<template>
  <div>
    <div class="subtitle mb-16px">{{ $t('label.autoimport') }}</div>
    <div class="subtitle-description mb-32px">{{ $t('label.my_personal_template') }}</div>

    <a-row :gutter="32" class="mb-32px">
      <a-col class="gutter-row" :span="6">
        <form-select
            :label="$t('label.nfc_id')"
            :placeholder="$t('label.nfc_id')"
            :options="templateOptions"
            :value="form.nfc"
            :error="error.nfc"
            required
            allow-clear
            @filled="error.nfc = null"
            @change="form.nfc = $event"
        />
      </a-col>
      <a-col class="gutter-row" :span="6">
        <form-select
            :label="$t('label.email')"
            :placeholder="$t('label.email')"
            :options="templateOptions"
            :value="form.email"
            allow-clear
            @change="form.email = $event"
        />
      </a-col>
      <a-col class="gutter-row" :span="6">
        <form-select
            :label="$t('label.name')"
            :placeholder="$t('label.name')"
            :options="templateOptions"
            :value="form.name"
            allow-clear
            @change="form.name = $event"
        />
      </a-col>
      <a-col class="gutter-row" :span="6">
        <form-select
            :label="$t('label.last_name')"
            :placeholder="$t('label.last_name')"
            :options="templateOptions"
            :value="form.last_name"
            allow-clear
            @change="form.last_name = $event"
        />
      </a-col>
    </a-row>

    <a-button class="app-button submit-base-btn primary mb-32px" @click="submit">
      {{ $t('btn.save_import_users') }}
    </a-button>

    <div class="subtitle mb-16px">{{ $t('label.single_import') }}</div>

    <a-row :gutter="32" class="d-flex aic mb-32px">
      <a-col class="gutter-row" :span="6">
        <span class="download-label">{{ $t('label.download_template') }}</span>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <a href="/UserList_Template.xlsx" download>
          <a-button class="app-button primary">{{ $t('btn.download') }}</a-button>
        </a>
      </a-col>
    </a-row>
    <a-row :gutter="32" class="d-flex aic mb-40px">
      <a-col class="gutter-row" :span="6">
        <span class="download-label">{{ $t('label.upload_list_of_users') }}</span>
      </a-col>
      <a-col class="gutter-row" :span="6">
        <a-button class="app-button primary" @click="$refs.inputFile.click()">{{ $t('btn.upload') }}</a-button>
        <input
            ref="inputFile"
            class="d-none"
            type="file"
            name="file"
            :disabled="importing"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="uploadFile($event.target.name, $event.target.files)"
        >
      </a-col>
    </a-row>
  </div>
</template>

<script>
import validationMixin from '@/mixins/validation'
import FormSelect from '@/components/elements/FormSelect'
import {mapGetters} from "vuex";

export default {
  components: {
    FormSelect
  },
  mixins: [validationMixin],
  props: {
    organizationId: {type: Number, default: null}
  },
  data: () => ({
    form: {
      nfc: null,
      email: null,
      name: null,
      last_name: null,
    },
    error: {
      nfc: null
    },
    importing: false
  }),
  computed: {
    ...mapGetters({
      importTemplate: 'organizationsStore/IMPORT_TEMPLATE'
    }),
    templateOptions() {
      return 'abcdefghijklmnopqrstuvwxyz'.split('').map(el => {
        return {value: el.toUpperCase(), label: el.toUpperCase()};
      });
    }
  },
  watch: {
    organizationId: {
      immediate: true,
      handler(id) {
        this.$store.dispatch('organizationsStore/GET_IMPORT_TEMPLATE', id);
      }
    },
    importTemplate(data) {
      if (data) {
        this.form.email = data.email;
        this.form.last_name = data.last_name;
        this.form.name = data.name;
        this.form.nfc = data.nfc;
      }
    }
  },
  methods: {
    uploadFile(name, files) {
      const file = files[0];
      let formData = new FormData();
      formData.append('file', file);
      this.importing = true;
      this.$store.dispatch('usersStore/IMPORT', formData)
      .then(() => {
        this.$message.success(this.$t('alert.users_import_started'));
        this.importing = false;
      })
      .catch((error) => {
        const errors = error.response.data.error.errors;

        for (const [key, message] of Object.entries(errors)) {
          const row = key.split('.');
          this.$message.error('Error! Row #' + row[row.length - 1] + '. ' + message);
        }
        this.importing = false;
      })
    },
    validateForm() {
      this.error.nfc = !this.form.nfc ? this.$t('error.required') : null;

      return !Object.values(this.error).some(v => v);
    },
    submit() {
      if (!this.validateForm()) return false;

      let data = structuredClone(this.form);
      for (let key in data) data[key] = data[key] || '-1';

      this.$store.dispatch('organizationsStore/UPDATE_IMPORT_TEMPLATE', {id: this.organizationId, data: data})
      .then(() => {
        this.$message.success(this.$t('alert.information_updated'));
      })
      .catch(error => {
        if (error.response.data?.error?.errors) {
          this.$message.error(Object.values(error.response.data.error.errors)[0]);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: #132540;
  font-size: 16px;
  font-weight: 500;
}

.subtitle-description {
  color: #6886A6;
  font-size: 16px;
}

.download-label {
  color: #132540;
  font-size: 15px;
  line-height: 24px;
}

.app-button {
  min-width: 128px;
}
</style>