<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4 7.98291C21.0913 8.56281 21.7454 9.18564 22.3584 9.84771C22.6417 10.1464 22.7995 10.5424 22.7995 10.9541C22.7995 11.3658 22.6417 11.7618 22.3584 12.0605C20.6064 13.9517 16.6272 17.5997 12 17.5997" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.59999 13.9423C2.91153 13.3533 2.25764 12.7251 1.64159 12.0607C1.35833 11.762 1.20044 11.366 1.20044 10.9543C1.20044 10.5426 1.35833 10.1466 1.64159 9.8479C4.25409 6.75341 7.96989 4.79909 12 4.3999" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.80005 21.1998L22.2 0.799805" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>