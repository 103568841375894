<template>
  <div>
    <a-row :gutter="32" class="mb-24px">
      <a-col v-if="ticket.organization" :xs="24" :md="12" :lg="5" class="gutter-row">
        <div class="d-flex flex-column text-dark">
          <span class="fw-500">{{ $t('label.organization') }}:</span>
          <span>{{ ticket.organization }}</span>
        </div>
      </a-col>
      <a-col v-if="computedBaseId" :xs="24" :md="12" :lg="5" class="gutter-row">
        <div class="d-flex flex-column text-dark">
          <span class="fw-500">{{ $t('label.base_id') }}:</span>
          <span>{{ computedBaseId }}</span>
        </div>
      </a-col>
      <a-col v-if="computedPicoId" :xs="24" :md="12" :lg="5" class="gutter-row">
        <div class="d-flex flex-column text-dark">
          <span class="fw-500">{{ $t('label.pico_id') }}:</span>
          <span>{{ computedPicoId }}</span>
        </div>
      </a-col>
      <a-col v-if="computedNfcId" :xs="24" :md="12" :lg="4" class="gutter-row">
        <div class="d-flex flex-column text-dark">
          <span class="fw-500">{{ $t('label.nfc_id') }}:</span>
          <span>{{ computedNfcId }}</span>
        </div>
      </a-col>
      <a-col v-if="computedProblem" :xs="24" :md="12" :lg="4" class="gutter-row">
        <div class="d-flex flex-column text-dark">
          <span class="fw-500">{{ $t('label.type_of_problem') }}:</span>
          <span>{{ computedProblem }}</span>
        </div>
      </a-col>
    </a-row>
    <div class="messages-wrapper mb-24px" :class="{'closed': status === 'closed'}" ref="messagesWrapper">
      <div v-for="(message, idx) in messages" :key="idx" class="message-wrapper">
        <a-row :gutter="32" class="d-flex jcb aic">
          <a-col :span="2" class="gutter-row">
            <div class="fs-sm text-grey">{{ getMessageType(message) }}:</div>
          </a-col>
          <a-col :span="12" class="gutter-row">
            <div class="fs-md text-dark" v-html="message.message"/>
          </a-col>
          <a-col v-if="message.file" :span="4" class="gutter-row text-right">
            <a :href="message.file" target="_blank" class="cursor-pointer">
              <clip-icon fill="#6886A6" stroke="#6886A6" view-box="0 0 256 256"/>
            </a>
          </a-col>
          <a-col v-else :span="4"></a-col>
          <a-col :span="5" class="gutter-row text-right">
            <div class="d-flex jcc aic">
              <calendar-icon height="24" width="24" stroke="#467BFF" view-box="0 0 32 32"/>
              <div class="text-grey ml-12px" style="margin-top: 2px">
                {{ timestampToDate(message.create_at_timestamp + '000', true) }}
              </div>
            </div>
          </a-col>
        </a-row>
        <a-divider v-if="(idx+1) !== ticket.ticket_message.length" type="horizontal" class="my-16px"/>
      </div>
    </div>
    <div v-if="status !== 'closed'" class="textarea-wrapper mb-24px">
      <form-textarea
          :placeholder="$t('placeholder.your_message')"
          :value="form.message"
          :rows="3"
          :element-class="form.attachment ? 'pr-200px' : 'pr-50px'"
          @input="form.message = $event"
      />
      <form-image-uploader v-if="withImageUpload" :update="updateImageComponentState" @change="form.attachment = $event"/>
    </div>
    <div class="d-flex aic">
      <a-button
          v-if="status !== 'closed'"
          class="app-button primary mr-16px"
          :disabled="!form.message"
          @click="sendMessage"
      >
        {{ $t('btn.send') }}
      </a-button>
      <a-button
          v-if="status !== 'closed'"
          class="app-button outline-danger"
          @click="$emit('close')"
      >
        {{ $t('btn.close_ticket') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import generalMixin from '@/mixins/general';
import FormImageUploader from '@/components/elements/FormImageUploader';
import FormTextarea from '@/components/elements/FormTextarea';
import CalendarIcon from '@/assets/icons/calendarIcon';
import ClipIcon from '@/assets/icons/clipIcon';

export default {
  components: {
    FormImageUploader,
    FormTextarea,
    CalendarIcon,
    ClipIcon
  },
  mixins: [generalMixin],
  props: {
    ticket: {
      type: Object, default: () => {
      }
    },
    withImageUpload: {type: Boolean, default: false},
  },
  data: () => ({
    ticketId: null,
    updateImageComponentState: false,
    form: {
      message: '',
      attachment: null
    }
  }),
  beforeMount() {
    this.ticketId = this.$route.params.id;
  },
  computed: {
    ...mapGetters({
      role: 'getMainRoleName'
    }),
    computedBaseId() {
      return this.ticket && this.ticket.ticket_param
          ? this.ticket.ticket_param.find(el => el.type.key === 'base_id')?.title
          : null;
    },
    computedPicoId() {
      return this.ticket && this.ticket.ticket_param
          ? this.ticket.ticket_param.find(el => el.type.key === 'pico_id')?.title
          : null;
    },
    computedNfcId() {
      return this.ticket && this.ticket.ticket_param
          ? this.ticket.ticket_param.find(el => el.type.key === 'nfc_id')?.title
          : null;
    },
    computedProblem() {
      return this.ticket && this.ticket.ticket_param
          ? this.ticket.ticket_param.find(el => el.type.key === 'problem_id')?.title
          : null;
    },
    messages() {
      return this.ticket?.ticket_message || [];
    },
    status() {
      return this.ticket?.status?.key;
    }
  },
  watch: {
    messages(data) {
      if (data) {
        this.$nextTick(() => {
          this.$refs.messagesWrapper.scrollTop = this.$refs.messagesWrapper.scrollHeight;
        });
      }
    },
    updateImageComponentState(value) {
      if (value) {
        setTimeout(() => this.updateImageComponentState = false, 1000);
      }
    }
  },
  methods: {
    getMessageType(message) {
      if (this.role === 'admin') {
        return message.type.key === 'request' ? this.$t('label.you') : this.$t('label.response');
      }
      if (this.role === 'superadmin') {
        return message.type.key === 'response' ? this.$t('label.you') : this.$t('label.response');
      }

      return '';
    },
    clearForm() {
      Object.entries(this.form).map(el => this.form[el[0]] = null);
      this.updateImageComponentState = true;
    },
    sendMessage() {
      if (!this.form.message) return false;
      this.$emit('send', this.form);
      this.clearForm();
    },
  }
}
</script>

<style lang="scss" scoped>
.messages-wrapper {
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-thumb:vertical {
    background: #467BFF;
    border-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background: #FFF;
  }
}

.textarea-wrapper {
  position: relative;
}
</style>