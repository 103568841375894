import { axios } from '@/utils/request';
import generalMixin from '@/mixins/general';

export function getHistory(data) {
    if (!data.use_time_from_value) {
      data.use_time_from_unit = null;
    }
    if (!data.use_time_to_value) {
      data.use_time_to_unit = null;
    }
    let date = generalMixin.methods.dateToQuery(data.date);
    delete data.date;
    const filtersString = generalMixin.methods.objectToQuery(data) + date;

    return axios({url: `v2/picos/log-history${filtersString}`, method: 'get'});
}

export function getPicosFromBase(baseId) {
  return axios({url: `v2/bases/${baseId}/picos`, method: 'get'});
}