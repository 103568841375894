export default {
  namespaced: true,
  state: {
    notificationReceived: false,
    ticketCreated: false,
  },
  getters: {
    NOTIFICATION_RECEIVED(state) {
      return state.notificationReceived;
    },
    TICKET_CREATED(state) {
      return state.ticketCreated;
    }
  },
  mutations: {
    SET_NOTIFICATION_RECEIVED(state, value) {
      state.notificationReceived = value;
      if (value) setTimeout(() => state.notificationReceived = false, 500);
    },
    SET_TICKET_CREATED(state, value) {
      state.ticketCreated = value;
      if (value) setTimeout(() => state.ticketCreated = false, 500);
    }
  },
  actions: {}
}