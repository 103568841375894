<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 16.875C14.0711 16.875 15.75 15.1961 15.75 13.125C15.75 11.0539 14.0711 9.375 12 9.375C9.92893 9.375 8.25 11.0539 8.25 13.125C8.25 15.1961 9.92893 16.875 12 16.875Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.375 10.875C19.2484 10.8743 20.1098 11.0772 20.891 11.4677C21.6722 11.8583 22.3516 12.4256 22.8751 13.1246" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.125 13.1248C1.64854 12.4257 2.3279 11.8584 3.10912 11.4678C3.89033 11.0772 4.75187 10.8743 5.62527 10.875" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.60352 20.2499C7.09619 19.238 7.86345 18.385 8.81771 17.7882C9.77197 17.1914 10.8748 16.875 12.0003 16.875C13.1258 16.875 14.2286 17.1914 15.1829 17.7882C16.1371 18.3849 16.9044 19.238 17.3971 20.2499" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.62542 10.875C5.0562 10.8749 4.49877 10.7129 4.0182 10.4078C3.53763 10.1027 3.15377 9.66726 2.91143 9.1522C2.6691 8.63715 2.5783 8.06378 2.64963 7.49906C2.72096 6.93433 2.95148 6.40155 3.31427 5.96293C3.67706 5.52431 4.15715 5.19796 4.69848 5.02198C5.23981 4.84599 5.82003 4.82765 6.3714 4.96908C6.92276 5.11052 7.42251 5.40589 7.81228 5.82072C8.20206 6.23554 8.46577 6.7527 8.57264 7.31179" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.4277 7.31186C15.5345 6.75268 15.7982 6.23543 16.188 5.82051C16.5778 5.40559 17.0776 5.11014 17.629 4.96865C18.1805 4.82716 18.7608 4.84548 19.3022 5.02147C19.8436 5.19745 20.3237 5.52384 20.6866 5.96251C21.0494 6.40119 21.28 6.93404 21.3513 7.49885C21.4226 8.06365 21.3318 8.63709 21.0894 9.1522C20.847 9.66731 20.4631 10.1028 19.9824 10.4079C19.5018 10.713 18.9443 10.875 18.375 10.875" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>