<template>
  <a-row :gutter="16" class="mb-32px">
    <a-col class="gutter-row" :span="8">
      <div class="gutter-box box-shadow-green">
        <div class="d-flex jcb aic">
          <div>
            <div class="gutter-box-number">{{ data.current_user || '0' }}</div>
            <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.current_users') }}</div>
          </div>
          <div class="gutter-box-icon d-flex jcc aic green">
            <users-icon height="32" width="32" stroke="#2FC091"/>
          </div>
        </div>
        <a-button class="app-button primary gutter-box-btn mt-24px" @click="goToUsersWithPico">
          {{ $t('btn.details') }}
        </a-button>
      </div>
    </a-col>
    <a-col class="gutter-row" :span="8">
      <div class="gutter-box box-shadow-yellow">
        <div class="d-flex jcb aic">
          <div>
            <div class="gutter-box-number">{{ data.tickets || '0' }}</div>
            <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.unread_tickets') }}</div>
          </div>
          <div class="gutter-box-icon d-flex jcc aic yellow">
            <mail-icon height="32" width="32" stroke="#EABA13"/>
          </div>
        </div>
        <a-button class="app-button primary gutter-box-btn mt-24px" @click="goToUnreadTickets">
          {{ $t('btn.see_more') }}
        </a-button>
      </div>
    </a-col>
    <a-col class="gutter-row" :span="8">
      <div class="gutter-box box-shadow-red">
        <div class="d-flex jcb aic">
          <div>
            <div class="gutter-box-number">{{ data.notifications || '0' }}</div>
            <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.urgent_notifications') }}</div>
          </div>
          <div class="gutter-box-icon d-flex jcc aic red">
            <notifications-icon height="32" width="32" stroke="#FF655B" viewbox="0 0 50 50"/>
          </div>
        </div>
        <a-button class="app-button primary gutter-box-btn mt-24px" @click="goToUrgentNotifications">
          {{ $t('btn.see_more') }}
        </a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import NotificationsIcon from '@/assets/icons/notificationsIcon';
import MailIcon from '@/assets/icons/mailIcon';
import UsersIcon from '@/assets/icons/usersIcon';

export default {
  components: {
    NotificationsIcon,
    MailIcon,
    UsersIcon
  },
  props: {
    organizationId: {type: [Number, String], default: null},
    data: {type: Object, default: () => {}}
  },
  methods: {
    goToUsersWithPico() {
      this.$router.push({name: 'Users', query: {organization_id: this.organizationId, pico_taken: null}});
    },
    goToUnreadTickets() {
      this.$router.push({name: 'Tickets', query: {organization_id: this.organizationId, unread: null}});
    },
    goToUrgentNotifications() {
      this.$router.push({name: 'Notifications', query: {urgent: null, new_event: null}});
    }
  }
}
</script>