<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_211_2535)">
      <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.125 11.25C8.125 11.319 8.06904 11.375 8 11.375C7.93096 11.375 7.875 11.319 7.875 11.25C7.875 11.181 7.93096 11.125 8 11.125C8.06904 11.125 8.125 11.181 8.125 11.25Z" fill="#4D4D4D" :stroke="stroke"/>
      <path d="M8 9V8.5C8.34612 8.5 8.68446 8.39736 8.97225 8.20507C9.26003 8.01278 9.48434 7.73947 9.61679 7.4197C9.74924 7.09993 9.7839 6.74806 9.71637 6.40859C9.64885 6.06913 9.48218 5.75731 9.23744 5.51256C8.9927 5.26782 8.68087 5.10115 8.34141 5.03363C8.00194 4.9661 7.65008 5.00076 7.3303 5.13321C7.01053 5.26566 6.73722 5.48997 6.54493 5.77775C6.35264 6.06554 6.25 6.40388 6.25 6.75" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_211_2535">
        <rect :width="width" :height="height" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>