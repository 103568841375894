<template>
  <div>
    <a-modal class="create-user-modal" v-model="visible" title="Basic Modal">
      <div class="title mb-40px">{{ $t('btn.set_user_as_admin') }}</div>
      <form-select
          class="mb-32px"
          :label="$t('label.organization')"
          :placeholder="$t('label.organization')"
          :value="form.organization_id"
          :options="organizationsList"
          @change="form.organization_id = $event"
      />
      <form-input
          class="mb-32px"
          :value="form.email"
          :label="$t('label.email')"
          :placeholder="$t('label.email')"
          :disabled="!form.organization_id"
          :error="error.user_email"
          @input="form.email = $event"
      />
      <form-checkbox
          v-if="user"
          class="mb-40px"
          :label="userName"
          @change="form.confirm = $event"
      />
      <template slot="footer">
        <a-button key="back" class="app-button" @click="$emit('close')">
          {{ $t('btn.cancel') }}
        </a-button>
        <a-button key="submit" class="app-button primary" :loading="loading" :disabled="!form.confirm" @click="submit">
          {{ $t('btn.set_user_as_admin') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import validationMixin from '@/mixins/validation';
import FormCheckbox from '@/components/elements/FormCheckbox';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';
import {mapGetters} from "vuex";

export default {
  components: {
    FormCheckbox,
    FormInput,
    FormSelect
  },
  mixins: [validationMixin],
  props: {
    show: {type: Boolean, default: false},
    organizationsList: {type: Array, default: () => []}
  },
  data: () => ({
    loading: false,
    visible: false,
    user: null,
    form: {
      confirm: false,
      email: null,
      organization_id: null
    },
    error: {
      user_email: null
    }
  }),
  computed: {
    ...mapGetters({
      users: 'usersStore/USERS_LIST'
    }),
    formEmail() {
      return this.form.email;
    },
    userName() {
      return this.user?.name;
    }
  },
  watch: {
    formEmail(value) {
      if (this.validateEmail(value)) {
        this.error.user_email = null;
        this.$store.dispatch('usersStore/GET_USERS_LIST', {
          organization_id: this.form.organization_id,
          user_email: value
        })
        .catch(error => {
          if (error.response.status === 422) {
            for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
              this.error[key] = errorMessage[0];
            }
          } else {
            this.$message.error(error.response.data.error.message || this.$t('alert.something_goes_wrong'));
          }
        });
      }
    },
    users(data) {
      if (data.length) {
        this.user = data[0];
      } else {
        this.error.user_email = this.$t('error.user_not_found');
      }
    },
    show(value) {
      this.visible = value;
    },
    visible(value) {
      if (!value) {
        this.clearError();
        this.clearForm();
        this.$emit('close');
      }
    }
  },
  methods: {
    clearError() {
      Object.entries(this.error).map(el => this.error[el[0]] = null);
    },
    clearForm() {
      this.user = null;
      Object.entries(this.form).map(el => this.form[el[0]] = null);
    },
    submit() {
      this.$store.dispatch('usersStore/SET_USER_AS_ADMIN', {
        organization_id: this.form.organization_id,
        user_ids: [this.user.id]
      })
      .then(() => {
        this.visible = false;
        this.$message.success(this.$t('alert.admin_created'));
      })
      .catch(error => {
        if (error.response.status === 422) {
          for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
            this.error[key] = errorMessage[0];
          }
        } else {
          this.$message.error(error.response.data.error.message || this.$t('alert.something_goes_wrong'));
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.create-user-modal::v-deep {
  .ant-modal {
    width: 590px !important;

    .title {
      color: #000;
      font-size: 36px;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>