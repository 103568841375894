import Vue from 'vue';
import store from '@/store';
import i18n from '@/core/vue-i18n';
import VueRouter from 'vue-router';
import AuthLayout from '@/components/layout/AuthLayout';
import MainLayout from '@/components/layout/MainLayout';
import AdminTicketLayout from '@/views/tickets/admin/TicketLayout';
import SuperadminTicketLayout from '@/views/tickets/superadmin/TicketLayout';
import AdminCreateTicketView from '@/views/tickets/admin/CreateTicketView';
import AdminShowTicketView from '@/views/tickets/admin/ShowTicketView';
import AdminTicketsView from '@/views/tickets/admin/TicketsView';
import BaseDetailsView from '@/views/bases/details/BaseDetailsView';
import BasesView from '@/views/bases/BasesView';
import DashboardView from '@/views/dashboard/DashboardView';
import HistoryView from '@/views/history/HistoryView';
import NotificationsView from '@/views/notifications/NotificationsView';
import SettingsView from '@/views/settings/SettingsView';
import SuperadminShowTicketView from '@/views/tickets/superadmin/ShowTicketView';
import SuperadminTicketsView from '@/views/tickets/superadmin/TicketsView';
import UsersView from '@/views/users/UsersView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/user',
    component: AuthLayout,
    name: 'Auth',
    redirect: '/user/login',
    beforeEnter: (to, from, next) => {
      if (!Vue.ls.get('Authorization')) next();
      else return next({name: 'Dashboard'});
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/LoginPage'),
        meta: {title: 'login'}
      },
      {
        path: 'password-recovery',
        name: 'ForgotPassword',
        component: () => import('@/views/auth/PasswordRecoveryPage'),
        meta: {title: 'forgot_password'}
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPasswordPage'),
        meta: {title: 'reset_password'}
      }
    ]
  },
  {
    path: '/user',
    component: AuthLayout,
    name: 'Auth',
    redirect: '/user/login',
    beforeEnter: (to, from, next) => {
      if (Vue.ls.get('Authorization')) next();
      else return next({name: 'Login'});
    },
    children: [
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => import('@/views/auth/ChangePasswordPage'),
        meta: {title: 'change_password', superadmin: true, admin: true}
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    component: MainLayout,
    beforeEnter: async (to, from, next) => {
      if (Vue.ls.get('Authorization')) next();
      else next({name: 'Login'});
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: DashboardView,
        meta: {title: 'dashboard', auth: true, superadmin: true, admin: true}
      },
      {
        path: 'bases',
        name: 'Bases',
        component: BasesView,
        meta: {title: 'bases', auth: true, superadmin: false, admin: true}
      },
      {
        path: 'bases/:id',
        name: 'BaseDetails',
        component: BaseDetailsView,
        meta: {title: 'basedetails', auth: true, superadmin: false, admin: true}
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: NotificationsView,
        meta: {title: 'notifications', auth: true, superadmin: true, admin: true}
      },
      {
        path: 'history',
        name: 'History',
        component: HistoryView,
        meta: {title: 'history', auth: true, superadmin: true, admin: true}
      },
      {
        path: 'users',
        name: 'Users',
        component: UsersView,
        meta: {title: 'users', auth: true, superadmin: true, admin: true}
      },
      {
        path: 'contact',
        component: AdminTicketLayout,
        children: [
          {
            path: '',
            name: 'ContactList',
            component: AdminTicketsView,
            meta: {title: 'contact_list', auth: true, superadmin: false, admin: true},
          },
          {
            path: 'new',
            name: 'NewContact',
            component: AdminCreateTicketView,
            meta: {title: 'create_ticket', auth: true, superadmin: false, admin: true}
          },
          {
            path: ':id',
            name: 'ShowContact',
            component: AdminShowTicketView,
            meta: {title: 'contact', auth: true, superadmin: false, admin: true}
          },
        ]
      },
      {
        path: 'tickets',
        component: SuperadminTicketLayout,
        children: [
          {
            path: '',
            name: 'Tickets',
            component: SuperadminTicketsView,
            meta: {title: 'tickets', auth: true, superadmin: true, admin: false}
          },
          {
            path: ':id',
            name: 'ShowTicket',
            component: SuperadminShowTicketView,
            meta: {title: 'tickets', auth: true, superadmin: true, admin: false}
          }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        component: SettingsView,
        meta: {title: 'settings', auth: true, superadmin: false, admin: true}
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/views/errors/NotFoundErrorPage'),
        meta: {title: '404', auth: true}
      }
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({y: 0}),
  routes
});

router.beforeEach(async (to, from, next) => {
  const token = to.query?.access_token;

  if (to.path === '/') {
    next({name: 'Dashboard'});
  }
  let profile = store.getters['me'];
  if (to.meta.auth) {
    if (token) {
      Vue.ls.set('Authorization', token);
      store.commit('SET_TOKEN', token);
      let query = Object.assign({}, to.query);
      delete query.access_token;

      if (query?.lang) {
        localStorage.setItem('language', query.lang);
        delete query.lang;
      }

      // router.replace({path: to.path, query: query});
      next({path: to.path, replace: true, query: query});
    }
    if (!profile) {
      await store.dispatch('GetLoggedUser').then(response => profile = response.data);
    }

    if (profile.password_expired) {
      next({name: 'ChangePassword'});
    } else if (to.meta[profile.main_role.name]) {
      next();
    } else if (to.name === '404') {
      next();
    } else {
      next({name: 'Dashboard'});
    }
  } else {
    next();
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta?.title ? i18n.t(`navigation.${to.meta.title}`) + ' | OSOL' : 'OSOL';
  });
});

export default router;
