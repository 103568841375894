<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3V7.75" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 3.38745C11.9937 4.03416 12.7519 4.98469 13.1614 6.09734C13.571 7.21 13.61 8.42522 13.2727 9.56187C12.9355 10.6985 12.2399 11.6958 11.2897 12.4049C10.3395 13.1141 9.18564 13.4972 8 13.4972C6.81436 13.4972 5.66046 13.1141 4.71029 12.4049C3.76011 11.6958 3.06455 10.6985 2.72725 9.56187C2.38996 8.42522 2.429 7.21 2.83856 6.09734C3.24812 4.98469 4.00627 4.03416 5 3.38745" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>