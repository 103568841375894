<template>
  <div>
    <a-input-group class="app-input-select" :class="{'error': error}" compact>
      <div v-if="label" class="input-label text-grey">
        {{ label }}
        <span v-if="required" class="required text-danger">*</span>
      </div>
      <a-input
          :style="`width: ${inputWidth}%`"
          :value="inputValue"
          default-value="input content"
          :placeholder="inputPlaceholder"
          :disabled="disabled"
          @keydown="$emit('keydown', $event)"
          @change="onChange"
      />
      <a-select
          :style="`width: ${selectWidth}%`"
          :value="selectValue || undefined"
          :options="options"
          :default-active-first-option="activeFirstOption"
          :disabled="disabled"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @change="$emit('change', $event || null)"
      />
    </a-input-group>
    <div v-if="error" class="error-msg position-absolute text-danger fs-sm nowrap">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {type: String, default: ''},
    options: {type: Array, default: () => []},
    inputPlaceholder: {type: String, default: ''},
    inputValue: {type: [Number, String], default: ''},
    inputWidth: {type: [Number, String], default: 70},
    selectPlaceholder: {type: String, default: ''},
    selectValue: {type: String, default: ''},
    selectWidth: {type: [Number, String], default: 30},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    error: {type: String, default: null},
    activeFirstOption: {type: Boolean, default: false},
  },
  methods: {
    onChange(e) {
      if (e.target.value) {
        this.$emit('filled');
      }
      this.$emit('input', e.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-input-select::v-deep {
  .input-label {
    margin-bottom: 8px;
    display: block;
  }

  .anticon {
    color: #467BFF;
  }

  .ant-input {
    height: 48px;
    border: 1px solid transparent;

    &:not(.ant-input-disabled) {
      color: #132540;
    }

    &::-webkit-input-placeholder {
      color: #6886A6;
      font-size: 15px;
    }

    &::-moz-placeholder {
      color: #6886A6;
      font-size: 15px;
    }

    &:-ms-input-placeholder {
      color: #6886A6;
      font-size: 15px;
    }

    &:-moz-placeholder {
      color: #6886A6;
      font-size: 15px;
    }
  }

  .ant-select {
    height: 48px;
    border: 0;

    &:not(.ant-select-disabled) {
      .ant-select-selection {
        color: #132540;
      }
    }

    .ant-select-selection {
      height: 100%;
      padding: 8px;
      color: #6886A6;
      border: 1px solid transparent;
      font-size: 15px;

      .ant-select-selection__placeholder {
        color: #6886A6;
      }

      .ant-select-selection__rendered {
        margin-left: 0;
      }
    }

    .ant-select-arrow {
      top: 23px;
    }
  }
}

.error::v-deep {
  .ant-input {
    border: 1px solid #FF655B;

    &:not(:focus) {
      background: #FFF6F5;
    }
  }

  .ant-select {
    border: 1px solid #FF655B;

    &:not(:focus) {
      .ant-select-selection {
        background: #FFF6F5;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .app-input-select::v-deep {
    .ant-input {
      &::-webkit-input-placeholder {
        color: #6886A6;
        font-size: 14px;
      }

      &::-moz-placeholder {
        color: #6886A6;
        font-size: 14px;
      }

      &:-ms-input-placeholder {
        color: #6886A6;
        font-size: 14px;
      }

      &:-moz-placeholder {
        color: #6886A6;
        font-size: 14px;
      }
    }

    .ant-select {
      .ant-select-selection {
        font-size: 14px;
      }
    }
  }
}
</style>