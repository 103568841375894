<template>
  <div class="app-switcher d-flex jcb aic">
    <div v-if="label" class="text-grey">{{ label }}</div>
    <a-switch :checked="value" :disabled="disabled" @change="$emit('change', $event)" />
  </div>
</template>

<script>
export default {
  props: {
    label: {type: String, default: ''},
    value: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false}
  }
}
</script>

<style lang="scss" scoped>
.app-switcher::v-deep {
  .ant-switch {
    height: 24px;
    min-width: 40px;
    background: #BBD2FF;

    &::after {
      height: 20px;
      width: 20px;
    }

    &.ant-switch-checked {
      background: #467BFF;
    }
  }
}
</style>