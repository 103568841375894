<template>
  <a-popover v-model="notificationsPopoverState" trigger="click" overlayClassName="notifications-popover">
    <div slot="content">
      <div v-if="notifications.length" class="notifications-wrapper">
        <div v-for="notification in notifications" :key="notification.id" class="notification-item">
          <div class="d-flex aic cursor-pointer" @click="markAsRead($event, notification)">
            <shield-warning-icon stroke="#FF655B"/>
            <div class="position-relative">
              <div class="ellipsis text-dark fw-500" :title="notification.message">{{ notification.message }}</div>
              <div class="text-grey">{{ timestampToDate(notification.store_at, true) }}</div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <a-button class="app-button primary my-24px" @click="goToNotifications">
            {{ $t('btn.see_all_notifications') }}
          </a-button>
        </div>
      </div>
      <div v-else class="empty-list text-grey text-center">{{ $t('label.no_new_notifications') }}</div>
    </div>
    <div class="notification-icon cursor-pointer" :class="{'unread': notifications.length}">
      <notifications-icon/>
    </div>
  </a-popover>
</template>

<script>
import {mapGetters} from 'vuex';
import {settings} from '@/core/defaultSettings';
import generalMixin from '@/mixins/general';
import NotificationsIcon from '@/assets/icons/notificationsIcon';
import ShieldWarningIcon from '@/assets/icons/shieldWarningIcon';

export default {
  components: {
    NotificationsIcon,
    ShieldWarningIcon
  },
  mixins: [generalMixin],
  data: () => ({
    notificationsPopoverState: false
  }),
  beforeMount() {
    this.getLastNotifications();
  },
  computed: {
    ...mapGetters({
      notifications: 'notificationsStore/LAST_NOTIFICATIONS',
      markedAsRead: 'notificationsStore/MARKED_AS_READ'
    })
  },
  watch: {
    markedAsRead(id) {
      if (id) setTimeout(() => this.getLastNotifications(), 700);
    }
  },
  methods: {
    getLastNotifications() {
      this.$store.dispatch('notificationsStore/GET_LAST_NOTIFICATIONS', {limit: settings.notificationsPopoverLength});
    },
    markAsRead(e, notification) {
      e.target.closest('.notification-item').classList.toggle('hidden');

      this.$store.dispatch('notificationsStore/MARK_AS_READ', notification.id);
    },
    goToNotifications() {
      this.notificationsPopoverState = false;
      this.$router.push({name: 'Notifications'}).catch(() => {
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-item {
  padding: 16px 0;
  border-bottom: 1.5px solid #F4F4F4;

  &.hidden {
    overflow: hidden;
    height: 0;
    opacity: 0;
    padding: 0;
    transition: height 0ms 700ms, opacity 700ms 0ms, padding-top 700ms, padding-bottom 700ms;
  }

  .ellipsis {
    max-width: 235px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.empty-list {
  padding: 4px 16px 16px;
}
</style>