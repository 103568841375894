<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 13.5V6.5C14 6.36739 13.9473 6.24021 13.8536 6.14645C13.7598 6.05268 13.6326 6 13.5 6H9" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 4.5H6" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 8.5H7" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 11H6" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 11H12" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 8.5H12" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9 13.5V2.5C9 2.36739 8.94732 2.24021 8.85355 2.14645C8.75979 2.05268 8.63261 2 8.5 2H2.5C2.36739 2 2.24021 2.05268 2.14645 2.14645C2.05268 2.24021 2 2.36739 2 2.5V13.5" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1 13.5H15" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>