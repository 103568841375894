<template>
  <a-table
      ref="appTable"
      :columns="columns"
      :row-key="(record, idx) => idx"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :scroll="{ x: scroll }"
      @change="handleTableChange"
  >
    <span v-for="(col, idx) in columns" :key="idx" :slot="`${col.dataIndex}_icon`" class="header-icon-wrapper">
      <slot :name="`${col.dataIndex}_icon`"></slot>
    </span>

    <template
        v-for="col in columns"
        :slot="col.dataIndex"
        slot-scope="text, record, index"
    >
      <slot :name="col.dataIndex" v-bind="{ text, record, index }">{{ text }}</slot>
    </template>
    <template #footer>
      <slot name="footer-actions"/>
    </template>
  </a-table>
</template>

<script>
export default {
  props: {
    columns: {type: Array, default: () => []},
    data: {type: Array, default: () => []},
    current: {type: Number, default: 1},
    total: {type: Number, default: 0},
    scroll: {type: Boolean, default: false},
    hidePagination: {type: Boolean, default: false}
  },
  data: () => ({
    pagination: {defaultPageSize: 12, total: 0, current: 1},
    loading: false
  }),
  watch: {
    data(data) {
      if (data) {
        this.$nextTick(() => {
          setTimeout(() => this.createFakeScroll(), 500);
        });
      }
    },
    current(value) {
      this.pagination.current = value || 1;
    },
    total(value) {
      this.pagination.total = value;
    },
    hidePagination: {
      immediate: true,
      handler(value) {
        if (value) {
          this.pagination = false;
        }
      }
    }
  },
  methods: {
    handleTableChange(pagination) {    // pagination, filters, sorter
      this.$emit('getData', {page: pagination?.current || 1});
    },
    createFakeScroll() {
      let isScrollbarVisible = false;
      let element = this.$refs.appTable.$el;
      const tableEl = document.querySelector('.ant-table');
      const previousFakeScroll = document.querySelector('.fake-app-table-scroll');
      if (previousFakeScroll) {
        previousFakeScroll.remove();
      }

      let scrollbar = document.createElement('div');
      scrollbar.classList.add('fake-app-table-scroll');
      const scrollableElementArr = element.getElementsByClassName('ant-table-body');
      scrollbar.appendChild(document.createElement('div'));
      scrollbar.style.overflowX = 'auto';
      scrollbar.firstChild.style.height = 0;
      scrollbar.firstChild.style.width = element.getElementsByClassName('ant-table-tbody')[0].scrollWidth + 'px';
      scrollbar.firstChild.style.paddingTop = '1px';
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
      scrollbar.onscroll = function () {
        for (let i = 0; i < scrollableElementArr.length; i++) scrollableElementArr[i].scrollLeft = scrollbar.scrollLeft;
      };

      for (let i = 0; i < scrollableElementArr.length; i++) {
        let el = scrollableElementArr[i];
        isScrollbarVisible = el.clientWidth !== el.scrollWidth;
        el.onscroll = () => scrollbar.scrollLeft = el.scrollLeft;
      }
      if (isScrollbarVisible) {
        tableEl.insertBefore(scrollbar, tableEl.children[0]);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.ant-table-wrapper::v-deep {
  background: #FFF;
  scrollbar-color: #467BFF #FFF;

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #F6F8FC;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: #467BFF;
    border-radius: 4px;
  }

  .ant-table-empty {
    .ant-table-footer {
      display: none;
    }
  }

  .ant-empty {
    .ant-empty-image {
      display: none;
    }

    .ant-empty-description {
      font-size: 15px;
      color: #6886A6;
    }
  }

  .ant-table-thead {
    > tr {
      > th {
        padding: 24px;
        background: #FFF;
        color: #6886A6;
        border-bottom: 1px solid #F4F4F4;

        .ant-table-header-column {
          vertical-align: middle;

          .ant-table-column-title {
            font-weight: 400;
            white-space: nowrap;

            .header-icon-wrapper {
              > span {
                display: flex;
                align-items: center;

                > span {
                  margin-left: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-table-tbody {
    > tr {
      > td {
        padding: 24px;
        color: #132540;
        border-bottom: 1.5px solid #F7F8FA;
        font-weight: 500;
        white-space: nowrap;

        .table-actions-wrapper {
          span {
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .ant-table-footer {
    height: 70px;
    padding: 15px 24px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  .ant-pagination {
    .ant-pagination-prev, .ant-pagination-next {
      height: 38px;
      margin: 0;

      .ant-pagination-item-link {
        padding: 12px 23px 12px 16px;
        border: 0;

        .anticon {
          display: block;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
        }
      }
    }

    .ant-pagination-item {
      height: 38px;
      margin-right: 0;
      padding: 12px;
      background: #FFF;
      border: 1px solid transparent;
      color: #467BFF;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;

      &.ant-pagination-item-active {
        border: 1px solid #467BFF;
      }
    }

    .ant-pagination-jump-next {
      margin-right: 0;

      .ant-pagination-item-ellipsis {
        color: #467BFF;
        font-size: 10px;
      }
    }
  }
}
</style>
