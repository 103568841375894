<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7.16875V3.5C2.5 3.36739 2.55268 3.24021 2.64645 3.14645C2.74021 3.05268 2.86739 3 3 3H13C13.1326 3 13.2598 3.05268 13.3536 3.14645C13.4473 3.24021 13.5 3.36739 13.5 3.5V7.16875C13.5 12.4187 9.04375 14.1562 8.15625 14.45C8.05543 14.4873 7.94457 14.4873 7.84375 14.45C6.95625 14.1562 2.5 12.4187 2.5 7.16875Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 6V8.5" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.125 10.75C8.125 10.819 8.06904 10.875 8 10.875C7.93096 10.875 7.875 10.819 7.875 10.75C7.875 10.681 7.93096 10.625 8 10.625C8.06904 10.625 8.125 10.681 8.125 10.75Z" fill="#FF655B" :stroke="stroke"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>