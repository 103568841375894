<template>
  <div>
    <app-breadcrumb :items="breadcrumbs" class="mb-24px"/>
    <a-row :gutter="32" class="mb-32px">
      <a-col :span="8">
        <form-select
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            :value="organizationId"
            active-first-option
            with-icon
            @change="organizationId = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
    </a-row>
    <app-tabs v-if="organizationId" :tabs="tabs" style="margin-left: 2px">
      <template #general>
        <general-tab :organization="organization"/>
      </template>
      <template #bases_pico>
        <bases-tab :organization="organization"/>
      </template>
      <template #import_of_users>
        <import-users-tab :organization-id="organizationId"/>
      </template>
      <template #notifications>
        <notifications-tab :organization-id="organizationId"/>
      </template>
    </app-tabs>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppBreadcrumb from '@/components/elements/AppBreadcrumb';
import AppTabs from '@/components/elements/AppTabs';
import FormSelect from '@/components/elements/FormSelect';
import GeneralTab from './components/GeneralTab';
import BasesTab from './components/BasesTab';
import ImportUsersTab from './components/ImportUsersTab';
import NotificationsTab from './components/NotificationsTab';
import ListIcon from '@/assets/icons/listIcon';

export default {
  components: {
    AppBreadcrumb,
    AppTabs,
    FormSelect,
    GeneralTab,
    BasesTab,
    ImportUsersTab,
    NotificationsTab,
    ListIcon
  },
  data: () => ({
    organizationId: null
  }),
  mounted() {
    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      organization: 'organizationsStore/ORGANIZATION'
    }),
    breadcrumbs() {
      return [
        {name: this.$t('navigation.home'), route: 'Dashboard'},
        {name: this.$t('label.settings'), route: null}
      ];
    },
    tabs() {
      return ['general', 'bases_pico', 'import_of_users', 'notifications'];
    }
  },
  watch: {
    organizationId(id) {
      if (id) {
        this.$store.dispatch('organizationsStore/GET_ORGANIZATION', id);
      }
    }
  }
}
</script>