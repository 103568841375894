<template>
  <div class="card-container">
    <a-tabs type="card" @change="$emit('change', $event)">
      <a-tab-pane
        v-for="(item, idx) in tabs"
        :key="idx"
        :tab="getTabName(item)"
        :class="`${item}-wrapper`"
        :disabled="disabledTabs.includes(item)"
      >
        <slot :name="item"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {type: Array, default: () => []},
    disabledTabs: {type: Array, default: () => []},
    suffixInfo: {type: Object, default: () => {}}
  },
  methods: {
    getTabName(item) {
      let string = this.$t(`label.${item}`);
      if (this.suffixInfo && this.suffixInfo[item]) {
        string += this.suffixInfo[item];
      }

      return string;
    }
  }
}
</script>

<style lang="scss" scoped>
.card-container::v-deep {
  .ant-tabs {
    overflow: initial;

    .ant-tabs-bar {
      border-bottom: 1.5px solid #F4F4F4;

      .ant-tabs-nav-container {
        height: auto;

        .ant-tabs-nav-wrap {
          .ant-tabs-tab {
            height: auto;
            margin-right: 10px;
            padding: 16px;
            border: 0;
            color: #6886A6;
            line-height: 16px;

            &.ant-tabs-tab-active {
              color: #467BFF;
            }

            &:not(.ant-tabs-tab-active) {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
</style>