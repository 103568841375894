import {
  advancedUpdate,
  getBase,
  getBaseAdvancedDetails, getBasesDropdown,
  getBoxData,
  getListData, reboot, releaseSlot,
  unlockAllPicos,
  updateBase
} from '@/api/bases';

export default {
  namespaced: true,
  state: {
    boxData: {},
    listData: [],
    listMeta: {total: 0},
    dropdownList: [],
    base: null,
    advancedDetails: null,
  },
  getters: {
    BOX_DATA(state) {
      return state.boxData;
    },
    LIST_DATA(state) {
      return state.listData;
    },
    LIST_META(state) {
      return state.listMeta;
    },
    DROPDOWN_LIST(state) {
      return state.dropdownList;
    },
    BASE(state) {
      return state.base;
    },
    ADVANCED_DETAILS(state) {
      return state.advancedDetails;
    }
  },
  mutations: {
    SET_BOX_DATA(state, data) {
      state.boxData = data;
    },
    SET_LIST_DATA(state, data) {
      state.listData = data;
    },
    SET_LIST_META(state, meta) {
      state.listMeta = meta;
    },
    SET_DROPDOWN_LIST(state, list) {
      state.dropdownList = list.map(el => {
        return {value: el.id, label: el.base_id}
      });
    },
    SET_BASE(state, data) {
      state.base = data;
    },
    SET_ADVANCED_DETAILS(state, data) {
      state.advancedDetails = data;
    },
  },
  actions: {
    GET_BOX_DATA(context, organizationId) {
      return new Promise((resolve, reject) => {
        getBoxData(organizationId).then(response => {
          context.commit('SET_BOX_DATA', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GET_LIST_DATA(context, filters) {
      return new Promise((resolve, reject) => {
        getListData(filters).then(response => {
          context.commit('SET_LIST_DATA', response.data);
          context.commit('SET_LIST_META', response.meta);
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GET_DROPDOWN_LIST(context, filters) {
      return new Promise((resolve, reject) => {
        getBasesDropdown(filters).then(response => {
          context.commit('SET_DROPDOWN_LIST', response.data);
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GET_BASE(context, id) {
      return new Promise((resolve, reject) => {
        getBase(id).then(response => {
          context.commit('SET_BASE', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GET_ADVANCED_DETAILS(context, id) {
      return new Promise((resolve, reject) => {
        getBaseAdvancedDetails(id).then(response => {
          context.commit('SET_ADVANCED_DETAILS', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    UPDATE_BASE(context, {id, details}) {
      return new Promise((resolve, reject) => {
        updateBase(id, details).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ADVANCED_UPDATE(context, id) {
      return new Promise((resolve, reject) => {
        advancedUpdate(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    RELEASE_SLOT(context, {baseId, slot}) {
      return new Promise((resolve, reject) => {
        releaseSlot(baseId, slot).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      })
    },
    REBOOT(context, id) {
      return new Promise((resolve, reject) => {
        reboot(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    UNLOCK_ALL_PICOS(context, id) {
      return new Promise((resolve, reject) => {
        unlockAllPicos(id).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}