<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_196_2557)">
      <path d="M7.64556 4.46445L8.883 3.22702C9.13832 2.97146 9.44149 2.76871 9.77518 2.63035C10.1089 2.492 10.4666 2.42074 10.8278 2.42065C11.1891 2.42057 11.5468 2.49166 11.8805 2.62986C12.2143 2.76807 12.5176 2.97067 12.773 3.22611C13.0284 3.48155 13.231 3.78481 13.3692 4.11857C13.5074 4.45233 13.5785 4.81005 13.5785 5.17129C13.5784 5.53254 13.5071 5.89022 13.3688 6.22392C13.2304 6.55762 13.0276 6.86078 12.7721 7.1161L11.0043 8.88387C10.749 9.13923 10.4458 9.3418 10.1122 9.48C9.77851 9.6182 9.42091 9.68933 9.05978 9.68933C8.69864 9.68933 8.34104 9.6182 8.0074 9.48C7.67375 9.3418 7.3706 9.13923 7.11523 8.88387" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.35476 11.5354L7.11732 12.7729C6.86201 13.0284 6.55884 13.2312 6.22514 13.3695C5.89144 13.5079 5.53376 13.5791 5.17252 13.5792C4.81127 13.5793 4.45355 13.5082 4.11979 13.37C3.78603 13.2318 3.48277 13.0292 3.22733 12.7738C2.97189 12.5183 2.76929 12.2151 2.63108 11.8813C2.49288 11.5475 2.42179 11.1898 2.42188 10.8286C2.42196 10.4673 2.49322 10.1096 2.63157 9.77595C2.76993 9.44226 2.97268 9.13909 3.22824 8.88377L4.996 7.116C5.25136 6.86064 5.55452 6.65808 5.88817 6.51988C6.22181 6.38168 6.57941 6.31055 6.94055 6.31055C7.30168 6.31055 7.65928 6.38168 7.99293 6.51988C8.32657 6.65808 8.62973 6.86064 8.88509 7.116" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_196_2557">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>