<template>
  <div>
    <admin-top-boxes
        v-if="mainRoleName === 'admin'"
        class="top-box-wrapper"
        :data="boxData"
        :organization-id="filters.organization_id"
    />
    <superadmin-top-boxes
        v-if="mainRoleName === 'superadmin'"
        class="top-box-wrapper"
        :data="boxData"
        :organization-id="filters.organization_id"
    />

    <a-row :gutter="32" class="dashboard-table-wrapper">
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-select
            :value="filters.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            active-first-option
            with-icon
            @change="filters.organization_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-date-range-picker
            :value="filters.date"
            :placeholder="[$t('placeholder.from'), $t('placeholder.to')]"
            :disabled-date="disabledDate"
            allow-clear
            with-icon
            @change="filters.date = $event"
        >
          <template #prefix-icon>
            <calendar-icon height="24" width="24" stroke="#467BFF" view-box="0 0 32 24"/>
          </template>
        </form-date-range-picker>
      </a-col>
    </a-row>

    <a-row>
      <a-col>
        <app-table
            class="testtest"
            :columns="computedTableColumns"
            :data="list"
            :current="computedCurrentPage"
            :total="computedTotalItems"
            scroll
            @getData="getTableData"
        >
          <span slot="organization_icon">
            <list-icon height="16" width="16"/>
            <span>{{ $t('label.organization') }}</span>
          </span>
          <span slot="long_alias_icon">
            <bases-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.long_alias') }}</span>
          </span>
          <span slot="base_id_icon">
            <bases-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.base_alias') }}</span>
          </span>
          <span slot="total_consumption_icon">
            <lightbulb-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.total_consumption') }}, h</span>
          </span>
          <span slot="number_superuser_icon">
            <user-circle-gear-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.super_users') }}</span>
          </span>
          <span slot="number_active_user_icon">
            <user-gear-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.active_users') }}</span>
          </span>
          <span slot="average_peak_hour_icon">
            <time-three-interface-icon height="16" width="16" stroke="#467BFF" fill="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.average_peak_hour') }}</span>
          </span>
          <span slot="not_returned_pico_icon">
            <shield-warning-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.not_returned_pico') }}</span>
          </span>
          <span slot="workplace_mobility_icon">
            <bases-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.workplace_mobility') }}, %</span>
          </span>

          <template #average_peak_hour="{ text }">{{ getTimeFromTimestamp(text) }}</template>
        </app-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import moment from 'moment';
import AppTable from '@/components/elements/AppTable';
import FormDateRangePicker from '@/components/elements/FormDateRangePicker';
import FormSelect from '@/components/elements/FormSelect';
import AdminTopBoxes from './components/AdminTopBoxes';
import SuperadminTopBoxes from './components/SuperadminTopBoxes';
import BasesIcon from '@/assets/icons/basesIcon';
import CalendarIcon from '@/assets/icons/calendarIcon';
import LightbulbIcon from '@/assets/icons/lightbulbIcon';
import ListIcon from '@/assets/icons/listIcon';
import ShieldWarningIcon from '@/assets/icons/shieldWarningIcon';
import TimeThreeInterfaceIcon from '@/assets/icons/timeThreeInterfaceIcon';
import UserCircleGearIcon from '@/assets/icons/userCircleGearIcon';
import UserGearIcon from '@/assets/icons/userGearIcon';

export default {
  components: {
    AppTable,
    FormDateRangePicker,
    FormSelect,
    AdminTopBoxes,
    SuperadminTopBoxes,
    BasesIcon,
    CalendarIcon,
    LightbulbIcon,
    ListIcon,
    ShieldWarningIcon,
    TimeThreeInterfaceIcon,
    UserCircleGearIcon,
    UserGearIcon
  },
  data: () => ({
    filters: {
      organization_id: null,
      date: []
    },
    tableColumns: [
      'organization',
      'long_alias',
      'base_id',
      'total_consumption',
      'number_superuser',
      'number_active_user',
      'average_peak_hour',
      'not_returned_pico',
      'workplace_mobility'
    ]
  }),
  mounted() {
    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
  },
  computed: {
    ...mapGetters({
      mainRoleName: 'getMainRoleName',
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      boxData: 'dashboardStore/BOX_DATA',
      list: 'dashboardStore/TABLE_DATA',
      listMeta: 'dashboardStore/TABLE_META',
      notificationReceivedState: 'socketsStore/NOTIFICATION_RECEIVED',
    }),
    computedTableColumns() {
      return this.tableColumns.map(el => {
        return {dataIndex: el, slots: {title: `${el}_icon`}, scopedSlots: {customRender: el}};
      });
    },
    computedCurrentPage() {
      return this.listMeta?.current_page || 1;
    },
    computedTotalItems() {
      return this.listMeta?.total || this.list.length;
    },
    selectedOrganization() {
      return this.organizationsList.find(el => el.id === this.filters.organization_id);
    }
  },
  watch: {
    filters: {
      handler() {
        this.getBoxData();
        this.getTableData();
      },
      deep: true
    },
    notificationReceivedState(value) {
      if (value) this.getBoxData();
    }
  },
  methods: {
    moment,
    disabledDate(current) {
      const organizationCreatedAt = this.selectedOrganization?.created_at
        ? new Date(this.selectedOrganization?.created_at)
        : null;

      return current.isBefore(moment(organizationCreatedAt).subtract(1,'day'));
    },
    getTableData(pagination) {
      this.$store.dispatch('dashboardStore/GET_TABLE_DATA', {...this.filters, ...pagination});
    },
    getBoxData() {
      this.$store.dispatch('dashboardStore/GET_BOX_DATA', this.filters.organization_id);
    },
    getTimeFromTimestamp(timestamp) {
      let h = new Date(timestamp).getHours();
      h = (h < 10) ? '0' + h : h;

      return h + ':00';
    }
  }
}
</script>

<style lang="scss" scoped>
.gutter-example > > > .ant-row > div {
  background: transparent;
  border: 0;
}

.testtest::v-deep {
  .ant-table-thead {
    tr {
      th {
        padding: 24px 12px;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 24px 12px;
        text-align: center;
      }
    }
  }
}
</style>
