import {getNotificationSettings, getRelatedLocations, updateNotificationSettings} from '@/api/settings';

export default {
  namespaced: true,
  state: {
    relatedLocations: [],
    notificationSettings: {}
  },
  getters: {
    RELATED_LOCATIONS(state) {
      return state.relatedLocations;
    },
    NOTIFICATION_SETTINGS(state) {
      return state.notificationSettings;
    }
  },
  mutations: {
    SET_RELATED_LOCATIONS(state, locations) {
      state.relatedLocations = locations
        ? locations.map(el => {
          return {...el, value: el.label};
        })
        .filter((v, i, a) => a.findLastIndex(v2 => (v2.label === v.label)) === i)
        : [];
    },
    SET_NOTIFICATION_SETTINGS(state, data) {
      state.notificationSettings = data;
    },
    CLEAR_RELATED_LOCATIONS(state) {
      state.relatedLocations = [];
    }
  },
  actions: {
    GET_RELATED_LOCATIONS(context, q) {
      return new Promise((resolve, reject) => {
        getRelatedLocations(q).then(response => {
          context.commit('SET_RELATED_LOCATIONS', response);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_NOTIFICATION_SETTINGS(context, organizationId) {
      return new Promise((resolve, reject) => {
        getNotificationSettings(organizationId).then(response => {
          context.commit('SET_NOTIFICATION_SETTINGS', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    UPDATE_NOTIFICATION_SETTINGS(context, {organizationId, data}) {
      return new Promise((resolve, reject) => {
        updateNotificationSettings(organizationId, data).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }
  }
}