import {axios} from '@/utils/request';
import generalMixin from "@/mixins/general";

export function getOrganizationsDropdown(filters) {
    let filtersString = generalMixin.methods.objectToQuery(filters);
    return axios({url: `v2/organizations/list${filtersString}`, method: 'get'});
}

export function getList() {
    return axios({url: 'v2/dashboard/table', method: 'get'});
}

export function getOrganization(id) {
    return axios({url: `v2/organizations/${id}`, method: 'get'});
}

export function getImportTemplate(id) {
    return axios({url: `v2/organizations/${id}/import-template`, method: 'get'});
}

export function updateImportTemplate(id, data) {
    return axios({url: `v2/organizations/${id}/import-template`, method: 'post', data: data});
}

export function update(id, data) {
    return axios({url: `v2/organizations/${id}`, method: 'patch', data: data});
}

export function sendCreateOrganizationLink(email) {
    return axios({
        url: '/organizations/send-link',
        method: 'post',
        data: email
    })
}

export function createOrganizations(data) {
    return axios({
        url: '/organizations',
        method: 'post',
        data: data
    })
}

export function createOrganizationsByToken(token, data) {
    return axios({
        url: `/organizations/${token}/create`,
        method: 'post',
        data: data
    })
}

export function updateOrganization(id, data) {
    return axios({
        url: `/organizations/${id}`,
        method: 'patch',
        data: data
    })
}

export function updateOrganizationAutomation(id, data) {
    return axios({
        url: `/organizations/${id}/automation`,
        method: 'patch',
        data: data
    })
}

export function deleteOrganization(id) {
    return axios({
        url: `/organizations/${id}`,
        method: 'delete'
    })
}

export function pushBadges(id) {
    return axios({
        url: `/organizations/${id}/push-badges`,
        method: 'patch'
    })
}

export function testConnectionAutomation(id, data) {
    return axios({
        url: `/organizations/${id}/automation/test-connection`,
        method: 'post',
        data: data,
        timeout: 29000
    })
}