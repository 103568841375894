<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
<defs>
</defs>
    <g style="stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
       :style="`stroke: ${stroke}; fill: ${fill}`"
       transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
	<path
      d="M 26.827 89.998 c -5.832 0 -11.664 -2.22 -16.104 -6.66 c -8.88 -8.88 -8.88 -23.329 0 -32.209 L 56.932 4.921 c 6.563 -6.564 17.244 -6.563 23.805 0 c 6.563 6.563 6.563 17.241 0 23.804 L 37.658 71.804 c -4.245 4.247 -11.155 4.247 -15.401 0 c -4.246 -4.246 -4.246 -11.155 0 -15.401 l 39.3 -39.3 c 0.781 -0.781 2.047 -0.781 2.829 0 c 0.781 0.781 0.781 2.048 0 2.829 l -39.3 39.3 c -2.686 2.686 -2.686 7.057 0 9.743 c 2.687 2.687 7.058 2.685 9.743 0 l 43.079 -43.079 c 5.003 -5.003 5.003 -13.144 0 -18.147 c -5.004 -5.004 -13.145 -5.003 -18.147 0 L 13.552 53.959 c -7.319 7.32 -7.319 19.231 0 26.551 c 7.322 7.32 19.232 7.319 26.551 0 L 82.533 38.08 c 0.781 -0.781 2.047 -0.781 2.829 0 c 0.781 0.781 0.781 2.048 0 2.829 L 42.931 83.339 C 38.491 87.778 32.659 89.998 26.827 89.998 z"
      style="stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
      :style="`stroke: ${stroke}; fill: ${fill}`"
      transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
</g>
</svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>