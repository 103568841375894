import {getBoxData, getTableData} from '@/api/dashboard';

export default {
    namespaced: true,
    state: {
        boxData: {},
        tableData: [],
        tableMeta: {total: 0},
    },
    getters: {
        BOX_DATA(state) {
            return state.boxData;
        },
        TABLE_DATA(state) {
            return state.tableData;
        },
        TABLE_META(state) {
            return state.tableMeta;
        }
    },
    mutations: {
        SET_BOX_DATA(state, data) {
            state.boxData = data;
        },
        SET_TABLE_DATA(state, data) {
            state.tableData = data;
        },
        SET_TABLE_META(state, data) {
            state.tableMeta = data;
        },
    },
    actions: {
        GET_BOX_DATA(context, organizationId) {
            return new Promise((resolve, reject) => {
                getBoxData(organizationId).then(response => {
                    context.commit('SET_BOX_DATA', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_TABLE_DATA(context, filters) {
            return new Promise((resolve, reject) => {
                getTableData(filters).then(response => {
                    context.commit('SET_TABLE_DATA', response.data);
                    context.commit('SET_TABLE_META', response.meta);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
}