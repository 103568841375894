<template>
  <div class="clip-wrapper d-flex aic">
    <a-tooltip v-if="image">
      <template slot="title">
        {{ $t('text.click_to_delete_image') }}
      </template>
      <span class="image-name text-danger fw-500 cursor-pointer mr-16px" @click="uploadImage(null)">
        {{ image.name }}
      </span>
    </a-tooltip>
    <span class="cursor-pointer" style="line-height: 14px;" @click="$refs.inputFile.click()">
      <clip-icon fill="#467BFF" stroke="#467BFF" view-box="0 0 256 256"/>
    </span>

    <input ref="inputFile" class="d-none" type="file" name="attachment" accept="image/*" @change="uploadImage">
  </div>
</template>

<script>
import ClipIcon from '@/assets/icons/clipIcon';

export default {
  components: {
    ClipIcon
  },
  props: {
    update: {type: Boolean, default: false}
  },
  data: () => ({
    image: null
  }),
  watch: {
    update(value) {
      if (value) this.uploadImage(null);
    }
  },
  methods: {
    uploadImage(e) {
      this.image = e ? e.target.files[0] : null;
      this.$emit('change', this.image);
      this.$refs.inputFile.value = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.clip-wrapper::v-deep {
  position: absolute;
  top: calc(50% - 14px);
  right: 24px;

  .image-name {
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>