import Echo from 'laravel-echo';
import store from '@/store';

let authToken = localStorage.getItem('pro__Authorization');
if (authToken) authToken = JSON.parse(authToken).value;

const Socket = {
    installEcho(Vue) {
        if (!authToken) return false;
        window.io = require('socket.io-client');
        Vue.prototype.$echo = new Echo({
            broadcaster: 'socket.io',
            host: process.env.VUE_APP_API_SOCKET_URL,
            auth: {headers: {Authorization: `Bearer ${authToken}`}}
        });

        Vue.prototype.$echo.connector.socket.on('connect', function () {
            store.commit('echo/SET_IS_CONNECTED', true);
        });
        Vue.prototype.$echo.connector.socket.on('disconnect', function () {
            store.commit('echo/SET_IS_CONNECTED', false);
        });
    }
};

export default Socket;
