<template>
  <div>
    <form-input
        class="mb-32px"
        :value="filters.search"
        :placeholder="$t('label.search')"
        allow-clear
        with-icon
        @input="filters.search = $event"
    >
      <template #prefix-icon>
        <search-icon stroke="#467BFF"/>
      </template>
    </form-input>

    <a-row :gutter="32" class="mb-24px">
      <a-col :xs="24" :md="12" :lg="8" class="gutter-row">
        <form-select
            :value="filters.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            allow-clear
            with-icon
            @change="filters.organization_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" class="gutter-row">
        <form-date-range-picker
            :value="filters.date"
            :placeholder="[$t('placeholder.from'), $t('placeholder.to')]"
            allow-clear
            with-icon
            @change="filters.date = $event"
        >
          <template #prefix-icon>
            <calendar-icon stroke="#467BFF" view-box="0 0 32 24"/>
          </template>
        </form-date-range-picker>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" class="gutter-row mb-24px text-right">
        <a-button class="app-button primary w-100" @click="goToNewTicket">{{ $t('btn.create_ticket') }}</a-button>
      </a-col>
    </a-row>

    <app-table
        :columns="tableColumns"
        :data="list"
        :current="computedCurrentPage"
        :total="computedTotalItems"
        scroll
        @getData="getTableData"
    >
      <span slot="create_at_icon">
        <calendar-icon height="16" width="16" stroke="#467BFF" view-box="0 0 32 32"/>
        <span>{{ $t('label.date') }}</span>
      </span>
      <span slot="organization_icon">
        <list-icon height="16" width="16"/>
        <span>{{ $t('label.organization') }}</span>
      </span>
      <span slot="category_icon">
        <warning-octagon-icon height="16" width="16" stroke="#467BFF"/>
        <span>{{ $t('label.issue') }}</span>
      </span>
      <span slot="status_icon">
        <four-circles-icon height="16" width="16" stroke="#467BFF" view-box="0 0 32 32"/>
        <span>{{ $t('label.status') }}</span>
      </span>
      <span slot="message_icon">
        <warning-octagon-icon height="16" width="16" stroke="#467BFF"/>
        <span>{{ $t('label.information') }}</span>
      </span>

      <template v-slot:organization="{ text }">
        <div class="d-flex table-actions-wrapper">{{ text || '-' }}</div>
      </template>
      <template v-slot:category="{ text }">
        <div class="d-flex table-actions-wrapper">{{ text.title }}</div>
      </template>
      <template #status="{ text }">
        <a-tag :color="text.key === 'new' ? 'green' : 'red'">{{ text.title }}</a-tag>
      </template>
      <template v-slot:actions="{ record }">
        <div class="d-flex table-actions-wrapper">
          <a-tooltip>
            <template v-slot:title>
              {{ $t('btn.show_ticket') }}
            </template>
            <span class="cursor-pointer" @click="goToTicket(record)">
              <eye-open-icon height="20" width="20" stroke="#467BFF" view-box="0 0 18 18"/>
            </span>
          </a-tooltip>
          <a-tooltip v-if="record.status.key === 'new'">
            <template v-slot:title>
              {{ $t('btn.close_ticket') }}
            </template>
            <span class="cursor-pointer" @click="showCloseTicketModal(record)">
              <trash-icon height="18" width="18" stroke="#467BFF" view-box="0 0 32 32"/>
            </span>
          </a-tooltip>
        </div>
      </template>
    </app-table>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import generalMixin from '@/mixins/general';
import AppTable from '@/components/elements/AppTable';
import FormDateRangePicker from '@/components/elements/FormDateRangePicker';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';
import CalendarIcon from '@/assets/icons/calendarIcon';
import EyeOpenIcon from '@/assets/icons/eyeOpenIcon';
import FourCirclesIcon from '@/assets/icons/fourCirclesIcon';
import ListIcon from '@/assets/icons/listIcon';
import SearchIcon from '@/assets/icons/searchIcon';
import TrashIcon from '@/assets/icons/trashIcon';
import WarningOctagonIcon from '@/assets/icons/warningOctagonIcon';

export default {
  components: {
    AppTable,
    FormDateRangePicker,
    FormInput,
    FormSelect,
    CalendarIcon,
    EyeOpenIcon,
    FourCirclesIcon,
    ListIcon,
    SearchIcon,
    TrashIcon,
    WarningOctagonIcon
  },
  mixins: [generalMixin],
  data: () => ({
    echoChannel: null,
    echoEvent: null,
    issuesList: [],
    pagination: {page: 1},
    filters: {
      date: null,
      organization_id: null,
      search: null,
    }
  }),
  beforeMount() {
    this.echoChannel = `user.${this.profile.id}`;
    this.echoEvent = '.ticket_change_status';
    this.listen();

    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
  },
  computed: {
    ...mapGetters({
      profile: 'me',
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      list: 'ticketsStore/TABLE_DATA',
      listMeta: 'ticketsStore/TABLE_META',
    }),
    tableColumns() {
      return [
        {
          dataIndex: 'create_at',
          slots: {title: 'create_at_icon'},
          scopedSlots: {customRender: 'create_at'}
        },
        {
          dataIndex: 'organization',
          slots: {title: 'organization_icon'},
          scopedSlots: {customRender: 'organization'}
        },
        {
          dataIndex: 'category',
          slots: {title: 'category_icon'},
          scopedSlots: {customRender: 'category'}
        },
        {
          dataIndex: 'status',
          slots: {title: 'status_icon'},
          scopedSlots: {customRender: 'status'}
        },
        {
          dataIndex: 'message',
          slots: {title: 'message_icon'},
          scopedSlots: {customRender: 'message'}
        },
        {
          title: '',
          dataIndex: 'actions',
          scopedSlots: {customRender: 'actions'}
        }
      ];
    },
    computedCurrentPage() {
      return this.listMeta?.current_page || 1;
    },
    computedTotalItems() {
      return this.listMeta?.total || this.list.length;
    }
  },
  watch: {
    filters: {
      immediate: true,
      handler() {
        this.getTableData();
      },
      deep: true
    }
  },
  methods: {
    getTableData(pagination) {
      let date = this.filters.date;
      if (date && date.length) {
        date = {from: this.getDateStringFromObject(date[0].toDate()), to: this.getDateStringFromObject(date[1].toDate())};
      }
      this.$store.dispatch('ticketsStore/GET_TABLE_DATA', {...this.filters, ...date, ...pagination});
      this.pagination = pagination;
    },
    goToTicket(record) {
      this.$router.push({name: 'ShowContact', params: {id: record.id}});
    },
    goToNewTicket() {
      this.$router.push({name: 'NewContact'});
    },
    showCloseTicketModal(record) {
      this.$confirm({
        title: this.$t('modal.close_ticket'),
        centered: true,
        closable: true,
        okType: 'primary',
        cancelText: this.$t('btn.no'),
        okText: this.$t('btn.yes'),
        onOk: () => {
          this.$store.dispatch('ticketsStore/CHANGE_STATUS', {id: record.id, status: 2})
          .then(() => {
            this.getTableData();
            this.$message.success(this.$t('alert.ticket_closed'));
          })
          .catch(error => {
            this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
          });
        },
        onCancel: () => {
          console.log('cancel');
        },
      });
    },
    listen() {
      this.$echo.private(this.echoChannel)
      .listen(this.echoEvent, response => {
        this.list.map(el => {
          if (el.id === response?.ticket?.id) {
           this.getTableData(this.pagination);
          }
        })
      });
    }
  },
  beforeDestroy() {
    this.$echo.leave(this.echoChannel);
  }
}
</script>