<template>
  <div class="form-element-wrapper app-input">
    <div v-if="label" class="input-label text-grey">
      {{ label }}
      <span v-if="required" class="required text-danger">*</span>
    </div>
    <div v-if="withIcon" class="form-element-icon">
      <slot name="prefix-icon"/>
    </div>
    <a-input
        :class="{'with-icon': withIcon, 'error': error, 'with-suffix-icon': type === 'password'}"
        :type="inputType"
        :value="value"
        :placeholder="placeholder"
        :allow-clear="allowClear"
        :disabled="disabled"
        @keydown="$emit('keydown', $event)"
        @change="onChange"
    />
    <div v-if="type === 'password'">
      <span v-if="!showPass" class="suffix-icon cursor-pointer" @click="showPass = true">
        <eye-close-icon stroke="#467BFF"/>
      </span>
      <span v-else class="suffix-icon open cursor-pointer" @click="showPass = false">
        <eye-open-icon height="36" width="36" stroke="#467BFF"/>
      </span>
    </div>
    <div v-if="error" class="error-msg position-absolute text-danger fs-sm nowrap">{{ error }}</div>
  </div>
</template>
<script>
import EyeCloseIcon from '@/assets/icons/eyeCloseIcon';
import EyeOpenIcon from '@/assets/icons/eyeOpenIcon';

export default {
  components: {
    EyeCloseIcon,
    EyeOpenIcon
  },
  props: {
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    value: {type: [Number, String], default: ''},
    type: {type: String, default: 'text'},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    error: {type: String, default: null},
    allowClear: {type: Boolean, default: false},
    withIcon: {type: Boolean, default: false}
  },
  data: () => ({
    inputType: 'text',
    showPass: false
  }),
  watch: {
    type: {
      immediate: true,
      handler(value) {
        if (value === 'password') this.inputType = 'password'
      }
    },
    showPass(value) {
      this.inputType = value ? 'text' : 'password';
    }
  },
  methods: {
    onChange(e) {
      if (e.target.value) {
        this.$emit('filled');
      }
      this.$emit('input', e.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-input::v-deep {
  .input-label {
    margin-bottom: 8px;
  }

  .with-icon {
    .ant-input {
      padding-left: 48px;
    }
  }

  .anticon {
    color: #467BFF;
  }

  .ant-input {
    height: 48px;
    padding: 8px 16px;
    border: 1px solid transparent;
    font-size: 15px;

    &.error {
      border: 1px solid #FF655B;

      &:not(:focus) {
        background: #FFF6F5;
      }
    }

    &.with-icon {
      padding-left: 48px;
    }

    &.with-suffix-icon {
      padding-right: 48px;
    }

    &.ant-input-disabled {
      background: #E4E4E4;
    }

    &:not(.ant-input-disabled) {
      color: #132540;
    }

    &::-webkit-input-placeholder {
      color: #6886A6;
    }

    &::-moz-placeholder {
      color: #6886A6;
    }

    &:-ms-input-placeholder {
      color: #6886A6;
    }

    &:-moz-placeholder {
      color: #6886A6;
    }
  }
}

.suffix-icon {
  position: absolute;
  right: 18px;
  bottom: 5px;

  &.open {
    right: 7px;
    bottom: -5px;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .app-input::v-deep {
    .with-icon {
      .ant-input {
        padding-left: 48px;
      }
    }

    .ant-input {
      font-size: 14px;

      &.with-icon {
        padding-left: 48px;
      }
    }
  }
}
</style>