import {getHistory, getPicosFromBase} from '@/api/picos';

export default {
    namespaced: true,
    state: {
        history: null,
        historyMeta: {total: 0},
        picos: [],
    },
    getters: {
        HISTORY(state) {
            return state.history;
        },
        HISTORY_META(state) {
            return state.historyMeta;
        },
        PICOS(state) {
            return state.picos;
        }
    },
    mutations: {
        SET_HISTORY(state, data) {
            state.history = data;
        },
        SET_HISTORY_META(state, data) {
            state.historyMeta = data;
        },
        SET_PICOS(state, data) {
            state.picos = data;
        }
    },
    actions: {
        GET_HISTORY(context, data) {
            return new Promise((resolve, reject) => {
                getHistory(data).then(response => {
                    context.commit('SET_HISTORY', response.data);
                    context.commit('SET_HISTORY_META', response.meta);
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_PICOS_FROM_BASE(context, baseId) {
            return new Promise((resolve, reject) => {
                getPicosFromBase(baseId).then(response => {
                    context.commit('SET_PICOS', response.data);
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
}