<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75 3C8.75 2.86193 8.86193 2.75 9 2.75H15C15.1381 2.75 15.25 2.86193 15.25 3V4V5C15.25 5.13807 15.1381 5.25 15 5.25H9C8.86193 5.25 8.75 5.13807 8.75 5V4V3ZM7.25 3.25V3C7.25 2.0335 8.0335 1.25 9 1.25H15C15.9665 1.25 16.75 2.0335 16.75 3V3.25H18C18.7293 3.25 19.4288 3.53973 19.9445 4.05546C20.4603 4.57118 20.75 5.27065 20.75 6V20C20.75 20.7293 20.4603 21.4288 19.9445 21.9445C19.4288 22.4603 18.7293 22.75 18 22.75H6C5.27065 22.75 4.57118 22.4603 4.05546 21.9445C3.53973 21.4288 3.25 20.7293 3.25 20V6C3.25 5.27066 3.53973 4.57118 4.05546 4.05546C4.57118 3.53973 5.27065 3.25 6 3.25H7.25ZM16.75 4.75H18C18.3315 4.75 18.6495 4.8817 18.8839 5.11612C19.1183 5.35054 19.25 5.66848 19.25 6V20C19.25 20.3315 19.1183 20.6495 18.8839 20.8839C18.6495 21.1183 18.3315 21.25 18 21.25H6C5.66848 21.25 5.35054 21.1183 5.11612 20.8839C4.8817 20.6495 4.75 20.3315 4.75 20V6C4.75 5.66848 4.8817 5.35054 5.11612 5.11612C5.35054 4.8817 5.66848 4.75 6 4.75H7.25V5C7.25 5.9665 8.0335 6.75 9 6.75H15C15.9665 6.75 16.75 5.9665 16.75 5V4.75Z" fill="#467BFF"/>
    <path d="M8 11H16" :stroke="stroke" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M8 16H16" :stroke="stroke" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>