export default {
    namespaced: true,
    state: {
        isConnected: false
    },
    getters: {
        IS_CONNECTED(state) {
            return state.isConnected;
        }
    },
    mutations: {
        SET_IS_CONNECTED(state, value) {
            state.isConnected = value;
        }
    },
    actions: {}
}