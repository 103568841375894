<template>
  <div>
    <a-row :gutter="32">
      <a-col class="gutter-row" :span="8">
        <form-switcher
            class="mb-32px"
            :label="$t('label.lock_mode')"
            :value="form.is_lock_mode"
            @change="form.is_lock_mode = $event"
        />
        <form-input
            class="mb-32px"
            :label="$t('label.number_of_pico_used_by_one_user')"
            :placeholder="$t('label.number_of_pico_used_by_one_user')"
            :value="form.number_limit"
            required
            :error="error.number_limit"
            @keydown="validateNumber"
            @filled="error.number_limit = null"
            @input="form.number_limit = $event"
        />
        <form-switcher
            class="mb-32px"
            :label="$t('label.limit_authorized_area')"
            :value="form.is_area_limited"
            @change="form.is_area_limited = $event"
        />
        <form-input-select
            class="mb-32px"
            :label="$t('label.maximum_radius')"
            :input-placeholder="$t('label.maximum_radius')"
            :input-value="form.area_limit.radius"
            :select-value="form.area_limit.unit"
            :options="maximumRadiusOptions"
            :required="form.is_area_limited"
            :disabled="!form.is_area_limited"
            :error="error.area_limit"
            active-first-option
            input-width="80"
            select-width="20"
            @keydown="validateNumber"
            @filled="error.area_limit = null"
            @input="form.area_limit.radius = $event"
            @change="form.area_limit.unit = $event"
        />
        <form-switcher
            class="mb-32px"
            :label="$t('label.usb_c_100_w')"
            :value="form.is_input_usb_c_100w"
            @change="form.is_input_usb_c_100w = $event"
        />
      </a-col>
      <a-col class="gutter-row" :span="8">
        <form-switcher
            class="mb-32px"
            :label="$t('label.limit_holding_time')"
            :value="form.is_time_limited"
            @change="form.is_time_limited = $event"

        />
        <form-input-select
            class="mb-32px"
            :label="$t('label.holding_time')"
            :input-placeholder="$t('label.holding_time')"
            :input-value="form.time_limit.duration"
            :select-value="form.time_limit.unit"
            :options="holdingTimeOptions"
            :required="form.is_time_limited"
            :disabled="!form.is_time_limited"
            :error="error.time_limit"
            active-first-option
            input-width="80"
            select-width="20"
            @keydown="validateNumber"
            @filled="error.time_limit = null"
            @input="form.time_limit.duration = $event"
            @change="form.time_limit.unit = $event"
        />
        <form-switcher
            class="mb-32px"
            :label="$t('label.nfc_badge_reader')"
            :value="form.is_nfc_reader_on"
            @change="form.is_nfc_reader_on = $event"
        />
        <form-switcher
            class="mb-32px"
            :label="$t('label.enable_gps')"
            :value="form.is_gps_on"
            @change="form.is_gps_on = $event"
        />
      </a-col>
    </a-row>
    <a-button class="app-button submit-base-btn primary" @click="submit">{{ $t('btn.save_bases_pico_info') }}</a-button>
  </div>
</template>

<script>
import validationMixin from '@/mixins/validation'
import FormInput from '@/components/elements/FormInput'
import FormInputSelect from '@/components/elements/FormInputSelect'
import FormSwitcher from '@/components/elements/FormSwitcher'

export default {
  components: {
    FormInput,
    FormInputSelect,
    FormSwitcher
  },
  mixins: [validationMixin],
  props: {
    organization: {
      type: Object, default: () => {
      }
    }
  },
  data: () => ({
    form: {
      number_limit: null,
      is_lock_mode: false,
      is_area_limited: false,
      is_input_usb_c_100w: false,
      is_time_limited: false,
      is_nfc_reader_on: false,
      is_gps_on: false,
      area_limit: {radius: 0, unit: 'm'},
      time_limit: {duration: 0, unit: 'm'}
    },
    error: {
      number_limit: null,
      area_limit: null,
      time_limit: null
    }
  }),
  computed: {
    holdingTimeOptions() {
      return [
        {value: 'm', label: 'm'},
        {value: 'h', label: 'h'},
        {value: 'd', label: 'd'}
      ];
    },
    maximumRadiusOptions() {
      return [
        {value: 'm', label: 'm'},
        {value: 'km', label: 'km'}
      ];
    },
    formIsAreaLimited() {
      return this.form.is_area_limited;
    },
    formIsTimeLimited() {
      return this.form.is_time_limited;
    },
  },
  watch: {
    organization: {
      immediate: true,
      handler(data) {
        this.form.is_lock_mode = data.is_lock_mode;
        this.form.number_limit = data.number_limit;
        this.form.is_area_limited = data.is_area_limited;
        if (data.area_limit) {
          this.form.area_limit.radius = data.area_limit.radius;
          this.form.area_limit.unit = data.area_limit.unit;
        }
        this.form.is_input_usb_c_100w = data.is_input_usb_c_100w;
        this.form.is_time_limited = data.is_time_limited;
        if (data.time_limit) {
          this.form.time_limit.duration = data.time_limit.duration;
          this.form.time_limit.unit = data.time_limit.unit;
        }
        this.form.is_nfc_reader_on = data.is_nfc_reader_on;
        this.form.is_gps_on = data.is_gps_on;
      }
    },
    formIsAreaLimited(value) {
      if (!value) {
        this.form.area_limit.radius = 0;
        this.error.area_limit = false;
      }
    },
    formIsTimeLimited(value) {
      if (!value) {
        this.form.time_limit.duration = 0;
        this.error.time_limit = false;
      }
    },
  },
  methods: {
    clearError() {
      Object.entries(this.error).map(el => this.error[el[0]] = null);
    },
    validateForm() {
      this.error.number_limit = !this.form.number_limit ? this.$t('error.required') : null;
      this.error.area_limit = this.form.is_area_limited && !parseInt(this.form.area_limit.radius) ? this.$t('error.required') : null;
      this.error.time_limit = this.form.is_time_limited && !parseInt(this.form.time_limit.duration) ? this.$t('error.required') : null;

      console.log(this.error);

      return !Object.values(this.error).some(v => v);
    },
    submit() {
      this.clearError();
      if (!this.validateForm()) return false;

      this.form.area_limit.radius = this.form.area_limit.radius ? parseInt(this.form.area_limit.radius) : 0;
      this.form.time_limit.duration = this.form.time_limit.duration ? parseInt(this.form.time_limit.duration) : 0;

      this.$store.dispatch('organizationsStore/UPDATE', {id: this.organization.id, data: this.form})
      .then(() => {
        this.$message.success(this.$t('alert.information_updated'));
      })
      .catch(error => {
        if (error.response.status === 422) {
          for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
            let formattedKey = key.split('.');
            this.error[formattedKey[0]] = errorMessage[0];
          }
        } else {
          this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
        }
      });
    }
  }
}
</script>