import {axios} from '@/utils/request';
import { OpenStreetMapProvider } from 'leaflet-geosearch'

export function getRelatedLocations(q) {
    const provider = new OpenStreetMapProvider();
    return provider.search({ query: q });
}

export function getNotificationSettings(organizationId) {
    return axios({url: `v2/notification-setting/${organizationId}`, method: 'get'});
}

export function updateNotificationSettings(organizationId, data) {
    return axios({url: `v2/notification-setting/${organizationId}`, method: 'patch', data: data});
}