<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_483_5525)">
      <path d="M12 7.5V12.75" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.4219 3H8.57813C8.48074 2.99966 8.38424 3.01853 8.29415 3.05553C8.20407 3.09253 8.12216 3.14694 8.05313 3.21563L3.21563 8.05313C3.14694 8.12216 3.09253 8.20407 3.05553 8.29415C3.01853 8.38424 2.99966 8.48074 3 8.57813V15.4219C2.99966 15.5193 3.01853 15.6158 3.05553 15.7059C3.09253 15.7959 3.14694 15.8778 3.21563 15.9469L8.05313 20.7844C8.12216 20.8531 8.20407 20.9075 8.29415 20.9445C8.38424 20.9815 8.48074 21.0003 8.57813 21H15.4219C15.5193 21.0003 15.6158 20.9815 15.7059 20.9445C15.7959 20.9075 15.8778 20.8531 15.9469 20.7844L20.7844 15.9469C20.8531 15.8778 20.9075 15.7959 20.9445 15.7059C20.9815 15.6158 21.0003 15.5193 21 15.4219V8.57813C21.0003 8.48074 20.9815 8.38424 20.9445 8.29415C20.9075 8.20407 20.8531 8.12216 20.7844 8.05313L15.9469 3.21563C15.8778 3.14694 15.7959 3.09253 15.7059 3.05553C15.6158 3.01853 15.5193 2.99966 15.4219 3V3Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 17.0625C12.5178 17.0625 12.9375 16.6428 12.9375 16.125C12.9375 15.6072 12.5178 15.1875 12 15.1875C11.4822 15.1875 11.0625 15.6072 11.0625 16.125C11.0625 16.6428 11.4822 17.0625 12 17.0625Z" fill="#4D4D4D"/>
    </g>
    <defs>
      <clipPath id="clip0_483_5525">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>