<template>
  <div>
    <a-modal class="create-user-modal" v-model="visible" title="Basic Modal">
      <div class="title mb-40px">{{ $t(`btn.${mode}_user`) }}</div>
      <a-row :gutter="32">
        <a-col class="gutter-row" :span="12">
          <form-input
              class="mb-32px"
              :label="$t('label.first_name')"
              :placeholder="$t('label.first_name')"
              :value="form.first_name"
              :error="error.first_name"
              @input="form.first_name = $event"
          />
          <form-input
              class="mb-32px"
              :label="$t('label.last_name')"
              :placeholder="$t('label.last_name')"
              :value="form.last_name"
              :error="error.last_name"
              @input="form.last_name = $event"
          />
          <form-input
              class="mb-32px"
              :label="$t('label.email')"
              :placeholder="$t('label.email')"
              :value="form.email"
              :required="!form.nfc_badge_id"
              :error="error.email"
              @filled="error.email = error.nfc_badge_id = null"
              @input="form.email = $event"
          />
        </a-col>
        <a-col class="gutter-row" :span="12">
          <form-select
              class="mb-32px"
              :label="$t('label.organization')"
              :placeholder="$t('label.list_of_organizations')"
              :options="computedOrganizationsList"
              :value="form.organization_id"
              :active-first-option="mainRoleName !== 'superadmin'"
              :error="error.organization_id"
              @change="form.organization_id = $event"
          />
          <form-input
              class="mb-32px"
              :label="$t('label.nfc_badge_id')"
              :placeholder="$t('label.nfc_badge_id')"
              :value="form.nfc_badge_id"
              :required="!form.email"
              :error="error.nfc_badge_id"
              @filled="error.email = error.nfc_badge_id = null"
              @input="form.nfc_badge_id = $event"
          />
          <form-input
              class="mb-32px"
              :label="$t('label.user_id')"
              :placeholder="$t('label.user_id')"
              :value="form.user_id"
              :error="error.user_id"
              @input="form.user_id = $event"
          />
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" class="app-button" @click="$emit('close')">
          {{ $t('btn.cancel') }}
        </a-button>
        <a-button v-if="mode === 'create'" key="submit" class="app-button primary" :loading="loading"
                  @click="createUser">
          {{ $t('btn.create_user') }}
        </a-button>
        <a-button v-else key="submit" class="app-button primary" :loading="loading" @click="updateUser">
          {{ $t('btn.update_user') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import validationMixin from '@/mixins/validation';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';

export default {
  components: {
    FormInput,
    FormSelect
  },
  mixins: [validationMixin],
  props: {
    show: {type: Boolean, default: false},
    userSlug: {type: String, default: null},
  },
  data: () => ({
    loading: false,
    visible: false,
    mode: 'create',
    form: {
      email: null,
      first_name: null,
      last_name: null,
      nfc_badge_id: null,
      organization_id: -1,
      user_id: null
    },
    error: {
      email: null,
      first_name: null,
      last_name: null,
      nfc_badge_id: null,
      organization_id: null,
      user_id: null
    },
    required: ['email', 'nfc_badge_id']
  }),
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      user: 'usersStore/USER',
      mainRoleName: 'getMainRoleName'
    }),
    computedOrganizationsList() {
      let list = [...this.organizationsList];
      if (this.mainRoleName === 'superadmin') {
        list.unshift({value: -1, label: this.$t('label.not_selected')});
      }
      return list;
    }
  },
  watch: {
    show(value) {
      this.visible = value;
    },
    visible(value) {
      if (!value) {
        this.clearForm();
        this.clearError();
        this.$emit('close', true);
      }
    },
    userSlug(slug) {
      this.mode = slug ? 'update' : 'create';
      if (slug) {
        this.$store.dispatch('usersStore/GET_USER', slug);
      }
    },
    user: {
      handler(data) {
        if (data) {
          this.form.email = data.email;
          this.form.first_name = data.first_name;
          this.form.last_name = data.last_name;
          this.form.nfc_badge_id = data.nfc_badge_id;
          this.form.organization_id = data.organization_id;
          this.form.user_id = data.user_id;
        }
      },
      deep: true
    }
  },
  methods: {
    clearForm() {
      Object.entries(this.form).map(el => {
        if (el[0] !== 'organization_id') {
          this.form[el[0]] = null;
        }
      });
    },
    clearError() {
      Object.entries(this.error).map(el => this.error[el[0]] = null);
    },
    validateForm() {
      this.clearError();

      if (!this.form.email && !this.form.nfc_badge_id) {
        this.error.email = this.error.nfc_badge_id = this.$t('error.email_or_nfc_id_required');
      } else if (!this.validateEmail(this.form.email) && !this.form.nfc_badge_id) {
        this.error.email = this.$t('error.invalid_email');
      }
      return !Object.values(this.error).some(v => v);
    },
    createUser() {
      if (!this.validateForm()) return false;
      this.loading = true;

      let organizationId = this.form.organization_id !== -1 ? this.form.organization_id : null;

      this.$store.dispatch('usersStore/CREATE_USER', {...this.form, organization_id: organizationId})
      .then(() => {
        this.loading = this.visible = false;
        this.$message.success(this.$t('alert.user_created'));
      })
      .catch(error => {
        this.loading = false;

        if (error.response.status === 422) {
          for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
            this.error[key] = errorMessage[0];
          }
        } else {
          this.$message.error(error.response.data.error.message || this.$t('alert.something_goes_wrong'));
        }
      });
    },
    updateUser() {
      if (!this.validateForm()) return false;
      this.loading = true;

      this.$store.dispatch('usersStore/UPDATE_USER', {slug: this.user.slug, data: {...this.form, is_active: true}})
      .then(() => {
        this.loading = this.visible = false;
        this.$message.success(this.$t('alert.user_updated'));
      })
      .catch(error => {
        this.loading = false;

        if (error.response.status === 422) {
          for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
            this.error[key] = errorMessage[0];
          }
        } else {
          this.$message.error(error.response.data.error.message || this.$t('alert.something_goes_wrong'));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-user-modal::v-deep {
  .ant-modal {
    width: 800px !important;

    .title {
      color: #000;
      font-size: 36px;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>