<template>
  <div class="">
    <div v-if="show" style="height: 300px" class="map-wrapper">
      <l-map
          :ref="refer"
          :zoom="map.zoom"
          :center="map.center"
          :options="map.mapOptions"
          @click="radiusMap ? null : $emit('handleMapClick', $event)"
      >
        <l-tile-layer :url="map.url" :attribution="map.attribution"/>
        <l-marker v-if="location" :lat-lng="location">
          <l-icon :icon-size="[16, 16]">
            <div class="map-icon"></div>
          </l-icon>
        </l-marker>
        <l-circle v-if="circle" :lat-lng="circle.center" :radius="circle.radius" :stroke="false"/>
      </l-map>
<!--      <div @click="$emit('openMap')" class="open-big-map">-->
<!--        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M8.58892 7.8388L4.48819 3.73807L8.11945 3.6899C8.40966 3.68605 8.65093 3.44478 8.65478 3.15457C8.65576 3.08566 8.64315 3.01759 8.61765 2.95426C8.59216 2.89093 8.55429 2.83359 8.50621 2.7855C8.45813 2.73742 8.40078 2.69955 8.33745 2.67406C8.27412 2.64856 8.20605 2.63595 8.13714 2.63693L3.23345 2.70198C3.16454 2.70282 3.09611 2.71725 3.03208 2.74443C2.96805 2.77162 2.90968 2.81102 2.86031 2.8604C2.81093 2.90977 2.77153 2.96814 2.74434 3.03217C2.71716 3.0962 2.70273 3.16463 2.70189 3.23354L2.63685 8.13723C2.63593 8.20612 2.6486 8.27415 2.67412 8.33745C2.69963 8.40075 2.73751 8.45807 2.78558 8.50614C2.83364 8.5542 2.89096 8.59208 2.95426 8.6176C3.01756 8.64312 3.08559 8.65578 3.15448 8.65487C3.22338 8.65395 3.29177 8.63948 3.35577 8.61227C3.41977 8.58506 3.47811 8.54565 3.52747 8.49629C3.57683 8.44693 3.61624 8.38859 3.64345 8.32459C3.67065 8.2606 3.68513 8.1922 3.68604 8.12331L3.73421 4.49205L7.83494 8.59278C8.03686 8.7947 8.37171 8.79026 8.57905 8.58291C8.7864 8.37557 8.79084 8.04072 8.58892 7.8388Z" fill="#000000"/>-->
<!--          <path d="M14.5285 15.2808L18.6292 19.3816L14.998 19.4297C14.7078 19.4336 14.4665 19.6749 14.4627 19.9651C14.4617 20.034 14.4743 20.102 14.4998 20.1654C14.5253 20.2287 14.5631 20.286 14.6112 20.3341C14.6593 20.3822 14.7167 20.4201 14.78 20.4456C14.8433 20.4711 14.9114 20.4837 14.9803 20.4827L19.884 20.4176C19.9529 20.4168 20.0213 20.4024 20.0853 20.3752C20.1494 20.348 20.2078 20.3086 20.2571 20.2592C20.3065 20.2099 20.3459 20.1515 20.3731 20.0875C20.4003 20.0234 20.4147 19.955 20.4155 19.8861L20.4806 14.9824C20.4815 14.9135 20.4688 14.8455 20.4433 14.7822C20.4178 14.7189 20.3799 14.6616 20.3319 14.6135C20.2838 14.5654 20.2265 14.5276 20.1632 14.502C20.0999 14.4765 20.0318 14.4638 19.9629 14.4648C19.8941 14.4657 19.8257 14.4802 19.7617 14.5074C19.6977 14.5346 19.6393 14.574 19.59 14.6233C19.5406 14.6727 19.5012 14.731 19.474 14.795C19.4468 14.859 19.4323 14.9274 19.4314 14.9963L19.3832 18.6276L15.2825 14.5268C15.0806 14.3249 14.7457 14.3294 14.5384 14.5367C14.331 14.7441 14.3266 15.0789 14.5285 15.2808Z" fill="#000000"/>-->
<!--          <path d="M14.5307 7.8388L18.6314 3.73807L15.0002 3.6899C14.71 3.68605 14.4687 3.44478 14.4649 3.15457C14.4639 3.08566 14.4765 3.01759 14.502 2.95426C14.5275 2.89093 14.5653 2.83359 14.6134 2.7855C14.6615 2.73742 14.7188 2.69955 14.7822 2.67406C14.8455 2.64856 14.9136 2.63595 14.9825 2.63693L19.8862 2.70198C19.9551 2.70282 20.0235 2.71725 20.0875 2.74443C20.1516 2.77162 20.2099 2.81102 20.2593 2.8604C20.3087 2.90977 20.3481 2.96814 20.3753 3.03217C20.4025 3.0962 20.4169 3.16463 20.4177 3.23354L20.4828 8.13723C20.4837 8.20612 20.471 8.27415 20.4455 8.33745C20.42 8.40075 20.3821 8.45807 20.3341 8.50614C20.286 8.5542 20.2287 8.59208 20.1654 8.6176C20.1021 8.64312 20.034 8.65578 19.9651 8.65487C19.8963 8.65395 19.8279 8.63948 19.7639 8.61227C19.6999 8.58506 19.6415 8.54565 19.5922 8.49629C19.5428 8.44693 19.5034 8.38859 19.4762 8.32459C19.449 8.2606 19.4345 8.1922 19.4336 8.12331L19.3854 4.49205L15.2847 8.59278C15.0828 8.7947 14.7479 8.79026 14.5406 8.58291C14.3332 8.37557 14.3288 8.04072 14.5307 7.8388Z" fill="#000000"/>-->
<!--          <path d="M8.59088 15.5958L4.49014 19.6965L8.12141 19.7447C8.41161 19.7485 8.65288 19.9898 8.65673 20.28C8.65772 20.3489 8.6451 20.417 8.61961 20.4803C8.59411 20.5436 8.55624 20.601 8.50816 20.6491C8.46008 20.6971 8.40273 20.735 8.3394 20.7605C8.27607 20.786 8.208 20.7986 8.13909 20.7976L3.2354 20.7326C3.16649 20.7317 3.09806 20.7173 3.03403 20.6901C2.97001 20.663 2.91163 20.6235 2.86226 20.5742C2.81289 20.5248 2.77348 20.4664 2.7463 20.4024C2.71911 20.3384 2.70469 20.2699 2.70384 20.201L2.6388 15.2973C2.63788 15.2285 2.65055 15.1604 2.67607 15.0971C2.70159 15.0338 2.73946 14.9765 2.78753 14.9284C2.8356 14.8804 2.89292 14.8425 2.95621 14.817C3.01951 14.7915 3.08755 14.7788 3.15644 14.7797C3.22533 14.7806 3.29373 14.7951 3.35772 14.8223C3.42172 14.8495 3.48006 14.8889 3.52942 14.9383C3.57878 14.9876 3.61819 15.046 3.6454 15.11C3.67261 15.174 3.68708 15.2424 3.688 15.3113L3.73616 18.9425L7.8369 14.8418C8.03881 14.6399 8.37366 14.6443 8.58101 14.8517C8.78835 15.059 8.79279 15.3939 8.59088 15.5958Z" fill="#000000"/>-->
<!--        </svg>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LCircle, LIcon } from 'vue2-leaflet'

export default {
  name: 'AppLocationMap',
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LCircle,
    LIcon,
  },
  props: {
    show: {type: Boolean, default: false},
    refer: {type: String, default: ''},
    selectedLocation: {type: Object, default: () => {}},
    circle: {type: Object, default: () => {}},
    radiusMap: {type: Boolean, default: false}
  },
  data: () => ({
    location: null,
    map: {
      zoom: 13,
      center: {lat: null, lon: null},
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      latitude: null,
      longitude: null
    }
  }),
  watch: {
    selectedLocation(data) {
      if (data) {
        this.location = latLng(data.y, data.x);
      }
    },
    location: {
      handler(data) {
        this.map.center = [data?.lat, data?.lng];
        this.map.longitude = data.lng;
        this.map.latitude = data.lat;
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.map-block {
  margin-left: auto;
  max-width: 330px;
  margin-bottom: 12px;

  .map-wrapper {
    width: 100%;
    height: 125px;
  }
}
.details-radius-map {
  .map-wrapper {
    width: 325px;
    height: 125px;
  }
}
.map-wrapper {
  position: relative;
  width: 245px;
  height: 93px;
  margin-bottom: 12px;

  .vue2leaflet-map {
    z-index: 1;
  }
  .open-big-map {
    position: absolute;
    bottom: 9px;
    right: 9px;
    z-index: 2;
    cursor: pointer;
    height: 24px;
  }
}
.map-icon {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #4FB0EF;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: rgba(#4FB0EF, .5);
  }
}
.map-modal {
  .ant-modal-close {
    z-index: 499;
  }
  .ant-modal-body {
    padding: 0;
  }
}
.big-map-wrapper {
  width: 100%;
  height: 644px;
}
.leaflet-control-attribution {
  display: none;
}
.coordinates-wrapper {
  width: 245px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 20px;
  grid-template-areas: ". .";

  .ant-form-item {
    padding: 0;
  }
}
</style>