<template>
  <div class="app-textarea">
    <div v-if="label" class="input-label">{{ label }}</div>
    <a-textarea
        :class="[elementClass, {'error': error}]"
        :value="value"
        :placeholder="placeholder"
        :rows="rows"
        :disabled="disabled"
        @change="onChange"
    />
    <div v-if="error" class="error-msg position-absolute text-danger fs-sm nowrap">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {type: String, default: ''},
    value: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    rows: {type: Number, default: 4},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    error: {type: String, default: null},
    elementClass: {type: String, default: ''}
  },
  methods: {
    onChange(e) {
      this.$emit('filled');
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-textarea {
  .input-label {
    margin-bottom: 8px;
    color: #6886A6;
  }

  .ant-input {
    padding: 8px 16px;
    border: 1px solid transparent;
    color: #132540;

    &.error {
      border: 1px solid #FF655B;

      &:not(:focus) {
        background: #FFF6F5;
      }
    }

    &::-webkit-input-placeholder {
      color: #6886A6;
    }
    &::-moz-placeholder {
      color: #6886A6;
    }
    &:-ms-input-placeholder {
      color: #6886A6;
    }
    &:-moz-placeholder {
      color: #6886A6;
    }
  }
}
</style>