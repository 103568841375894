<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.98242 18.6913C6.54737 17.5806 7.40866 16.6478 8.47095 15.9963C9.53324 15.3448 10.7551 15 12.0012 15C13.2474 15 14.4693 15.3448 15.5316 15.9963C16.5938 16.6478 17.4551 17.5806 18.0201 18.6913" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>