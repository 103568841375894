<template>
  <a-breadcrumb separator=" ">
    <a-breadcrumb-item v-for="(item, idx) in items" :key="idx">
      <router-link :to="{name: item.route}">{{ item.name }}</router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  props: {
    items: {type: Array, default: () => []}
  }
}
</script>

<style lang="scss" scoped>
.ant-breadcrumb::v-deep {
  > span {
    color: #6886A6;

    &:not(:last-child) {
      a {
        color: #6886A6 !important;
      }
    }
  }

  .ant-breadcrumb-separator {
    height: 2px;
    width: 2px;
    margin: 0 15px 4px 15px;
    display: inline-block;
    background-color: #6886A6;
    border-radius: 50%;
  }
}
</style>