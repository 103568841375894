<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3.49951C3 3.49951 1 8 1 8C1 8 3 12.4995 8 12.4995C13 12.4995 15 8 15 8C15 8 13 3.49951 8 3.49951Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>