<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_950)">
      <path d="M5.5 14.5H10.5" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 12.5V9" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 7L8 9L10 7" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.91898 10.4382C4.32445 9.97389 3.8429 9.38082 3.51054 8.70359C3.17818 8.02637 3.00365 7.28262 3.00008 6.52825C2.9851 3.8181 5.17 1.56522 7.87944 1.50145C8.92935 1.47617 9.96058 1.78212 10.8268 2.37589C11.6931 2.96967 12.3504 3.82113 12.7055 4.80949C13.0606 5.79786 13.0955 6.87294 12.8052 7.88225C12.5149 8.89156 11.9142 9.78384 11.0883 10.4325C10.9064 10.5735 10.7589 10.7539 10.657 10.9603C10.555 11.1666 10.5014 11.3934 10.5 11.6235L10.5 12C10.5 12.1326 10.4473 12.2598 10.3536 12.3536C10.2598 12.4474 10.1326 12.5 10 12.5H5.99999C5.86738 12.5 5.74021 12.4474 5.64644 12.3536C5.55267 12.2598 5.49999 12.1326 5.49999 12L5.49998 11.6231C5.49942 11.3945 5.44681 11.1689 5.34614 10.9637C5.24548 10.7584 5.0994 10.5787 4.91898 10.4382V10.4382Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>