<template>
  <div>
    <div class="d-flex jcb aic mb-24px">
      <div class="fs-xl fw-500 text-dark">{{ `${$t('label.ticket')} #${ticketId}` }}</div>
    </div>
    <ticket-messenger
        class="messenger-wrapper"
        :ticket="ticket"
        with-image-upload
        @close="showCloseTicketModal"
        @send="sendMessage"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TicketMessenger from '@/components/custom/TicketMessenger';

export default {
  components: {
    TicketMessenger
  },
  data: () => ({
    echoChannel: null,
    echoEvent: null,
    ticketId: null
  }),
  beforeMount() {
    this.echoChannel = `user.${this.profile.id}`;
    this.echoEvent = '.ticket_client_added_message';
    this.listen();

    this.ticketId = this.$route.params.id;
    this.getTicket();
  },
  computed: {
    ...mapGetters({
      profile: 'me',
      ticket: 'ticketsStore/TICKET'
    }),
    status() {
      return this.ticket?.status?.key;
    }
  },
  methods: {
    showCloseTicketModal() {
      this.$confirm({
        title: this.$t('modal.close_ticket'),
        centered: true,
        closable: true,
        okType: 'primary',
        cancelText: this.$t('btn.no'),
        okText: this.$t('btn.yes'),
        onOk: () => {
          this.$store.dispatch('ticketsStore/CHANGE_STATUS', {id: this.ticket.id, status: 2})
          .then(() => {
            this.getTicket();
            this.$message.success(this.$t('alert.ticket_closed'));
          })
          .catch(error => {
            this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
          });
        },
        onCancel: () => {
          console.log('cancel');
        },
      });
    },
    getTicket() {
      this.$store.dispatch('ticketsStore/GET_TICKET', this.ticketId);
    },
    sendMessage(data) {
      let formData = new FormData();
      formData.append('message', data.message);
      if (data.attachment) formData.append('attachment', data.attachment);

      this.$store.dispatch('ticketsStore/SEND_MESSAGE', {ticketId: this.ticketId, data: formData});
    },
    listen() {
      this.$echo.private(this.echoChannel).listen(this.echoEvent, () => this.getTicket());
    }
  },
  beforeDestroy() {
    this.$echo.leave(this.echoChannel);
    this.$store.commit('ticketsStore/CLEAR_TICKET');
  }
}
</script>

<style lang="scss" scoped>
.messenger-wrapper::v-deep {
  .messages-wrapper {
    height: 33vh;

    &.closed {
      height: 50vh !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .messenger-wrapper::v-deep {
    .messages-wrapper {
      height: 30vh;

      &.closed {
        height: 50vh !important;
      }
    }
  }
}
</style>