<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_325_2839)">
      <path d="M25.9987 11H5.99866C5.44637 11 4.99866 11.4477 4.99866 12V26C4.99866 26.5523 5.44637 27 5.99866 27H25.9987C26.5509 27 26.9987 26.5523 26.9987 26V12C26.9987 11.4477 26.5509 11 25.9987 11Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.4987 11V6.5C11.4987 5.30653 11.9728 4.16193 12.8167 3.31802C13.6606 2.47411 14.8052 2 15.9987 2C17.1921 2 18.3367 2.47411 19.1806 3.31802C20.0246 4.16193 20.4987 5.30653 20.4987 6.5" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_325_2839">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>