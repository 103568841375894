import { axios } from '@/utils/request';
import generalMixin from '@/mixins/general';

export function getBoxData(organizationId) {
    return axios({url: `v2/dashboard/box?organization_id=${organizationId}`, method: 'get'})
}

export function getTableData(filters) {
    const date = generalMixin.methods.dateToQuery(filters.date);
    delete filters.date;
    const filtersString = generalMixin.methods.objectToQuery(filters) + date;

    return axios({url: `v2/dashboard/table${filtersString}`, method: 'get'});
}