export default {
    methods: {
        validateEmail(value) {
            let emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

            return !(!value || !emailPattern.test(value));
        },
        validateNumber(evt, additionalSymbols = []) {
            let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ArrowLeft', 'ArrowRight', 'Shift', 'Backspace', 'Delete'];
            if (additionalSymbols.length) {
                keysAllowed.push(...additionalSymbols);
            }
            const keyPressed = evt.key;

            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
        }
    }
}