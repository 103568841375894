import { axios } from '@/utils/request';
import generalMixin from '@/mixins/general';

export function getTableData(filters) {
    if ('date' in filters) delete filters.date;
    const filtersString = generalMixin.methods.objectToQuery(filters);
    return axios({url: `v2/action-log${filtersString}`, method: 'get'});
}

export function getLastNotifications(data) {
    const filtersString = generalMixin.methods.objectToQuery(data);
    return axios({url: `v2/action-log/subscription-list${filtersString}`, method: 'get'});
}

export function getTypes() {
    return axios({url: 'v2/action-log/level-list', method: 'get'});
}

export function markAsRead(notificationId) {
    return axios({url: `v2/action-log/${notificationId}`, method: 'head'});
}