import store from '@/store';
import {
  changeStatus,
  createTicket,
  getCategories, getCount,
  getProblems,
  getStatuses,
  getTableData,
  getTicket, sendMessage
} from '@/api/tickets';

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableMeta: {total: 0},
    ticket: {},
    count: 0,
    statuses: [],
    categories: [],
    problems: [],
  },
  getters: {
    TABLE_DATA(state) {
      return state.tableData;
    },
    TABLE_META(state) {
      return state.tableMeta;
    },
    TICKET(state) {
      return state.ticket;
    },
    COUNT(state) {
      return state.count;
    },
    STATUSES(state) {
      return state.statuses;
    },
    CATEGORIES(state) {
      return state.categories;
    },
    PROBLEMS(state) {
      return state.problems;
    }
  },
  mutations: {
    SET_TABLE_DATA(state, data) {
      state.tableData = data;
    },
    SET_TABLE_META(state, data) {
      state.tableMeta = data;
    },
    SET_TICKET(state, data) {
      state.ticket = data;
    },
    SET_COUNT(state, data) {
      state.count = data;
    },
    SET_STATUSES(state, data) {
      state.statuses = data.map(el => {
        return {value: el.key, label: el.title};
      });
    },
    SET_CATEGORIES(state, data) {
      state.categories = data.map(el => {
        return {value: el.id, label: el.title};
      });
    },
    SET_PROBLEMS(state, data) {
      state.problems = data.map(el => {
        return {value: el.id, label: el.title};
      });
    },
    CLEAR_TICKET(state) {
      state.ticket = {};
    }
  },
  actions: {
    GET_TABLE_DATA(context, filters) {
      return new Promise((resolve, reject) => {
        getTableData(filters).then(response => {
          context.commit('SET_TABLE_DATA', response.data);
          context.commit('SET_TABLE_META', response.meta);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_TICKET(context, id) {
      return new Promise((resolve, reject) => {
        getTicket(id).then(response => {
          context.commit('SET_TICKET', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_COUNT(context) {
      return new Promise((resolve, reject) => {
        getCount().then(response => {
          context.commit('SET_COUNT', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_STATUSES(context) {
      return new Promise((resolve, reject) => {
        getStatuses().then(response => {
          context.commit('SET_STATUSES', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_CATEGORIES(context) {
      return new Promise((resolve, reject) => {
        getCategories().then(response => {
          context.commit('SET_CATEGORIES', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_PROBLEMS(context) {
      return new Promise((resolve, reject) => {
        getProblems().then(response => {
          context.commit('SET_PROBLEMS', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    CREATE_TICKET(context, data) {
      return new Promise((resolve, reject) => {
        createTicket(data).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    SEND_MESSAGE(context, {ticketId, data}) {
      return new Promise((resolve, reject) => {
        sendMessage(ticketId, data).then(response => {
          context.commit('SET_TICKET', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    CHANGE_STATUS(context, {id, status}) {
      return new Promise((resolve, reject) => {
        changeStatus(id, status).then(response => {
          store.dispatch('ticketsStore/GET_COUNT');
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
  },
}