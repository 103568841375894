import Vue from 'vue'
import axios from 'axios'
import router from '@/router';
import store from '@/store';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

const axiosAgent = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000
})

const err = (error) => {
  if (error.response) {
    // const token = Vue.ls.get('Authorization')
    if (error.response.status === 401) {
      store.dispatch('Logout');
    }
    if (error.response.status === 404) {
      if (error.response.config.headers['Authorization']) {
        router.push({name: '404'});
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
axiosAgent.interceptors.request.use(config => {
  const token = Vue.ls.get('Authorization');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  config.headers['X-Language'] = `${localStorage.language ? localStorage.language : 'en'}`;
  return config;
}, err)

// response interceptor
axiosAgent.interceptors.response.use((response) => {
  return response.data
}, err)


export {
  axiosAgent as axios
}
