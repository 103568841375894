<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_196_4104)">
      <path d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 3V7" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 3V7" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5 11H27" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_196_4104">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>