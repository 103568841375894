<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_151_1969)">
      <path d="M9 21H15" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.26953 10.5C5.26706 9.61443 5.43934 8.73709 5.77652 7.91823C6.11369 7.09937 6.60912 6.3551 7.23443 5.72804C7.85974 5.10098 8.60263 4.60347 9.42054 4.26401C10.2385 3.92455 11.1153 3.74982 12.0009 3.74982C12.8864 3.74982 13.7633 3.92455 14.5812 4.26401C15.3991 4.60347 16.142 5.10098 16.7673 5.72804C17.3926 6.3551 17.888 7.09937 18.2252 7.91823C18.5624 8.73709 18.7347 9.61443 18.7322 10.5V10.5C18.7322 13.8577 19.4347 15.8061 20.0534 16.8711C20.1201 16.9848 20.1555 17.1142 20.1562 17.2461C20.157 17.3779 20.1229 17.5077 20.0574 17.6222C19.992 17.7367 19.8975 17.8319 19.7836 17.8982C19.6696 17.9645 19.5401 17.9996 19.4083 18H4.59271C4.46082 17.9996 4.33136 17.9645 4.21738 17.8982C4.10339 17.8318 4.00892 17.7366 3.9435 17.6221C3.87808 17.5076 3.84401 17.3778 3.84474 17.2459C3.84547 17.114 3.88096 16.9847 3.94765 16.8709C4.56671 15.8059 5.26952 13.8575 5.26952 10.5H5.26953Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.1963 2.2489C18.7224 3.21233 19.9526 4.57872 20.7511 6.19724" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.24805 6.19724C4.04657 4.57872 5.27679 3.21233 6.80291 2.2489" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_151_1969">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>