<template>
  <div class="menu-wrapper">
    <router-link :to="{name:'Dashboard'}" class="menu-logo">
      <img src="@/assets/text-logo.png" alt="">
    </router-link>
    <div class="menu-items-wrapper">
      <router-link
          v-for="menuItem in menuItems"
          :key="menuItem.name"
          :to="`/${menuItem.name}`"
          :disabled="menuItem.disabled"
      >
        <div
            v-if="menuItem[mainRoleName]"
            class="menu-item"
            :class="{'active': activeRouteName === menuItem.name, 'disabled': menuItem.disabled}"
        >
          <component :is="menuItem.icon" :stroke="activeRouteName === menuItem.name ? '#467BFF' : '#6886A6'"/>
          <div class="d-flex flex-column geomanist-font">
            <span class="menu-item-name">
              <span>{{ $t(`navigation.${menuItem.name}`) }}</span>
              <a-badge v-if="counter[menuItem.counter]" class="app-menu-badge" :count="counter[menuItem.counter]"/>
            </span>
            <span v-if="menuItem.disabled" class="coming-soon-label">{{ $t('label.coming_soon') }}</span>
          </div>
        </div>
      </router-link>
      <div class="logout-wrapper d-flex aic cursor-pointer" @click="logout">
        <logout-icon/>
        <span class="text-grey fs-18 geomanist-font">{{ $t('btn.logout') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BasesIcon from '@/assets/icons/basesIcon';
import CheckSquareOffsetIcon from '@/assets/icons/checkSquareOffsetIcon';
import ClockIcon from '@/assets/icons/clockIcon';
import ContactIcon from '@/assets/icons/contactIcon';
import DashboardIcon from '@/assets/icons/dashboardIcon';
import LogoutIcon from '@/assets/icons/logoutIcon';
import NotificationsIcon from '@/assets/icons/notificationsIcon';
import SettingsIcon from '@/assets/icons/settingsIcon';
import UsersIcon from '@/assets/icons/usersIcon';

export default {
  components: {
    BasesIcon,
    CheckSquareOffsetIcon,
    ClockIcon,
    ContactIcon,
    DashboardIcon,
    LogoutIcon,
    NotificationsIcon,
    SettingsIcon,
    UsersIcon,
  },
  data: () => ({
    counter: {},
    menuItems: [
      {name: 'dashboard', icon: 'DashboardIcon', counter: null, disabled: false, admin: true, superadmin: true},
      {name: 'bases', icon: 'BasesIcon', counter: null, disabled: false, admin: true, superadmin: false},
      {name: 'notifications', icon: 'NotificationsIcon', counter: null, disabled: false, admin: true, superadmin: true},
      {name: 'users', icon: 'UsersIcon', counter: null, disabled: false, admin: true, superadmin: true},
      {name: 'history', icon: 'ClockIcon', disabled: false, admin: false, superadmin: true},
      {name: 'tickets', icon: 'CheckSquareOffsetIcon', counter: 'ticketsCount', disabled: false, admin: false, superadmin: true},
      {name: 'settings', icon: 'SettingsIcon', counter: null, disabled: false, admin: true, superadmin: false},
      {name: 'contact', icon: 'ContactIcon', counter: null, disabled: false, admin: true, superadmin: false},
    ],
  }),
  beforeMount() {
    this.menuItems.filter(el => el.counter).map(el => this.$set(this.counter, el.counter, 0));
  },
  computed: {
    ...mapGetters({
      profile: 'me',
      mainRoleName: 'getMainRoleName',
    }),
    ticketsCount() {
      return this.$store.getters['ticketsStore/COUNT'];
    },
    activeRouteName() {
      return this.$route.name.toLowerCase();
    }
  },
  watch: {
    ticketsCount(value) {
      this.$set(this.counter, 'ticketsCount', value);
    },
    mainRoleName: {
      immediate: true,
      handler(value) {
        if (value === 'superadmin') {
          this.$echo.private(`user.${this.profile.id}`)
          .listen('.ticket_created', () => {
            this.$store.commit('socketsStore/SET_TICKET_CREATED', true);
            this.$store.dispatch('ticketsStore/GET_COUNT');
          });
        }
      }
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('Logout')
      .then(() => setTimeout(() => window.location.reload(), 16))
      .catch(err => console.log(err));
    }
  },
  beforeDestroy() {
    if (this.mainRoleName === 'superadmin') {
      this.$echo.leave(`user.${this.profile.id}`);
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-wrapper {
  height: 100%;
}

.menu-logo {
  height: 130px;
  padding: 40px 0 0 40px;
  display: block;
  border-bottom: 2px solid #F4F4F4;

  img {
    height: 50px;
    width: 143px;
  }
}

.menu-items-wrapper {
  margin-top: 40px;
  height: calc(100% - 170px);
  display: flex;
  flex-direction: column;

  .menu-item {
    margin: 0 24px;
    padding: 22px 20px;
    display: flex;
    color: #6886A6;

    &.active {
      background: #F6F8FC;
      color: #467BFF;
    }

    &.disabled {
      opacity: 0.7;
    }

    &:hover {
      background: #F6F8FC;
    }

    .menu-item-name {
      margin-left: 15px;
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .coming-soon-label {
      margin-left: 15px;
      text-transform: lowercase;
    }
  }
}

.logout-wrapper {
  margin-top: auto;
  padding: 40px;

  span {
    margin-left: 16px;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .menu-logo {
    height: 75px;
    padding: 20px 0 0 40px;

    img {
      height: 30px;
      width: 90px;
    }
  }
}
</style>