export default {
  methods: {
    objectToQuery(obj) {
      return obj
        ? '?' + Object.keys(obj).filter(k => obj[k]).map(k => k + '=' + encodeURIComponent(obj[k])).join('&')
        : '';
    },
    dateToQuery(date) {
      if (date && date.length) {
        let from = this.getDateStringFromObject(date[0].toDate());
        let to = this.getDateStringFromObject(date[1].toDate());

        return `&from=${from}&to=${to}`;
      }

      return '';
    },
    getDateStringFromObject(date) {
      return date.getFullYear() + '-' + this.addNullFirst(date.getMonth() + 1) + '-' + this.addNullFirst(date.getDate())
    },
    timestampToDate(timestamp, withTime = false, delimiter = '.') {
      const date = new Date(parseInt(timestamp));
      const day = this.addNullFirst(date.getDate());
      const month = this.addNullFirst(date.getMonth()+1);
      const year = date.getFullYear();

      let result = day + delimiter + month + delimiter + year;

      if (withTime) {
        const hours = this.addNullFirst(date.getHours());
        const minutes = this.addNullFirst(date.getMinutes());

        result = result + ' ' + hours + ':' + minutes;
      }

      return result;
    },
    addNullFirst(string) {
      return string < 10 ? '0' + string : string;
    }
  }
}