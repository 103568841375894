<template>
  <div>
    <!--Admin notifications-->
    <div v-if="adminFormSettings" class="mb-40px">
      <a-row :gutter="32" class="d-flex mb-24px">
        <a-col :span="6">
          <div class="subtitle">{{ $t('label.admin_notifications') }}</div>
        </a-col>
        <a-col :span="6" class="text-center">
          <div class="subtitle">{{ $t('label.web_notifications') }}</div>
        </a-col>
        <a-col :span="6" class="text-center">
          <div class="subtitle">{{ $t('label.email_notifications') }}</div>
        </a-col>
        <a-col :span="6" class="text-center d-flex jcc">
          <div class="subtitle">{{ $t('label.time') }}</div>

          <a-tooltip placement="top">
            <template slot="title">
              <span>Here is some description</span>
            </template>
            <span style="margin-left: 5px" class="cursor-pointer">&#128712;</span>
          </a-tooltip>
        </a-col>
      </a-row>

      <a-row
        v-for="(item, idx) in adminFormSettings"
        :key="idx"
        :gutter="32"
        class="d-flex"
        :class="{'mb-24px': idx !== adminFormSettings.length - 1}"
      >
        <a-col class="gutter-row" :span="6">
          <form-switcher
            :label="settings.find(el => el.id === item.id).label"
            :value="item.status"
            @change="item.status = $event"
          />
        </a-col>
        <a-col class="gutter-row d-flex jcc" :span="6">
          <form-switcher
            :value="item.status ? item.web : false"
            :disabled="!item.status"
            @change="item.web = $event"
          />
        </a-col>
        <a-col class="gutter-row d-flex jcc" :span="6">
          <form-switcher
            :value="item.status ? item.email : false"
            :disabled="!item.status"
            @change="item.email = $event"
          />
        </a-col>
        <a-col v-if="settings.find(el => el.id === item.id).time_list.length" class="gutter-row d-flex jcc" :span="6">
          <form-select
            class="w-100"
            :value="item.status ? item.time || undefined : undefined"
            :placeholder="$t('label.not_selected')"
            :options="settings.find(el => el.id === item.id).time_list"
            :disabled="!item.status"
            :error="error[item.id]"
            @filled="error[item.id] = null"
            @change="item.time = $event"
          />
        </a-col>
      </a-row>
    </div>

    <!--User notifications-->
    <div class="mb-40px">
      <a-row :gutter="32" class="d-flex mb-24px">
        <a-col :span="6">
          <div class="subtitle">{{ $t('label.user_notifications') }}</div>
        </a-col>
        <a-col :span="6" class="text-center">
          <div class="subtitle">{{ $t('label.mobile_app_notifications') }}</div>
        </a-col>
        <a-col :span="6" class="text-center">
          <div class="subtitle">{{ $t('label.email_notifications') }}</div>
        </a-col>
        <a-col :span="6" class="text-center d-flex jcc">
          <div class="subtitle">{{ $t('label.time') }}</div>

          <a-tooltip placement="top">
            <template slot="title">
              <span>Here is some description</span>
            </template>
            <span style="margin-left: 5px" class="cursor-pointer">&#128712;</span>
          </a-tooltip>
        </a-col>
      </a-row>

      <a-row
        v-for="(item, idx) in userFormSettings"
        :key="idx"
        :gutter="32"
        class="d-flex"
        :class="{'mb-24px': idx !== userFormSettings.length - 1}"
      >
        <a-col class="gutter-row" :span="6">
          <form-switcher
            :label="settings.find(el => el.id === item.id).label"
            :value="item.status"
            @change="item.status = $event"
          />
        </a-col>
        <a-col class="gutter-row d-flex jcc" :span="6">
          <form-switcher
            :value="item.status ? item.app : false"
            :disabled="!item.status"
            @change="item.app = $event"
          />
        </a-col>
        <a-col class="gutter-row d-flex jcc" :span="6">
          <form-switcher
            :value="item.status ? item.email : false"
            :disabled="!item.status"
            @change="item.email = $event"
          />
        </a-col>
        <a-col v-if="settings.find(el => el.id === item.id).time_list.length" class="gutter-row d-flex jcc" :span="6">
          <form-select
            class="w-100"
            :value="item.status ? item.time || undefined : undefined"
            :placeholder="$t('label.not_selected')"
            :options="settings.find(el => el.id === item.id).time_list"
            :disabled="!item.status"
            :error="error[item.id]"
            @filled="error[item.id] = null"
            @change="item.time = $event"
          />
        </a-col>
      </a-row>
    </div>

    <a-button class="app-button primary" @click="submit">{{ $t('btn.save_notifications_settings') }}</a-button>
  </div>
</template>

<script>
import FormSelect from '@/components/elements/FormSelect';
import FormSwitcher from '@/components/elements/FormSwitcher';
import {mapGetters} from "vuex";

export default {
  components: {
    FormSelect,
    FormSwitcher
  },
  props: {
    organizationId: {type: Number, default: null}
  },
  data: () => ({
    form: [],
    error: {}
  }),
  beforeMount() {
    this.$store.dispatch('settingsStore/GET_NOTIFICATION_SETTINGS', this.organizationId);
  },
  computed: {
    ...mapGetters({
      settings: 'settingsStore/NOTIFICATION_SETTINGS'
    }),
    adminFormSettings() {
      const adminIds = this.settings.length ? this.settings.filter(el => el.role === 'admin').map(el => el.id) : [];
      return this.form.filter(el => adminIds.includes(el.id));
    },
    userFormSettings() {
      const userIds = this.settings.length ? this.settings.filter(el => el.role === 'user').map(el => el.id) : [];
      return this.form.filter(el => userIds.includes(el.id));
    }
  },
  watch: {
    settings: {
      handler(data) {
        if (data) {
          data.map((el, idx) => {
            this.$set(this.error, el.id, null);
            let params = {id: el.id, status: el.status, web: el.web, email: el.email, time: el.time};
            if (el.role === 'admin') {
              params = {...params, web: el.web};
            } else {
              params = {...params, app: el.app};
            }
            this.$set(this.form, idx, params);
          });
        }
      },
      deep: true
    }
  },
  methods: {
    validateForm() {
      this.form.forEach(el => {
        let s = this.settings.find(e => e.id === el.id);
        this.$set(this.error, el.id, el.status && s.time_list.length && !el.time ? this.$t('error.required') : null);
      });

      return !Object.values(this.error).some(v => v);
    },
    submit() {
      if (!this.validateForm()) return false;

      this.$store.dispatch('settingsStore/UPDATE_NOTIFICATION_SETTINGS', {
        organizationId: this.organizationId, data: {settings: this.form}
      })
      .then(() => {
        this.$message.success(this.$t('alert.information_updated'));
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  color: #132540;
  font-size: 16px;
  font-weight: 500;
}

.text-transparent::v-deep {
  .select-label {
    color: transparent;
  }
}

.app-switcher::v-deep {
  line-height: 48px;
}
</style>