import { axios } from '@/utils/request';
import generalMixin from '@/mixins/general';

export function getTableData(filters) {
    if ('date' in filters) delete filters.date;
    const filtersString = generalMixin.methods.objectToQuery(filters);
    return axios({url: `v2/tickets${filtersString}`, method: 'get'});
}

export function getTicket(id) {
    return axios({url: `v2/tickets/${id}`, method: 'get'});
}

export function getCount() {
    return axios({url: 'v2/tickets/count', method: 'get'});
}

export function getStatuses() {
    return axios({url: `v2/tickets/list/statuses`, method: 'get'});
}

export function getCategories() {
    return axios({url: `v2/tickets/list/category`, method: 'get'});
}

export function getProblems() {
    return axios({url: `v2/tickets/list/param-type/problem_id`, method: 'get'});
}

export function createTicket(data) {
    return axios({url: `v2/tickets`, method: 'post', data: data});
}

export function sendMessage(id, data) {
    return axios({url: `v2/tickets/${id}/message`, method: 'post', data: data});
}

export function changeStatus(id, status) {
    return axios({url: `v2/tickets/${id}`, method: 'patch', data: {status: status}});
}