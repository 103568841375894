import {axios} from '@/utils/request';
import generalMixin from '@/mixins/general';

export function getBoxData(organizationId) {
  const query = organizationId ? `?organization_id=${organizationId}` : '';
  return axios({url: `v2/dashboard/last-import-users${query}`, method: 'get'});
}

export function getUsersList(data) {
  const filtersString = generalMixin.methods.objectToQuery(data);
  return axios({url: `v2/users/list${filtersString}`, method: 'get'});
}

export function getUsers(data) {
  const filtersString = generalMixin.methods.objectToQuery(data);
  return axios({url: `v2/users${filtersString}`, method: 'get'});
}

export function getAdmins(filters) {
  const filtersString = generalMixin.methods.objectToQuery(filters);
  return axios({url: `v2/users${filtersString}&admin`, method: 'get'});
}

export function getUser(slug) {
  return axios({url: `v2/users/${slug}`, method: 'get'});
}

export function createUser(data) {
  return axios({url: `v2/users`, method: 'post', data: data});
}

export function updateUser(slug, data) {
  return axios({url: `v2/users/${slug}`, method: 'put', data: data});
}

export function activateUser(slug) {
  return axios({url: `v2/users/${slug}`, method: 'patch', data: {is_active: true}});
}

export function destroyUser(slug) {
  return axios({url: `v2/users/${slug}`, method: 'patch', data: {is_active: false}});
}

export function setUserAsAdmin(data) {
  return axios({url: 'v2/users/set-admin', method: 'post', data: data});
}

export function importUsers(data) {
  return axios({url: `v2/users/import`, method: 'post', data: data});
}