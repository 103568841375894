import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueStorage from 'vue-ls';
import i18n from '@/core/vue-i18n';

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/style.css';

// Maps integration, icons issues resolve
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const storageOptions =  {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
}

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueStorage, storageOptions);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
