<template>
  <div>
    <a-row :gutter="32" class="filters-wrapper">
      <a-col :xs="24" :md="12" class="gutter-row">
        <form-input
          class="mb-32px"
          :value="filters.search"
          :placeholder="$t('label.search')"
          allow-clear
          with-icon
          @input="filters.search = $event"
        >
          <template #prefix-icon>
            <search-icon stroke="#467BFF"/>
          </template>
        </form-input>
      </a-col>
      <a-col :xs="24" :md="12" class="gutter-row mb-24px">
        <form-select
          :value="filters.level"
          :options="typesList"
          :placeholder="$t('label.type_of_notification')"
          multiple
          allow-clear
          with-icon
          @change="filters.level = $event"
        >
          <template #prefix-icon>
            <notification-icon stroke="#467BFF"/>
          </template>
        </form-select>
      </a-col>
    </a-row>

    <a-row :gutter="32" class="filters-wrapper">
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-select
            :value="filters.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            allow-clear
            with-icon
            @change="filters.organization_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-select
            :value="showOnlyNewNotifications"
            :options="yesNoList"
            :placeholder="$t('label.unread_only')"
            allow-clear
            with-icon
            @change="showOnlyNewNotifications = $event"
        >
          <template #prefix-icon>
            <four-circles-icon height="24" width="24" stroke="#467BFF" view-box="0 0 32 32"/>
          </template>
        </form-select>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-date-range-picker
            :value="filters.date"
            :placeholder="[$t('placeholder.from'), $t('placeholder.to')]"
            allow-clear
            with-icon
            @change="filters.date = $event"
        >
          <template #prefix-icon>
            <calendar-icon stroke="#467BFF" view-box="0 0 32 24"/>
          </template>
        </form-date-range-picker>
      </a-col>
    </a-row>

    <app-table
        :columns="tableColumns"
        :data="list"
        :current="computedCurrentPage"
        :total="computedTotalItems"
        scroll
        @getData="getTableData"
    >
      <span slot="store_at_icon">
        <calendar-icon height="16" width="16" stroke="#467BFF" view-box="0 0 32 32"/>
        <span>{{ $t('label.date') }}</span>
      </span>
      <span slot="event_level_label_icon">
        <question-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
        <span>{{ $t('label.urgency') }}</span>
      </span>
      <span slot="organization_name_icon">
        <list-icon height="16" width="16"/>
        <span>{{ $t('label.organization') }}</span>
      </span>
      <span slot="message_icon">
        <circle-wavy-warning-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
        <span>{{ $t('label.information') }}</span>
      </span>

      <template v-slot:store_at="{ text, record }">
        <span :class="{'fw-400': !record.subscription_status}">{{ timestampToDate(text, true) }}</span>
      </template>
      <template v-slot:event_level_label="{ text, record }">
        <span :class="{'fw-400': !record.subscription_status}">{{ text || '-' }}</span>
      </template>
      <template v-slot:organization_name="{ text, record }">
        <span :class="{'fw-400': !record.subscription_status}">{{ text || '-' }}</span>
      </template>
      <template v-slot:message="{ text, record }">
        <div class="d-flex aic">
          <shield-warning-icon height="16" width="16" stroke="#FF655B" view-box="0 0 16 16"/>
          <span class="ml-12px" :class="{'fw-400': !record.subscription_status}">{{ text }}</span>
        </div>
      </template>
      <template v-slot:actions="{ record }">
        <div class="d-flex table-actions-wrapper">
          <a-tooltip v-if="record.subscription_status">
            <template v-slot:title>
              {{ $t('btn.mark_as_read') }}
            </template>
            <span class="cursor-pointer" style="line-height: 16px;" @click="markAsRead(record)">
              <mail-open-icon height="18" width="18" stroke="#467BFF" view-box="0 0 24 24"/>
            </span>
          </a-tooltip>
        </div>
      </template>
    </app-table>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import {settings} from '@/core/defaultSettings';
import generalMixin from '@/mixins/general';
import AppTable from '@/components/elements/AppTable';
import FormDateRangePicker from '@/components/elements/FormDateRangePicker';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';
import CalendarIcon from '@/assets/icons/calendarIcon';
import CircleWavyWarningIcon from '@/assets/icons/circleWavyWarningIcon';
import FourCirclesIcon from '@/assets/icons/fourCirclesIcon';
import ListIcon from '@/assets/icons/listIcon';
import MailOpenIcon from '@/assets/icons/mailOpenIcon';
import NotificationIcon from '@/assets/icons/notificationIcon';
import QuestionIcon from '@/assets/icons/questionIcon';
import SearchIcon from '@/assets/icons/searchIcon';
import ShieldWarningIcon from '@/assets/icons/shieldWarningIcon';

export default {
  components: {
    AppTable,
    FormDateRangePicker,
    FormInput,
    FormSelect,
    CalendarIcon,
    CircleWavyWarningIcon,
    FourCirclesIcon,
    ListIcon,
    MailOpenIcon,
    NotificationIcon,
    QuestionIcon,
    SearchIcon,
    ShieldWarningIcon,
  },
  mixins: [generalMixin],
  data: () => ({
    showOnlyNewNotifications: null,
    pagination: {page: 1},
    filters: {
      date: [],
      level: [],
      organization_id: null,
      search: null
    }
  }),
  beforeMount() {
    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
    this.$store.dispatch('notificationsStore/GET_TYPES');

    if (this.$route.query?.organization_id) {
      this.filters.organization_id = parseInt(this.$route.query.organization_id);
    }
    if ('urgent' in this.$route.query) {
      this.filters.level = 100;
    }
    if ('new_event' in this.$route.query) {
      this.showOnlyNewNotifications = 1;
    }
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      list: 'notificationsStore/TABLE_DATA',
      listMeta: 'notificationsStore/TABLE_META',
      typesList: 'notificationsStore/TYPES',
      notificationReceivedState: 'socketsStore/NOTIFICATION_RECEIVED',
      markedAsRead: 'notificationsStore/MARKED_AS_READ'
    }),
    tableColumns() {
      return [
        {
          dataIndex: 'store_at',
          slots: {title: 'store_at_icon'},
          scopedSlots: {customRender: 'store_at'}
        },
        {
          dataIndex: 'event_level_label',
          slots: {title: 'event_level_label_icon'},
          scopedSlots: {customRender: 'event_level_label'}
        },
        {
          dataIndex: 'organization_name',
          slots: {title: 'organization_name_icon'},
          scopedSlots: {customRender: 'organization_name'}
        },
        {
          dataIndex: 'message',
          slots: {title: 'message_icon'},
          scopedSlots: {customRender: 'message'}
        },
        {
          title: '',
          dataIndex: 'actions',
          scopedSlots: {customRender: 'actions'}
        }
      ];
    },
    yesNoList() {
      return [{value: -1, label: this.$t('btn.no')}, {value: 1, label: this.$t('btn.yes')}]
    },
    computedCurrentPage() {
      return this.listMeta?.current_page || 1;
    },
    computedTotalItems() {
      return this.listMeta?.total || this.list.length;
    }
  },
  watch: {
    showOnlyNewNotifications(value) {
      if (value > 0) {
        this.$set(this.filters, 'new_event', true);
      } else {
        this.$delete(this.filters, 'new_event');
      }
    },
    markedAsRead(id) {
      if (id) this.getTableData(this.pagination);
    },
    filters: {
      immediate: true,
      handler: _.debounce(function () {
        this.getTableData();
      }, settings.debounceTime),
      deep: true
    },
    notificationReceivedState(value) {
      if (value) this.getTableData(this.pagination);
    },
    typesList(data) {
      data.map(el => {
        if (el.selected) {
          this.filters.level.push(el.id);
        }
      })
    }
  },
  methods: {
    getTableData(pagination) {
      let date = this.filters.date;
      if (date && date.length) {
        date = {from: this.getDateStringFromObject(date[0].toDate()), to: this.getDateStringFromObject(date[1].toDate())};
      }
      this.$store.dispatch('notificationsStore/GET_TABLE_DATA', {...this.filters, ...date, ...pagination});
      this.pagination = pagination;
    },
    markAsRead(notification) {
      this.$store.dispatch('notificationsStore/MARK_AS_READ', notification.id);
    }
  }
}
</script>