<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.39994 1.6001C9.88631 1.59985 11.3264 2.11697 12.4731 3.0627C13.6198 4.00843 14.4015 5.32379 14.6842 6.78304C14.9669 8.24228 14.7328 9.75441 14.0222 11.0599C13.3115 12.3654 12.1687 13.3828 10.7897 13.9376C9.41076 14.4924 7.8817 14.5499 6.46497 14.1002C5.04824 13.6506 3.83218 12.7219 3.02551 11.4735C2.21884 10.225 1.87186 8.73476 2.04411 7.2584C2.21637 5.78205 2.89712 4.41168 3.96954 3.3825" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.19995 4.00017L3.96955 3.38257L4.58875 5.85777" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.3999 5.6001V8.0001L9.9999 9.6001" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>