<template>
  <div>
    <app-breadcrumb v-if="base" :items="breadcrumbs" class="mb-24px"/>
    <app-tabs
      v-if="base"
      :tabs="tabs"
      :disabled-tabs="['last_update']"
      :suffix-info="base.api_updated_at ? {last_update: `: ${base.api_updated_at}`} : {}"
      @change="onTabChange"
    >
      <template #general>
        <div v-if="base" class="base-details-form-wrapper mt-32px">
          <a-row :gutter="32" class="mb-40px">
            <a-col class="gutter-row" :span="8">
              <form-input
                  class="mb-32px"
                  :label="$t('label.base_id')"
                  :placeholder="$t('label.base_id')"
                  :value="form.base_id"
                  disabled
                  :error="error.base_id"
                  @input="form.base_id = $event"
              />
              <form-textarea
                  class="mb-32px"
                  :label="$t('label.description')"
                  :placeholder="$t('label.description')"
                  :value="form.description"
                  @input="form.description = $event"
              />
              <form-input
                  class="mb-32px"
                  :label="$t('label.short_alias')"
                  :placeholder="$t('label.short_alias')"
                  :value="form.short_alias"
                  disabled
                  @input="form.short_alias = $event"
              />
              <form-input
                  class="mb-32px"
                  :label="$t('label.long_alias')"
                  :placeholder="$t('label.long_alias')"
                  :value="form.long_alias"
                  @input="form.long_alias = $event"
              />
              <form-switcher
                  class="mb-32px"
                  :label="$t(`label.status_${!form.is_active ? 'in' : ''}active`)"
                  :value="form.is_active"
                  @change="form.is_active = $event"
              />
              <form-switcher
                  :label="$t('label.maintenance_mode')"
                  :value="form.maintenance_mode"
                  @change="form.maintenance_mode = $event"
              />
            </a-col>
            <a-col class="gutter-row" :span="8">
              <form-select
                  class="mb-32px"
                  :label="$t('label.linked_organization')"
                  :placeholder="$t('label.organization')"
                  :options="organizationsList"
                  :value="baseOrganization.id"
                  disabled
                  @change="form.organization_id = $event"
              />
              <form-input
                  class="mb-32px"
                  :label="$t('label.location_of_installation')"
                  :placeholder="$t('label.location_of_installation')"
                  :value="baseOrganization.location"
                  disabled
              />
              <form-input
                  class="mb-32px"
                  :label="$t('label.managers_full_name')"
                  :placeholder="$t('label.managers_full_name')"
                  :value="baseOrganization.manager"
                  disabled
              />
              <form-input
                  class="mb-32px"
                  :label="$t('label.email')"
                  :placeholder="$t('label.email')"
                  :value="baseOrganization.email"
                  disabled
              />
              <form-input
                  class="mb-32px"
                  :label="$t('label.phone_number')"
                  :placeholder="$t('label.phone_number')"
                  :value="baseOrganization.phone"
                  disabled
              />
              <div v-if="base" class="qr-code-wrapper">
                <img :src="base.qr_code_url" alt="">
              </div>
            </a-col>
          </a-row>
          <a-button class="app-button submit-base-btn primary" @click="submit">{{ $t('btn.save_base_info') }}</a-button>
        </div>
      </template>
      <template #advanced_details>
        <app-table :columns="advancedDetailsColumns" :data="advancedDetails" scroll>
          <template #positions="{index}">{{ `${$t('label.position')} ${index + 1}` }}</template>
          <template #pico_id="{text}">{{ text || '-' }}</template>
          <template #alias="{text}">{{ text || '-' }}</template>
          <template #accelero_max="{text}">{{ text || '-' }}</template>
          <template #soh="{text}">{{ text || '-' }}</template>
          <template #soc="{text}">{{ text || '-' }}</template>
          <template #power_in="{text}">{{ text || '-' }}</template>
          <template #len_log="{text}">{{ text || '-' }}</template>
          <template #available="{text}">{{ text || '-' }}</template>
          <template #issues="{text}">{{ text || '-' }}</template>
          <template #release="{record, index}">
            <a-button v-if="record.available_pico" class="app-button sm outline-primary" @click="unlockSlot(index+1)">
              {{ $t('btn.unlock') }}
            </a-button>
          </template>

          <template #footer-actions>
            <a-button class="app-button outline-primary mr-16px" @click="unlockAllPicos">
              {{ $t('btn.unlock_all_picos') }}
            </a-button>
            <a-button class="app-button outline-primary mr-16px" @click="reboot">{{ $t('btn.reboot') }}</a-button>
            <a-button class="app-button primary" @click="advancedUpdate">{{ $t('btn.update') }}</a-button>
          </template>
        </app-table>
      </template>
      <template #date_of_last_update>
        <div v-if="base" class="base-details-form-wrapper mt-32px">
          <a-row :gutter="32" class="mb-40px">
            <a-col class="gutter-row" :span="8">{{ base.api_updated_at }}</a-col>
          </a-row>
        </div>
      </template>
    </app-tabs>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppBreadcrumb from '@/components/elements/AppBreadcrumb'
import AppTabs from '@/components/elements/AppTabs'
import AppTable from '@/components/elements/AppTable'
import FormInput from '@/components/elements/FormInput'
import FormSelect from '@/components/elements/FormSelect'
import FormSwitcher from '@/components/elements/FormSwitcher'
import FormTextarea from '@/components/elements/FormTextarea'

export default {
  components: {
    AppBreadcrumb,
    AppTabs,
    AppTable,
    FormInput,
    FormSelect,
    FormSwitcher,
    FormTextarea
  },
  data: () => ({
    currentBaseId: null,
    form: {
      base_id: null,
      description: null,
      long_alias: null,
      maintenance_mode: false,
      is_active: false,
      short_alias: null,
    },
    tableColumns: ['positions', 'pico_id', 'alias', 'accelero_max', 'soh', 'soc', 'power_in', 'len_log', 'available', 'issues', 'release'],
    error: {
      base_id: null,
    }
  }),
  beforeMount() {
    this.currentBaseId = this.$route.params.id;
    this.listenBaseUpdate();
  },
  mounted() {
    this.onTabChange(false);
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      base: 'basesStore/BASE',
      advancedDetails: 'basesStore/ADVANCED_DETAILS'
    }),
    breadcrumbs() {
      return [
        {name: this.$t('navigation.home'), route: 'Dashboard'},
        {name: this.$t('navigation.bases'), route: 'Bases'},
        {name: this.$t('label.base_details'), route: null},
      ];
    },
    tabs() {
      let tabs = ['general', 'advanced_details'];
      if (this.base.api_updated_at) {
        tabs.push('last_update');
      }

      return tabs;
    },
    baseOrganization() {
      return this.base?.organization || {};
    },
    advancedDetailsColumns() {
      return this.tableColumns.map(el => {
        let title = '';
        if (el === 'alias') {
          title = this.$t(`label.pico_alias`);
        } else if (el !== 'release') {
          title = this.$t(`label.${el}`);
        }

        return {title: title, dataIndex: el, scopedSlots: {customRender: el}};
      });
    }
  },
  watch: {
    base(data) {
      this.form.base_id = data.base_id;
      this.form.description = data.description;
      this.form.is_active = !!data.is_active;
      this.form.long_alias = data.long_alias;
      this.form.maintenance_mode = !!data.maintenance_mode;
      this.form.short_alias = data.short_alias;
    }
  },
  methods: {
    onTabChange(e) {
      if (e) {
        this.getAdvancedDetails();
      } else {
        this.$store.dispatch('basesStore/GET_BASE', this.currentBaseId);
        this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
      }
    },
    getAdvancedDetails() {
      this.$store.dispatch('basesStore/GET_ADVANCED_DETAILS', this.currentBaseId);
    },
    unlockSlot(slot) {
      this.$confirm({
        title: this.$t('modal.release_slot'),
        centered: true,
        closable: true,
        okType: 'primary',
        class: 'unlock-pico-modal',
        cancelText: this.$t('btn.no'),
        okText: this.$t('btn.yes'),
        onOk: () => {
          this.$store.dispatch('basesStore/RELEASE_SLOT', {baseId: this.currentBaseId, slot: slot})
          .then(() => {
            this.$message.success(this.$t('alert.slot_released'));
          })
          .catch(err => this.$message.error(err));
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    unlockAllPicos() {
      this.$store.dispatch('basesStore/UNLOCK_ALL_PICOS', this.currentBaseId)
      .then(() => {
        this.$message.success(this.$t('alert.all_picos_unlocked'));
      })
      .catch(error => {
        this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
      });
    },
    reboot() {
      this.$store.dispatch('basesStore/REBOOT', this.currentBaseId)
      .then(() => {
        this.$message.success(this.$t('alert.base_rebooted'));
      })
      .catch(error => {
        this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
      });
    },
    advancedUpdate() {
      this.$store.dispatch('basesStore/ADVANCED_UPDATE', this.currentBaseId)
      .then(() => {
        this.$message.success(this.$t('alert.update_request_sent'));
      })
      .catch(error => {
        this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
      });
    },
    listenBaseUpdate() {
      this.$echo.private(`base.${this.currentBaseId}`)
      .listen('.base_pico_list_updated', () => {
        this.getAdvancedDetails();
      });
    },
    validateForm() {
      this.error.base_id = !this.form.base_id ? this.$t('error.required') : null;

      return !Object.values(this.error).some(v => v);
    },
    submit() {
      if (!this.validateForm()) return false;
      this.$store.dispatch('basesStore/UPDATE_BASE', {id: this.currentBaseId, details: this.form})
      .then(() => {
        this.$message.success(this.$t('alert.base_updated'));
      })
      .catch(error => {
        this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
      });
    }
  },
  beforeDestroy() {
    this.$echo.leave(`base.${this.currentBaseId}`);
  }
}
</script>

<style lang="scss" scoped>
.card-container::v-deep {
  .ant-tabs {
    .ant-tabs-bar {
      margin-bottom: 0;
      border-bottom: 1.5px solid #F4F4F4;

      .ant-tabs-nav-container {
        height: auto;

        .ant-tabs-nav-wrap {
          .ant-tabs-tab {
            height: auto;
            margin-right: 10px;
            padding: 16px;
            border: 0;
            color: #6886A6;
            line-height: 16px;

            &.ant-tabs-tab-active {
              color: #467BFF;
            }

            &:not(.ant-tabs-tab-active) {
              background: transparent;
            }
          }
        }
      }
    }
  }

  .ant-pagination {
    visibility: hidden;
    opacity: 0;
  }

  .qr-code-wrapper {
    height: 150px;
    width: 150px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .submit-base-btn {
    padding: 11px 28px !important;
  }
}
</style>