import Vue from 'vue';
import {login, recoverPassword, resetPassword, getLoggedUser, changePassword, logout} from '@/api/auth';

const auth = {
  state: {
    token: null,
    emailSent: false,
    passwordReset: false,
    passwordExpired: false,
    userInfo: null,
    userOrganizationId: null,
    userOrganizationRoles: null,
    role: null,
    mainRoleName: null,
  },
  getters: {
    getRole: state => state.role,
    getMainRoleName: state => state.mainRoleName,
    getEmailSentState: state => state.emailSent,
    getResetPasswordState: state => state.passwordReset,
    getPasswordExpiredState: state => state.passwordExpired,
    getOrganizationId: state => state.userOrganizationId,
    getOrganizationRoles: state => state.userOrganizationRoles,
    me: state => state.userInfo
  },
  mutations: {
    SET_USER(state, user) {
      state.userInfo = user;
    },
    SET_ROLE(state, role) {
      state.role = role;
    },
    SET_MAIN_ROLE_NAME(state, name) {
      state.mainRoleName = name;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_EMAIL_SENT(state, value) {
      state.emailSent = value;
    },
    SET_RESET_PASSWORD(state, value) {
      state.passwordReset = value;
    },
    SET_USER_ORGANIZATION_ID(state, value) {
      state.userOrganizationId = value;
    },
    SET_USER_ORGANIZATIONS_ROLES(state, value) {
      state.userOrganizationRoles = value;
    },
    SET_PASSWORD_EXPIRED(state, value) {
      state.passwordExpired = value;
    }
  },
  actions: {
    Login(context, user) {
      return new Promise((resolve, reject) => {
        login(user).then(response => {
          const token = response.access_token;
          Vue.ls.set('Authorization', token);
          context.commit('SET_TOKEN', token);
          resolve();
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout() {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          Vue.ls.remove('Authorization');
          window.location = '/user/login';
          resolve()
        }).catch(error => {
          Vue.ls.remove('Authorization');
          window.location = '/user/login';
          reject(error);
        })
      })
    },
    GetLoggedUser: (context) => {
      return new Promise((resolve, reject) => {
        getLoggedUser().then(response => {
          const result = response.data;
          context.commit('SET_USER', result);
          context.commit('SET_ROLE', result.main_role.id);
          context.commit('SET_MAIN_ROLE_NAME', result.main_role.name);
          context.commit('SET_PASSWORD_EXPIRED', result.password_expired || false);
          context.commit('SET_USER_ORGANIZATION_ID', result.organization ? result.organization.id : null);
          context.commit('SET_USER_ORGANIZATIONS_ROLES', result.user_organization_role);
          resolve(response);
        }).catch(error => {
          console.log('error');
          reject(error);
        })
      })
    },
    RecoverPassword(context, data) {
      return new Promise((resolve, reject) => {
        recoverPassword(data).then(response => {
          // context.commit('SET_EMAIL_SENT', true);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    ReturnToLogin(context) {
      context.dispatch('Logout')
      context.commit('SET_EMAIL_SENT', false)
      context.commit('SET_RESET_PASSWORD', false)
    },
    ResetPassword(context, params) {
      return new Promise((resolve, reject) => {
        resetPassword(params).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    ChangePassword(context, params) {
      return new Promise((resolve, reject) => {
        changePassword(params).then(() => {
          resolve();
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
}

export default auth
