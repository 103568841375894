import {
    getImportTemplate,
    getOrganization,
    getOrganizationsDropdown,
    update,
    updateImportTemplate
} from '@/api/organizations';

export default {
    namespaced: true,
    state: {
        dropdownList: [],
        linkedDropdownList: [],
        organization: null,
        importTemplate: null,
    },
    getters: {
        DROPDOWN_LIST(state) {
            return state.dropdownList;
        },
        LINKED_DROPDOWN_LIST(state) {
            return state.linkedDropdownList;
        },
        ORGANIZATION(state) {
            return state.organization;
        },
        IMPORT_TEMPLATE(state) {
            return state.importTemplate;
        }
    },
    mutations: {
        SET_DROPDOWN_LIST(state, list) {
            state.dropdownList = list.map(el => {
                return {...el, value: el.id, label: el.name}
            });
        },
        SET_LINKED_DROPDOWN_LIST(state, list) {
            state.linkedDropdownList = list.map(el => {
                return {value: el.id, label: el.name}
            });
        },
        SET_ORGANIZATION(state, data) {
            state.organization = data;
        },
        SET_IMPORT_TEMPLATE(state, data) {
            state.importTemplate = data;
        }
    },
    actions: {
        GET_DROPDOWN_LIST(context, filters) {
            return new Promise((resolve, reject) => {
                getOrganizationsDropdown(filters, false).then(response => {
                    context.commit('SET_DROPDOWN_LIST', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_LINKED_DROPDOWN_LIST(context, filters) {
            let payload = {...filters, withLink: true};
            return new Promise((resolve, reject) => {
                getOrganizationsDropdown(payload, false).then(response => {
                    context.commit('SET_LINKED_DROPDOWN_LIST', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_ORGANIZATION(context, id) {
            return new Promise((resolve, reject) => {
                getOrganization(id).then(response => {
                    context.commit('SET_ORGANIZATION', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_IMPORT_TEMPLATE(context, id) {
            return new Promise((resolve, reject) => {
                getImportTemplate(id).then(response => {
                    context.commit('SET_IMPORT_TEMPLATE', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        UPDATE_IMPORT_TEMPLATE(context, {id, data}) {
            return new Promise((resolve, reject) => {
                updateImportTemplate(id, data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        UPDATE(context, {id, data}) {
            return new Promise((resolve, reject) => {
                update(id, data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
}