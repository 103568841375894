import {axios} from '@/utils/request';
import generalMixin from '@/mixins/general';

export function getBoxData(organizationId) {
    const query = organizationId ? `?organization_id=${organizationId}` : '';
    return axios({url: `v2/dashboard/bases-pico${query}`, method: 'get'})
}

export function getListData(filters) {
    const filtersString = generalMixin.methods.objectToQuery(filters);
    return axios({url: `v2/bases${filtersString}`, method: 'get'});
}

export function getBasesDropdown(filters) {
    const filtersString = generalMixin.methods.objectToQuery(filters);
    return axios({url: `v2/bases/list${filtersString}`, method: 'get'});
}

export function getBase(id) {
    return axios({url: `v2/bases/${id}`, method: 'get'});
}

export function getBaseAdvancedDetails(id) {
    return axios({url: `v2/bases/${id}/picos`, method: 'get'});
}

export function updateBase(id, data) {
    return axios({url: `v2/bases/${id}`, method: 'put', data: data});
}

export function advancedUpdate(id) {
    return axios({url: `v2/bases/${id}/advanced/update`, method: 'get'});
}

export function releaseSlot(baseId, slot) {
    return axios({url: `v2/bases/${baseId}/release/${slot}`, method: 'get'});
}

export function reboot(id) {
    return axios({url: `v2/bases/${id}/advanced/sync`, method: 'get'});
}

export function unlockAllPicos(id) {
    return axios({url: `v2/bases/${id}/picos/unlock`, method: 'put'});
}