<template>
  <div class="app-header d-flex jcb aic h-100">
    <div class="page-name geomanist-font">{{ $t(`navigation.${pageName}`) }}</div>
    <div class="right-header-corner d-flex aic">
      <notifications-popover/>
      <div v-if="userFullName" class="user-name fs-lg">{{ `${$t('label.hello')}, ${userFullName}` }}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NotificationsPopover from './NotificationsPopover';

export default {
  components: {
    NotificationsPopover
  },
  computed: {
    ...mapGetters({
      profile: 'me',
    }),
    pageName() {
      return this.$route.name.toLowerCase();
    },
    userFullName() {
      return this.profile ? `${this.profile.first_name} ${this.profile.last_name}` : null;
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler(data) {
        if (data) {
          this.$echo.private(`user.${this.profile.id}`)
          .listen('.notifications', response => {
            const notification = response.notification;
            this.$store.commit('socketsStore/SET_NOTIFICATION_RECEIVED', true);
            this.$store.commit('notificationsStore/ADD_NOTIFICATION', notification);
            this.openNotification(notification);
          });
        }
      },
      deep: true
    }
  },
  methods: {
    openNotification(notification) {
      const key = `open${Date.now()}`;
      const config = {
        message: notification.event_level_label,
        description: notification.message,
        duration: 10,
        btn: h => {
          return h('a-button',
            {
              props: {type: ' app-button primary sm', size: 'small'},
              on: {
                click: () => {
                  this.$store.dispatch('notificationsStore/MARK_AS_READ', notification.id);
                  this.$notification.close(key);
                },
              },
            },
            this.$t('btn.mark_as_read'),
          );
        },
        key,
        onClose: close,
      };

      if (notification.event_level === 100) {
        this.$notification.warning(config);
      } else {
        this.$notification.info(config);
      }
    }
  },
  beforeDestroy() {
    this.$echo.leave(`user.${this.profile.id}`);
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  .page-name {
    color: #132540;
    font-size: 21px;
    font-weight: 600;
    line-height: 21px;
  }

  .user-name {
    padding-left: 20px;
    color: #6886A6;
    line-height: 21px;
  }

  .right-header-corner {
    .notification-icon {
      height: 24px;
      line-height: 24px;

      &.unread {
        &::after {
          position: absolute;
          height: 4px;
          width: 4px;
          content: '';
          background: #FF655B;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>