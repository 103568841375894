<template>
  <div class="form-element-wrapper">
    <div v-if="label" class="select-label">
      {{ label }}
      <span v-if="required" class="required text-danger">*</span>
    </div>
    <div v-if="withIcon" class="form-element-icon">
      <slot name="prefix-icon"/>
    </div>
    <a-select
        class="app-select"
        :class="{'with-icon': withIcon, 'error': error}"
        :placeholder="placeholder"
        :options="options"
        :value="innerValue || undefined"
        :mode="mode"
        :show-search="showSearch"
        :allow-clear="allowClear"
        :show-arrow="showArrow"
        :disabled="disabled"
        :filter-option="false"
        @blur="$emit('blur')"
        @change="onChange"
        @focus="$emit('focus')"
        @search="$emit('search', $event)"
    >
      <div slot="notFoundContent" class="text-center">{{ $t('label.no_data') }}</div>
    </a-select>
    <div v-if="error" class="error-msg position-absolute text-danger fs-sm nowrap">{{ error }}</div>
  </div>
</template>
<script>
export default {
  props: {
    label: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    options: {type: Array, default: () => []},
    value: {default: ''},
    error: {type: String, default: null},
    activeFirstOption: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    showArrow: {type: Boolean, default: true},
    allowClear: {type: Boolean, default: false},
    showSearch: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    withIcon: {type: Boolean, default: false}
  },
  data: () => ({
    innerValue: null
  }),
  computed: {
    mode() {
      return this.multiple ? 'multiple' : 'default';
    },
    activeFirstOptionWithLoadedOptions() {
      return this.activeFirstOption && !!this.options.length;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.innerValue = v;
      }
    },
    activeFirstOptionWithLoadedOptions: {
      immediate: true,
      handler(value) {
        if (value) {
          this.innerValue = this.options[0].value;
          this.$emit('change', this.innerValue);
        }
      }
    }
  },
  methods: {
    onChange(e) {
      if (e) {
        this.$emit('filled');
      }
      this.$emit('change', e || null);
    }
  }
};
</script>

<style lang="scss" scoped>
.select-label {
  margin-bottom: 8px;
  color: #6886A6;
}

.ant-select::v-deep {
  min-height: 48px;
  width: 100%;
  border: 0;

  &.error {
    .ant-select-selection {
      border: 1px solid #FF655B;
      box-shadow: none;
    }
    &:not(:focus) {
      .ant-select-selection {
        background: #FFF6F5;
      }
    }
  }

  &.ant-select-disabled {
    .ant-select-selection {
      background: #E4E4E4;

      .ant-select-selection-selected-value {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &.with-icon {
    .ant-select-selection {
      padding: 8px 48px;
    }
  }

  .ant-select-selection {
    height: 100%;
    padding: 8px 16px;
    color: #6886A6;
    border: 1px solid transparent;
    font-size: 15px;

    .ant-select-selection__placeholder {
      color: #6886A6;
    }

    .ant-select-selection-selected-value {
      color: #132540;
    }

    .ant-select-selection__rendered {
      margin-left: 0;
    }
  }

  .ant-select-arrow {
    top: 23px;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .app-select::v-deep {
    &.with-icon {
      .ant-select-selection {
        padding: 8px 48px;
      }
    }

    .ant-select-selection {
      font-size: 14px;
    }
  }
}
</style>