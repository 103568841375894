import {
    activateUser,
    createUser,
    destroyUser,
    getAdmins,
    getBoxData,
    getUser,
    getUsers,
    getUsersList,
    importUsers, setUserAsAdmin,
    updateUser
} from '@/api/users';

export default {
    namespaced: true,
    state: {
        boxData: {},
        usersList: [],
        users: [],
        usersMeta: {total: 0},
        admins: [],
        adminsMeta: {total: 0},
        user: null,
    },
    getters: {
        BOX_DATA(state) {
            return state.boxData;
        },
        USERS_LIST(state) {
            return state.usersList;
        },
        USERS(state) {
            return state.users;
        },
        USERS_META(state) {
            return state.usersMeta;
        },
        ADMINS(state) {
            return state.admins;
        },
        ADMINS_META(state) {
            return state.adminsMeta;
        },
        USER(state) {
            return state.user;
        }
    },
    mutations: {
        SET_BOX_DATA(state, data) {
            state.boxData = data;
        },
        SET_USERS_LIST(state, data) {
            state.usersList = data;
        },
        SET_USERS(state, data) {
            state.users = data;
        },
        SET_USERS_META(state, data) {
            state.usersMeta = data;
        },
        SET_ADMINS(state, data) {
            state.admins = data;
        },
        SET_ADMINS_META(state, data) {
            state.adminsMeta = data;
        },
        SET_USER(state, data) {
            state.user = data;
        },
    },
    actions: {
        GET_BOX_DATA(context, organizationId) {
            return new Promise((resolve, reject) => {
                getBoxData(organizationId).then(response => {
                    context.commit('SET_BOX_DATA', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_USERS_LIST(context, filters) {
            return new Promise((resolve, reject) => {
                getUsersList(filters).then(response => {
                    context.commit('SET_USERS_LIST', response.data)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_USERS(context, data) {
            return new Promise((resolve, reject) => {
                getUsers(data).then(response => {
                    context.commit('SET_USERS', response.data);
                    context.commit('SET_USERS_META', response.meta);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_ADMINS(context, filters) {
            return new Promise((resolve, reject) => {
                getAdmins(filters).then(response => {
                    context.commit('SET_ADMINS', response.data);
                    context.commit('SET_ADMINS_META', response.meta);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GET_USER(context, slug) {
            return new Promise((resolve, reject) => {
                getUser(slug).then(response => {
                    context.commit('SET_USER', response.data);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        CREATE_USER(context, data) {
            return new Promise((resolve, reject) => {
                createUser(data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        UPDATE_USER(context, {slug, data}) {
            return new Promise((resolve, reject) => {
                updateUser(slug, data).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ACTIVATE_USER(context, slug) {
            return new Promise((resolve, reject) => {
                activateUser(slug).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        DESTROY_USER(context, slug) {
            return new Promise((resolve, reject) => {
                destroyUser(slug).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        SET_USER_AS_ADMIN(context, data) {
            return new Promise((resolve, reject) => {
                setUserAsAdmin(data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        IMPORT(context, formData) {
            return new Promise((resolve, reject) => {
                importUsers(formData).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
}