<template>
  <div>
    <app-breadcrumb :items="breadcrumbs" class="mb-24px"/>
    <router-view/>
  </div>
</template>

<script>
import AppBreadcrumb from '@/components/elements/AppBreadcrumb';

export default {
  components: {
    AppBreadcrumb
  },
  computed: {
    breadcrumbs() {
      return [
        {name: this.$t('navigation.home'), route: 'Dashboard'},
        {name: this.$t('label.tickets'), route: 'Tickets'}
      ];
    }
  }
}
</script>