<template>
  <div class="app-list-wrapper">
    <div v-if="data.length">
      <a-row v-for="(item, index) in data" :key="index" class="app-list-item d-flex">
        <a-col :span="6" class="d-flex jcc aic">
          <slot name="image" v-bind="item"/>
        </a-col>
        <a-col :span="14" class="p-40px">
          <div class="columns-wrapper d-flex jcb h-100">
            <div
              v-for="(column, idx) in columns"
              :key="`${index}${idx}`"
              class="d-flex flex-column jcb aic"
              :style="`width: ${column.width}%`"
            >
              <div class="text-grey text-center fs-md">{{ $t(`label.${column.label}`) }}</div>
              <div v-if="!column.type" class="text-dark fs-xl">{{ item[column.key] || '-' }}</div>
              <div v-else-if="column.type === 'tag'">
                <a-tag :color="getTagColor(item[column.key]['name'])">
                  {{ $t(`status.${item[column.key]['name']}`) }}
                </a-tag>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :span="4" class="d-flex jcc aic">
          <slot name="actions" v-bind="item"/>
        </a-col>
      </a-row>
      <div class="list-footer-wrapper d-flex jcb aic">
        <div class="actions-wrapper">
          <slot name="pagination-actions"/>
        </div>
        <a-pagination
          v-if="!hidePagination"
          :current="pagination.current || 1"
          :page-size="pagination.defaultPageSize"
          :total="pagination.total"
          @change="handleTableChange"
        />
      </div>
    </div>
    <div v-else class="app-list-item empty d-flex jcc aic">{{ $t('label.no_data') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {type: Array, default: () => []},
    data: {type: Array, default: () => []},
    current: {type: Number, default: 1},
    total: {type: Number, default: 0},
    hidePagination: {type: Boolean, default: false},
    tagColors: {type: Array, default: () => []}
  },
  data: () => ({
    pagination: {defaultPageSize: 12, total: 0, current: 1},
    loading: false
  }),
  watch: {
    current(value) {
      this.pagination.current = value || 1;
    },
    total(value) {
      this.pagination.total = value;
    },

  },
  methods: {
    getTagColor(key) {
      return this.tagColors.find(el => el.key === key)?.color;
    },
    handleTableChange(page) {
      this.$emit('getData', {page: page || 1});
    }
  }
}
</script>

<style lang="scss" scoped>
.app-list-wrapper {
  .empty {
    font-size: 15px;
    color: #6886A6;
  }

  .app-list-item {
    height: 150px;
    width: 100%;
    background: #FFF;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .columns-wrapper {
      padding-bottom: 5px;

      .ant-tag {
        padding: 8px 16px;
        border: 0;
        line-height: 14px;
      }
    }
  }
}

.list-footer-wrapper::v-deep {
  height: 70px;

  .ant-pagination {
    .ant-pagination-prev, .ant-pagination-next {
      height: 38px;
      margin: 0 !important;
      background: transparent;

      .ant-pagination-item-link {
        padding: 12px 23px 12px 16px;
        border: 0;
        background: transparent;

        .anticon {
          display: block;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
        }
      }
    }

    .ant-pagination-item {
      height: 38px;
      margin-right: 0;
      padding: 12px;
      background: transparent;
      border: 1px solid transparent;
      color: #467BFF;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;

      &.ant-pagination-item-active {
        border: 1px solid #467BFF;
      }
    }

    .ant-pagination-jump-next {
      margin-right: 0;

      .ant-pagination-item-ellipsis {
        color: #467BFF;
        font-size: 10px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .app-list-wrapper::v-deep {
    .app-list-item {
      height: 130px;
    }

    .p-40px {
      padding: 28px;
    }

    .list-button {
      padding: 8px 20px;
    }
  }
}
</style>
