<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 10.5C9.10457 10.5 10 9.60457 10 8.5C10 7.39543 9.10457 6.5 8 6.5C6.89543 6.5 6 7.39543 6 8.5C6 9.60457 6.89543 10.5 8 10.5Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5 11.9999C5.34935 11.5342 5.80232 11.1562 6.32304 10.8959C6.84375 10.6356 7.41792 10.5 8.00009 10.5C8.58225 10.5 9.15643 10.6355 9.67716 10.8958C10.1979 11.1561 10.6509 11.5341 11.0002 11.9997" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13 13.5V2.5C13 2.22386 12.7761 2 12.5 2L3.5 2C3.22386 2 3 2.22386 3 2.5V13.5C3 13.7761 3.22386 14 3.5 14H12.5C12.7761 14 13 13.7761 13 13.5Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 4H10" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>