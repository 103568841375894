import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '@/i18n/en.js';
import fr from '@/i18n/fr.js';

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, fr };

// get current selected language
const lang = localStorage.getItem('language') || 'en';

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: lang, // set locale
    messages // set locale messages
});

export default i18n;