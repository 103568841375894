<template>
  <div>
    <a-row :gutter="{ xs: 8, sm: 16 }" class="mb-32px">
      <a-col class="gutter-row" :span="8">
        <div class="gutter-box box-shadow-green">
          <div class="d-flex jcb aic">
            <div>
              <div class="gutter-box-number">{{ computedLastImportDate }}</div>
              <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.last_autoimport') }}</div>
            </div>
            <div class="gutter-box-icon d-flex jcc aic green">
              <calendar-icon height="32" width="32" stroke="#2FC091" view-box="0 0 32 32"/>
            </div>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="8">
        <div class="gutter-box box-shadow-yellow">
          <div class="d-flex jcb aic">
            <div>
              <div class="gutter-box-number">{{ boxData.add || 0 }}</div>
              <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.number_of_users_added') }}</div>
            </div>
            <div class="gutter-box-icon d-flex jcc aic yellow">
              <four-users-icon height="32" width="32" stroke="#EABA13" view-box="0 0 32 32"/>
            </div>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="8">
        <div class="gutter-box box-shadow-red">
          <div class="d-flex jcb aic">
            <div>
              <div class="gutter-box-number">{{ boxData.delete || 0 }}</div>
              <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.number_of_users_deleted') }}</div>
            </div>
            <div class="gutter-box-icon d-flex jcc aic red">
              <trash-icon height="32" width="32" stroke="#FF655B" view-box="0 0 32 32"/>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="{ xs: 8, sm: 16, md: 24 }" class="filters-wrapper">
      <a-col :xs="24" :md="12" :lg="8" class="gutter-row mb-24px">
        <form-input
            :value="filters.search"
            :placeholder="$t('label.search')"
            allow-clear
            with-icon
            @input="filters.search = $event"
        >
          <template #prefix-icon>
            <search-icon stroke="#467BFF"/>
          </template>
        </form-input>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" class="gutter-row mb-24px">
        <form-select
            :value="filters.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            active-first-option
            with-icon
            @change="filters.organization_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" class="gutter-row mb-24px">
        <a-button class="app-button primary w-100" @click="showSetUserAsAdminModal = true">
          {{ $t('btn.set_user_as_admin') }}
        </a-button>
      </a-col>
    </a-row>

    <app-tabs :tabs="['users', 'admins']" @change="activeTab = $event">
      <template #users v-if="activeTab === 0">
        <app-table
            :columns="userColumns"
            :data="usersList"
            :current="usersMeta.current_page"
            :total="usersMeta.total"
            scroll
            @getData="getTableData"
        >
          <span slot="user_id_icon">
            <user-circle-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.user_id') }}</span>
          </span>
          <span slot="email_icon">
            <mail-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.email') }}</span>
          </span>
          <span slot="organization_name_icon">
            <link-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.linked_organization') }}</span>
          </span>
          <span slot="nfc_badge_id_icon">
            <identification-badge-id-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.nfc_id') }}</span>
          </span>
          <span slot="active_pico_cycles_count_icon">
            <archive-box-download-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.pico_taken') }}</span>
          </span>
          <span slot="status_icon">
            <four-circles-icon height="16" width="16" stroke="#467BFF" view-box="0 0 32 32"/>
            <span>{{ $t('label.status') }}</span>
          </span>

          <template #email="{ text }">{{ text || '-' }}</template>
          <template #status="{ text }">
            <a-tag :color="text.name === 'active' ? 'green' : 'red'">{{ $t(`status.${text.name}`) }}</a-tag>
          </template>

          <template v-slot:actions="{ record }">
            <div class="d-flex table-actions-wrapper">
              <a-tooltip v-if="record.status.name === 'active'">
                <template v-slot:title>
                  {{ $t('btn.delete_user') }}
                </template>
                <span class="cursor-pointer" @click="showDestroyUserModal(record)">
                <trash-icon height="18" width="18" stroke="#467BFF" view-box="0 0 32 32"/>
              </span>
              </a-tooltip>
              <a-tooltip v-else>
                <template v-slot:title>
                  {{ $t('btn.activate_user') }}
                </template>
                <span class="cursor-pointer" @click="showActivateUserModal(record)">
                <power-icon height="18" width="18" stroke="#467BFF" view-box="0 0 16 16"/>
              </span>
              </a-tooltip>
              <a-tooltip>
                <template v-slot:title>
                  {{ $t('btn.edit_user') }}
                </template>
                <span class="cursor-pointer" :class="{'hidden': record.status.name !== 'active'}"
                      @click="editUser(record)">
                  <pencil-line-edit-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
                </span>
              </a-tooltip>
              <a-tooltip>
                <template v-slot:title>
                  {{ $t('label.history') }}
                </template>
                <span class="cursor-pointer" @click="goToHistory(record)">
                  <time-reset-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
                </span>
              </a-tooltip>
            </div>
          </template>
          <template #footer-actions>
            <a-button class="app-button outline-primary" @click="showUserModal = true">
              {{ $t('btn.create_user') }}
            </a-button>
          </template>
        </app-table>
      </template>
      <template #admins v-if="activeTab === 1">
        <app-table
            :columns="userColumns"
            :data="adminsList"
            :current="adminsMeta.current_page"
            :total="adminsMeta.total"
            scroll
            @getData="getTableData"
        >
          <span slot="user_id_icon">
            <user-circle-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.user_id') }}</span>
          </span>
          <span slot="email_icon">
            <mail-icon height="16" width="16" stroke="#467BFF"/>
            <span>{{ $t('label.email') }}</span>
          </span>
          <span slot="organization_name_icon">
            <link-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.linked_organization') }}</span>
          </span>
          <span slot="nfc_badge_id_icon">
            <identification-badge-id-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.nfc_id') }}</span>
          </span>
          <span slot="active_pico_cycles_count_icon">
            <archive-box-download-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
            <span>{{ $t('label.pico_taken') }}</span>
          </span>
          <span slot="status_icon">
            <four-circles-icon height="16" width="16" stroke="#467BFF" view-box="0 0 32 32"/>
            <span>{{ $t('label.status') }}</span>
          </span>

          <template #email="{ text }">{{ text || '-' }}</template>
          <template #nfc_badge_id="{ text }">{{ text || '-' }}</template>
          <template #status="{ text }">
            <a-tag :color="text.name === 'active' ? 'green' : 'red'">{{ $t(`status.${text.name}`) }}</a-tag>
          </template>

          <template v-slot:actions="{ record }">
            <div class="d-flex table-actions-wrapper">
              <a-tooltip v-if="record.status.name === 'active'">
                <template v-slot:title>
                  {{ $t('btn.delete_user') }}
                </template>
                <span class="cursor-pointer" @click="showDestroyUserModal(record)">
                <trash-icon height="18" width="18" stroke="#467BFF" view-box="0 0 32 32"/>
              </span>
              </a-tooltip>
              <a-tooltip v-else>
                <template v-slot:title>
                  {{ $t('btn.activate_user') }}
                </template>
                <span class="cursor-pointer" @click="showActivateUserModal(record)">
                <power-icon height="18" width="18" stroke="#467BFF" view-box="0 0 16 16"/>
              </span>
              </a-tooltip>
              <a-tooltip>
                <template v-slot:title>
                  {{ $t('btn.edit_user') }}
                </template>
                <span class="cursor-pointer" :class="{'hidden': record.status.name !== 'active'}"
                      @click="editUser(record)">
                  <pencil-line-edit-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
                </span>
              </a-tooltip>
              <a-tooltip>
                <template v-slot:title>
                  {{ $t('label.history') }}
                </template>
                <span class="cursor-pointer" @click="goToHistory(record)">
                  <time-reset-icon height="16" width="16" stroke="#467BFF" view-box="0 0 16 16"/>
                </span>
              </a-tooltip>
            </div>
          </template>
        </app-table>
      </template>
    </app-tabs>

    <set-user-as-admin-modal
        :show="showSetUserAsAdminModal"
        :organizations-list="organizationsList"
        @close="showSetUserAsAdminModal = false"
    />
    <user-modal :show="showUserModal" :user-slug="editableUserSlug" @close="onUserModalClose"/>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import {settings} from '@/core/defaultSettings';
import generalMixin from '@/mixins/general';
import AppTable from '@/components/elements/AppTable';
import AppTabs from '@/components/elements/AppTabs';
import SetUserAsAdminModal from './modals/SetUserAsAdminModal';
import UserModal from './modals/UserModal';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';
import ArchiveBoxDownloadIcon from '@/assets/icons/archiveBoxDownloadIcon';
import CalendarIcon from '@/assets/icons/calendarIcon';
import FourCirclesIcon from '@/assets/icons/fourCirclesIcon';
import FourUsersIcon from '@/assets/icons/fourUsersIcon';
import IdentificationBadgeIdIcon from '@/assets/icons/identificationBadgeIdIcon';
import LinkIcon from '@/assets/icons/linkIcon';
import ListIcon from '@/assets/icons/listIcon';
import MailIcon from '@/assets/icons/mailIcon';
import PencilLineEditIcon from '@/assets/icons/pencilLineEditIcon';
import PowerIcon from '@/assets/icons/powerIcon';
import SearchIcon from '@/assets/icons/searchIcon';
import TimeResetIcon from '@/assets/icons/timeResetIcon';
import TrashIcon from '@/assets/icons/trashIcon';
import UserCircleIcon from '@/assets/icons/userCircleIcon';

export default {
  components: {
    AppTable,
    AppTabs,
    SetUserAsAdminModal,
    UserModal,
    FormInput,
    FormSelect,
    ArchiveBoxDownloadIcon,
    CalendarIcon,
    FourCirclesIcon,
    FourUsersIcon,
    IdentificationBadgeIdIcon,
    LinkIcon,
    ListIcon,
    MailIcon,
    PencilLineEditIcon,
    PowerIcon,
    SearchIcon,
    TimeResetIcon,
    TrashIcon,
    UserCircleIcon
  },
  mixins: [generalMixin],
  data: () => ({
    activeTab: 0,
    showSetUserAsAdminModal: false,
    showUserModal: false,
    editableUser: null,
    filters: {
      search: null,
      organization_id: null,
      pico_taken: null
    }
  }),
  mounted() {
    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
    if (this.$route.query?.organization_id) {
      this.filters.organization_id = parseInt(this.$route.query.organization_id);
    }
    if ('pico_taken' in this.$route.query) {
      this.filters.pico_taken = true;
    }
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      boxData: 'usersStore/BOX_DATA',
      usersList: 'usersStore/USERS',
      usersMeta: 'usersStore/USERS_META',
      adminsList: 'usersStore/ADMINS',
      adminsMeta: 'usersStore/ADMINS_META'
    }),
    userColumns() {
      return [
        {
          dataIndex: 'user_id',
          slots: {title: 'user_id_icon'},
          scopedSlots: {customRender: 'user_id'}
        },
        {
          dataIndex: 'email',
          slots: {title: 'email_icon'},
          scopedSlots: {customRender: 'email'}
        },
        {
          dataIndex: 'organization_name',
          slots: {title: 'organization_name_icon'},
          scopedSlots: {customRender: 'organization_name'}
        },
        {
          dataIndex: 'nfc_badge_id',
          slots: {title: 'nfc_badge_id_icon'},
          scopedSlots: {customRender: 'nfc_badge_id'}
        },
        {
          dataIndex: 'active_pico_cycles_count',
          slots: {title: 'active_pico_cycles_count_icon'},
          scopedSlots: {customRender: 'active_pico_cycles_count'}
        },
        {
          dataIndex: 'status',
          slots: {title: 'status_icon'},
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '',
          dataIndex: 'actions',
          scopedSlots: {customRender: 'actions'}
        }
      ];
    },
    editableUserSlug() {
      return this.editableUser?.slug;
    },
    selectedOrganizationId() {
      return this.filters.organization_id;
    },
    computedLastImportDate() {
      return this.boxData?.time ? generalMixin.methods.timestampToDate(this.boxData.time) : '-';
    }
  },
  watch: {
    selectedOrganizationId(id) {
      if (id) {
        this.$store.dispatch('usersStore/GET_BOX_DATA', id);
      }
    },
    activeTab() {
      this.getTableData();
    },
    showUserModal(value) {
      if (!value) {
        this.editableUser = null;
      }
    },
    filters: {
      immediate: true,
      handler: _.debounce(function () {
        this.getTableData();
      }, settings.debounceTime),
      deep: true
    }
  },
  methods: {
    getTableData(pagination) {
      if (this.activeTab) {
        this.$store.dispatch('usersStore/GET_ADMINS', {...this.filters, ...pagination});
      } else {
        this.$store.dispatch('usersStore/GET_USERS', {...this.filters, ...pagination});
      }
    },
    goToHistory(user) {
      this.$router.push({name: 'History', query: {user_id: user.user_id}});
    },
    editUser(user) {
      this.editableUser = user;
      this.showUserModal = true;
    },
    onUserModalClose(e) {
      this.showUserModal = false;
      if (e) this.getTableData();
    },
    showActivateUserModal(user) {
      this.$confirm({
        title: this.$t('modal.activate_user'),
        centered: true,
        closable: true,
        okType: 'primary',
        cancelText: this.$t('btn.no'),
        okText: this.$t('btn.yes'),
        onOk: () => {
          this.$store.dispatch('usersStore/ACTIVATE_USER', user.slug)
          .then(() => {
            this.getTableData();
            this.$message.success(this.$t('alert.user_restored'));
          })
          .catch(err => this.$message.error(err));
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    showDestroyUserModal(user) {
      this.$confirm({
        title: this.$t('modal.delete_user'),
        centered: true,
        closable: true,
        okType: 'primary',
        cancelText: this.$t('btn.no'),
        okText: this.$t('btn.yes'),
        onOk: () => {
          this.$store.dispatch('usersStore/DESTROY_USER', user.slug)
          .then(() => {
            this.getTableData();
            this.$message.success(this.$t('alert.user_deleted'));
          })
          .catch(error => {
            this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
          });
        },
        onCancel: () => {
          console.log('cancel');
        },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.gutter-example > > > .ant-row > div {
  background: transparent;
  border: 0;
}

.gutter-box {
  padding: 40px 32px;
  background: #fff;

  .gutter-box-number {
    color: #132540;
    font-size: 28px;
    font-weight: 900;
  }

  .gutter-box-label {
    color: #6886A6;
  }

  .gutter-box-btn {
    height: auto;
    margin-top: 32px;
    padding: 8px 28px;
    background: #467BFF;
  }
}

.card-container::v-deep {
  .ant-tabs {
    .ant-tabs-bar {
      margin-bottom: 0;
    }
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
</style>