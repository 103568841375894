<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V4.5L3.5 2.5H12.5L13.5 4.5V13C13.5 13.1326 13.4473 13.2598 13.3536 13.3536C13.2598 13.4473 13.1326 13.5 13 13.5Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.88086 9.38135L7.99961 11.5001L10.1184 9.38135" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 6.5V11.5" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 4.5H13.5" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>