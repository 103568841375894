<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 10C8.82107 10 10.5 8.32107 10.5 6.25C10.5 4.17893 8.82107 2.5 6.75 2.5C4.67893 2.5 3 4.17893 3 6.25C3 8.32107 4.67893 10 6.75 10Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.38867 12.4999C2.04576 11.717 2.86629 11.0875 3.79263 10.6557C4.71897 10.2238 5.72864 10 6.7507 10C7.77276 10 8.78243 10.2238 9.70877 10.6557C10.6351 11.0876 11.4556 11.7171 12.1127 12.4999" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.75 9.5C14.3023 9.5 14.75 9.05228 14.75 8.5C14.75 7.94772 14.3023 7.5 13.75 7.5C13.1977 7.5 12.75 7.94772 12.75 8.5C12.75 9.05228 13.1977 9.5 13.75 9.5Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.75 7.5V6.75" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.884 8L12.2345 7.625" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.884 9L12.2345 9.375" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.75 9.5V10.25" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.6172 9L15.2667 9.375" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.6172 8L15.2667 7.625" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>