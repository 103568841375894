import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import echo from './modules/echo';
import basesStore from './modules/bases';
import dashboardStore from './modules/dashboard';
import notificationsStore from './modules/notifications';
import organizationsStore from './modules/organization';
import picosStore from './modules/picos';
import settingsStore from './modules/settings';
import socketsStore from './modules/sockets';
import ticketsStore from './modules/tickets';
import usersStore from './modules/users';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    echo,
    basesStore,
    dashboardStore,
    notificationsStore,
    organizationsStore,
    picosStore,
    settingsStore,
    socketsStore,
    ticketsStore,
    usersStore
  }
});