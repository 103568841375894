<template>
  <div>
    <a-row :gutter="32" class="mb-24px">
      <a-col :xs="24" :md="8" class="gutter-row">
        <form-select
            :value="form.category_id"
            :options="categoriesList"
            :placeholder="$t('label.my_problem_is_about')"
            :error="error.category_id"
            required
            with-icon
            @filled="error.category_id = null"
            @change="form.category_id = $event"
        >
          <template #prefix-icon>
            <notification-icon stroke="#467BFF"/>
          </template>
        </form-select>
      </a-col>
      <a-col v-if="isOrganizationFieldVisible" :xs="24" :md="8" class="gutter-row">
        <form-select
            :value="form.params.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            :error="error.organization_id"
            required
            :active-first-option="organizationsList.length === 1"
            with-icon
            @filled="error.organization_id = null"
            @change="form.params.organization_id = $event"
        >
          <template #prefix-icon>
            <factory-icon stroke="#467BFF"/>
          </template>
        </form-select>
      </a-col>
      <a-col v-if="isBaseFieldVisible" :xs="24" :md="8" class="gutter-row">
        <form-select
            :value="form.params.base_id"
            :options="basesList"
            :placeholder="$t('label.base')"
            :error="error.base_id"
            required
            with-icon
            @filled="error.base_id = null"
            @change="form.params.base_id = $event"
        >
          <template #prefix-icon>
            <buildings-icon height="32" width="32" stroke="#467BFF" view-box="0 0 24 20"/>
          </template>
        </form-select>
      </a-col>
    </a-row>

    <a-row :gutter="32" class="mb-24px">
      <a-col v-if="isPicoFieldVisible" :xs="24" :md="12" :lg="8" class="gutter-row">
        <form-select
            :value="form.params.pico_id"
            :options="computedPicosList"
            :placeholder="$t('label.pico_id')"
            :disabled="!selectedBaseId"
            :error="error.pico_id"
            allow-clear
            with-icon
            @change="form.params.pico_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
      <a-col v-if="isNfcFieldVisible" :xs="24" :md="12" :lg="8" class="gutter-row">
        <form-input
            :value="form.params.nfc_id"
            :placeholder="$t('label.nfc_id')"
            :error="error.nfc_id"
            required
            with-icon
            @filled="error.nfc_id = null"
            @input="form.params.nfc_id = $event"
        >
          <template #prefix-icon>
            <user-circle-icon stroke="#467BFF"/>
          </template>
        </form-input>
      </a-col>
    </a-row>

    <div class="textarea-wrapper mb-24px">
      <form-textarea
          :placeholder="$t('placeholder.your_message')"
          :value="form.message"
          :error="error.message"
          required
          :rows="8"
          :element-class="`p-16px ${form.attachment ? 'pr-200px' : 'pr-50px'}`"
          @filled="error.message = null"
          @input="form.message = $event"
      />
      <form-image-uploader @change="form.attachment = $event"/>
    </div>

    <a-button class="app-button primary d-block" @click="submit">{{ $t('btn.send') }}</a-button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import FormImageUploader from '@/components/elements/FormImageUploader';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';
import FormTextarea from '@/components/elements/FormTextarea';
import BuildingsIcon from '@/assets/icons/buildingsIcon';
import FactoryIcon from '@/assets/icons/factoryIcon';
import ListIcon from '@/assets/icons/listIcon';
import NotificationIcon from '@/assets/icons/notificationIcon';
import UserCircleIcon from '@/assets/icons/userCircleIcon';

export default {
  components: {
    FormImageUploader,
    FormInput,
    FormSelect,
    FormTextarea,
    BuildingsIcon,
    FactoryIcon,
    ListIcon,
    NotificationIcon,
    UserCircleIcon
  },
  data: () => ({
    form: {
      category_id: null,
      message: null,
      attachment: null,
      params: {
        base_id: null,
        organization_id: null,
        pico_id: null,
        nfc_id: null
      }
    },
    error: {
      base_id: null,
      category_id: null,
      message: null,
      organization_id: null,
      pico_id: null,
      nfc_id: null,
    }
  }),
  beforeMount() {
    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
    this.$store.dispatch('ticketsStore/GET_CATEGORIES');
    this.$store.dispatch('ticketsStore/GET_PROBLEMS');
  },
  computed: {
    ...mapGetters({
      profile: 'me',
      basesList: 'basesStore/DROPDOWN_LIST',
      categoriesList: 'ticketsStore/CATEGORIES',
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      picosList: 'picosStore/PICOS',
    }),
    computedPicosList() {
      return this.picosList.filter(el => el.pico_id).map(el => {
        return {value: el.id, label: el.pico_id};
      })
    },
    selectedCategoryId() {
      return this.form.category_id;
    },
    selectedOrganizationId() {
      return this.form.params.organization_id;
    },
    selectedBaseId() {
      return this.form.params.base_id;
    },
    isOrganizationFieldVisible() {
      return this.selectedCategoryId;
    },
    isBaseFieldVisible() {
      return this.selectedCategoryId;
    },
    isPicoFieldVisible() {
      return this.form.category_id === 3 || this.form.category_id === 4 || this.form.category_id === 5;
    },
    isNfcFieldVisible() {
      return this.form.category_id === 2 || this.form.category_id === 5;
    }
  },
  watch: {
    selectedCategoryId(id) {
      if (id) {
        this.clearForm();
        this.clearError();
      }
    },
    selectedOrganizationId: {
      immediate: true,
      handler(id) {
        this.form.params.base_id = null;
        this.$store.dispatch('basesStore/GET_DROPDOWN_LIST', {organization_id: id});
      }
    },
    selectedBaseId: {
      handler(id) {
        this.form.params.pico_id = null;
        if (id) {
          this.$store.dispatch('picosStore/GET_PICOS_FROM_BASE', id);
        }
      }
    }
  },
  methods: {
    clearForm() {
      Object.entries(this.form.params).map(el => this.form.params[el[0]] = null);
    },
    clearError() {
      Object.entries(this.error).map(el => this.error[el[0]] = null);
    },
    validateForm() {
      this.error.category_id = !this.form.category_id ? this.$t('error.required') : null;
      this.error.organization_id = this.isOrganizationFieldVisible && this.form.category_id !== 5 && !this.form.params.organization_id
          ? this.$t('error.required')
          : null;
      this.error.base_id = this.isBaseFieldVisible && this.form.category_id !== 5 && !this.form.params.base_id ? this.$t('error.required') : null;
      this.error.pico_id = this.isPicoFieldVisible && this.form.category_id !== 5 && !this.form.params.pico_id ? this.$t('error.required') : null;
      this.error.nfc_id = this.isNfcFieldVisible && this.form.category_id !== 5 && !this.form.params.nfc_id ? this.$t('error.required') : null;
      this.error.message = !this.form.message ? this.$t('error.required') : null;

      return !Object.values(this.error).some(v => v);
    },
    submit() {
      this.clearError();
      if (!this.validateForm()) return false;
      let formData = new FormData();
      formData.append('message', this.form.message);
      formData.append('category_id', this.form.category_id);
      if (this.form.attachment) formData.append('attachment', this.form.attachment);
      Object.entries(this.form.params).map(el => {
        formData.append(`params[${el[0]}]`, el[1] || '');
      });

      this.$store.dispatch('ticketsStore/CREATE_TICKET', formData).then(() => {
        this.$message.success(this.$t('alert.ticket_created'));
        this.$router.push({name: 'ContactList'});
      })
      .catch(error => {
        if (error.response.status === 422) {
          for (const [key, errorMessage] of Object.entries(error.response.data.error.errors)) {
            let formattedKey = key;
            if (key.includes('params.')) {
              formattedKey = key.split('.')[1];
            }
            this.error[formattedKey] = errorMessage[0];
          }
        } else {
          this.$message.error(error.response.data?.error?.message || this.$t('alert.something_goes_wrong'));
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea-wrapper {
  position: relative;
}
</style>