import { axios } from '@/utils/request'

export function login(parameter) {
  return axios({url: 'v2/auth/login', method: 'post', data: parameter});
}

export function logout() {
  return axios({url: 'v2/auth/logout', method: 'post'});
}

export function recoverPassword(email) {
  return axios({url: 'v2/auth/password/reset', method: 'post', data: email});
}

export function resetPassword(data) {
  return axios({url: 'v2/auth/password/update', method: 'post', data: data});
}

export function changePassword(data) {
  return axios({url: 'v2/auth/password/change', method: 'post', data: data});
}

export function getLoggedUser() {
  return axios({url: 'v2/auth/profile', method: 'get'});
}