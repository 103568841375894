<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.94531 4.5H20.0545C20.1998 4.5 20.342 4.54221 20.4637 4.62149C20.5855 4.70077 20.6816 4.81371 20.7404 4.94658C20.7992 5.07946 20.8181 5.22655 20.7948 5.36998C20.7716 5.51341 20.7072 5.64699 20.6094 5.7545L14.4449 12.5355C14.3194 12.6735 14.2499 12.8534 14.2499 13.04V18.3486C14.2499 18.4721 14.2194 18.5936 14.1612 18.7025C14.1029 18.8114 14.0187 18.9042 13.9159 18.9726L10.9159 20.9727C10.803 21.048 10.6717 21.0912 10.5361 21.0977C10.4005 21.1043 10.2657 21.0739 10.146 21.0099C10.0263 20.9458 9.92626 20.8505 9.8565 20.734C9.78674 20.6176 9.7499 20.4844 9.7499 20.3486V13.04C9.7499 12.8534 9.68036 12.6735 9.55485 12.5355L3.39036 5.7545C3.29262 5.64699 3.22822 5.51341 3.20497 5.36998C3.18173 5.22655 3.20065 5.07946 3.25943 4.94658C3.31821 4.81371 3.41432 4.70077 3.53608 4.62149C3.65785 4.54221 3.80001 4.5 3.94531 4.5V4.5Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>