<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4_989)">
      <path d="M15.2 8.00005C15.2 6.09049 14.4414 4.25914 13.0912 2.90888C11.7409 1.55862 9.90956 0.800049 8 0.800049V8.00005H15.2Z" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.40002 3.5999C2.40002 3.91816 2.52645 4.22339 2.7515 4.44843C2.97654 4.67347 3.28176 4.7999 3.60002 4.7999C3.91828 4.7999 4.22351 4.67347 4.44855 4.44843C4.6736 4.22339 4.80002 3.91816 4.80002 3.5999C4.80002 3.28164 4.6736 2.97642 4.44855 2.75137C4.22351 2.52633 3.91828 2.3999 3.60002 2.3999C3.28176 2.3999 2.97654 2.52633 2.7515 2.75137C2.52645 2.97642 2.40002 3.28164 2.40002 3.5999Z" :fill="fill"/>
      <path d="M2.40002 12.4C2.40002 12.7182 2.52645 13.0234 2.7515 13.2485C2.97654 13.4735 3.28176 13.6 3.60002 13.6C3.91828 13.6 4.22351 13.4735 4.44855 13.2485C4.6736 13.0234 4.80002 12.7182 4.80002 12.4C4.80002 12.0817 4.6736 11.7765 4.44855 11.5514C4.22351 11.3264 3.91828 11.2 3.60002 11.2C3.28176 11.2 2.97654 11.3264 2.7515 11.5514C2.52645 11.7765 2.40002 12.0817 2.40002 12.4Z" :fill="fill"/>
      <path d="M6.80005 14.8001C6.80005 15.1184 6.92648 15.4236 7.15152 15.6486C7.37656 15.8737 7.68179 16.0001 8.00005 16.0001C8.31831 16.0001 8.62353 15.8737 8.84858 15.6486C9.07362 15.4236 9.20005 15.1184 9.20005 14.8001C9.20005 14.4818 9.07362 14.1766 8.84858 13.9516C8.62353 13.7265 8.31831 13.6001 8.00005 13.6001C7.68179 13.6001 7.37656 13.7265 7.15152 13.9516C6.92648 14.1766 6.80005 14.4818 6.80005 14.8001Z" :fill="fill"/>
      <path d="M11.2 12.4C11.2 12.7182 11.3264 13.0234 11.5514 13.2485C11.7765 13.4735 12.0817 13.6 12.4 13.6C12.7182 13.6 13.0234 13.4735 13.2485 13.2485C13.4735 13.0234 13.6 12.7182 13.6 12.4C13.6 12.0817 13.4735 11.7765 13.2485 11.5514C13.0234 11.3264 12.7182 11.2 12.4 11.2C12.0817 11.2 11.7765 11.3264 11.5514 11.5514C11.3264 11.7765 11.2 12.0817 11.2 12.4Z" :fill="fill"/>
      <path d="M0 8.00005C0 8.31831 0.126428 8.62353 0.351472 8.84858C0.576515 9.07362 0.88174 9.20005 1.2 9.20005C1.51826 9.20005 1.82348 9.07362 2.04853 8.84858C2.27357 8.62353 2.4 8.31831 2.4 8.00005C2.4 7.68179 2.27357 7.37656 2.04853 7.15152C1.82348 6.92648 1.51826 6.80005 1.2 6.80005C0.88174 6.80005 0.576515 6.92648 0.351472 7.15152C0.126428 7.37656 0 7.68179 0 8.00005H0Z" :fill="fill"/>
    </g>
    <defs>
      <clipPath id="clip0_4_989">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>