<template>
  <a-layout class="main-layout">
    <div class="bg-image"></div>
    <a-layout-sider>
      <app-menu/>
    </a-layout-sider>
    <a-layout>
      <a-layout-header>
        <app-header/>
      </a-layout-header>
      <a-layout-content>
        <router-view/>
        <a-back-top />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import Vue from 'vue';
import Socket from '@/core/echo';
import AppHeader from '@/components/layout/components/AppHeader';
import AppMenu from '@/components/layout/components/AppMenu';

export default {
  components: {
    AppHeader,
    AppMenu,
  },
  beforeMount() {
    Socket.installEcho(Vue);
    this.$store.dispatch('ticketsStore/GET_COUNT');

    this.updateLanguage();
  },
  methods: {
    updateLanguage() {
      const lsLang = localStorage.getItem('language');
      if (lsLang) {
        this.$i18n.locale = lsLang;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-layout::v-deep {
  min-height: 100vh;
  position: relative;
  background: #F7F8FA !important;

  .bg-image {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-image: url("@/assets/background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .ant-layout-sider {
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
    background: #fff;
  }

  .ant-layout-header {
    height: 130px;
    padding: 0 100px 0 60px;
    background: #F7F8FA !important;
    border-bottom: 2px solid #F4F4F4;
    z-index: 1;
  }

  .ant-layout-content {
    padding: 40px 100px 80px 60px;
    z-index: 1;
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .main-layout::v-deep {
    .ant-layout-header {
      height: 75px;
      padding: 0 60px 0 60px;
    }

    .ant-layout-content {
      padding: 40px 60px 80px 60px;
    }
  }
}
</style>