<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 16.5H10.125" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.875 16.5H16.5" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.25 12.75L18.8438 2.89687C18.8189 2.71793 18.7303 2.55397 18.5942 2.43512C18.4582 2.31628 18.2838 2.25054 18.1031 2.25H16.3969C16.2162 2.25054 16.0418 2.31628 15.9058 2.43512C15.7697 2.55397 15.6811 2.71793 15.6562 2.89687L14.3906 11.7375" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.25 20.25V12.75H15.75L9.75 8.25V12.75L3.75 8.25V20.25" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.5 20.25H22.5" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>