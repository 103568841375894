<template>
  <div>
    <a-row :gutter="16" class="mb-32px">
      <a-col class="gutter-row" :span="12">
        <div class="gutter-box box-shadow-green">
          <div class="d-flex jcb aic">
            <div>
              <div class="gutter-box-number">{{ boxData.number_of_pico_inside }}</div>
              <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.number_of_pico_general') }}</div>
            </div>
            <div class="gutter-box-icon d-flex jcc aic green">
              <four-circles-icon height="32" width="32" stroke="#2FC091" view-box="0 0 32 32"/>
            </div>
          </div>
        </div>
      </a-col>
      <a-col class="gutter-row" :span="12">
        <div class="gutter-box box-shadow-yellow">
          <div class="d-flex jcb aic">
            <div>
              <div class="gutter-box-number">{{ boxData.number_of_pico_unlocked }}</div>
              <div class="gutter-box-label fs-md geomanist-font">{{ $t('label.number_of_pico_unlocked') }}</div>
            </div>
            <div class="gutter-box-icon d-flex jcc aic yellow">
              <lock-open-icon height="32" width="32" stroke="#EABA13" view-box="0 0 32 32"/>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="32" class="filters-wrapper">
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-input
            :value="filters.search"
            :placeholder="$t('label.search')"
            allow-clear
            with-icon
            @input="filters.search = $event"
        >
          <template #prefix-icon>
            <search-icon stroke="#467BFF"/>
          </template>
        </form-input>
      </a-col>
      <a-col :xs="24" :md="12" :lg="8" :xl="8" class="gutter-row mb-24px">
        <form-select
            :value="filters.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            active-first-option
            with-icon
            @change="filters.organization_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
    </a-row>

    <app-list
        :columns="columns"
        :data="listData"
        :current="computedCurrentPage"
        :total="computedTotalItems"
        :tag-colors="tagColors"
        @getData="getListData"
    >
      <template #image="item">
        <div class="pico-icons-wrapper d-flex">
          <div v-for="idx in 10" :key="idx" class="pico-icon" :class="getClass(idx, item)"></div>
        </div>
      </template>
      <template #actions="item">
        <a-button class="app-button list-button outline-primary" @click="goToDetails(item)">{{ $t('label.details') }}</a-button>
      </template>
      <template #pagination-actions>
        <a-button class="app-button primary" @click="goToHistory">{{ $t('label.history') }}</a-button>
      </template>
    </app-list>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import {settings} from '@/core/defaultSettings';
import AppList from '@/components/elements/AppList';
import FormInput from '@/components/elements/FormInput';
import FormSelect from '@/components/elements/FormSelect';
import FourCirclesIcon from '@/assets/icons/fourCirclesIcon';
import ListIcon from '@/assets/icons/listIcon';
import LockOpenIcon from '@/assets/icons/lockOpenIcon';
import SearchIcon from '@/assets/icons/searchIcon';

export default {
  components: {
    AppList,
    FormInput,
    FormSelect,
    FourCirclesIcon,
    ListIcon,
    LockOpenIcon,
    SearchIcon
  },
  data: () => ({
    echoChannel: 'picos-list',
    echoEvent: '.status_updated',
    pagination: {page: 1},
    filters: {
      search: null,
      organization_id: null,
      online: null
    },
    columns: [
      {key: 'long_alias', label: 'long_alias', width: 40, type: null},
      {key: 'short_alias', label: 'alias', width: 20, type: null},
      {key: 'online_status', label: 'status', width: 20, type: 'tag'},
      {key: 'pico_number', label: 'available_pico', width: 20, type: null},
    ],
    tagColors: [
      {key: 'active', color: 'green'},
      {key: 'low_activity', color: 'orange'},
      {key: 'off', color: 'red'},
      {key: 'offline', color: 'red'},
    ]
  }),
  async mounted() {
    await this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');

    if (this.$route.query?.organization_id) {
      this.filters.organization_id = parseInt(this.$route.query.organization_id);
    }
    if ('online' in this.$route.query) {
      this.filters.online = true;
    }

    this.$echo.private(this.echoChannel).listen(this.echoEvent, pico => {
      if (pico?.main_organization_id && this.profile.organizations.find(el => el.id === pico.main_organization_id)) {
        this.getListData(this.pagination);
      }
    }).error(err => {
      console.log(`echo ${this.echoChannel} channel error`);
      console.log({err});
    });
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      boxData: 'basesStore/BOX_DATA',
      listData: 'basesStore/LIST_DATA',
      listMeta: 'basesStore/LIST_META',
      profile: 'me'
    }),
    selectedOrganizationId() {
      return this.filters.organization_id;
    },
    computedCurrentPage() {
      return this.listMeta?.current_page || 1;
    },
    computedTotalItems() {
      return this.listMeta?.total || this.listData.length;
    }
  },
  watch: {
    filters: {
      handler: _.debounce(function () {
        this.getListData();
      }, settings.debounceTime),
      deep: true
    },
    selectedOrganizationId(id) {
      if (id) {
        this.$store.dispatch('basesStore/GET_BOX_DATA', id);
      }
    }
  },
  methods: {
    getListData(pagination) {
      this.$store.dispatch('basesStore/GET_LIST_DATA', {...this.filters, ...pagination});
      this.pagination = pagination;
    },
    goToDetails(item) {
      this.$router.push({name: 'BaseDetails', params: {id: item.id}});
    },
    goToHistory() {
      this.$router.push({name: 'History'});
    },
    getClass(currentIndex, item) {
      let colorClass = null;

      if (currentIndex <= item.pico_number) {
        colorClass = 'green';
      }

      return colorClass;
    }
  },
  beforeDestroy() {
    this.$echo.leave(this.echoChannel);
  }
}
</script>

<style lang="scss" scoped>
.gutter-example > > > .ant-row > div {
  background: transparent;
  border: 0;
}

.gutter-box {
  padding: 40px 32px;
  background: #fff;

  .gutter-box-number {
    color: #132540;
    font-size: 28px;
    font-weight: 900;
  }

  .gutter-box-label {
    color: #6886A6;
  }

  .gutter-box-btn {
    height: auto;
    margin-top: 32px;
    padding: 8px 28px;
    background: #467BFF;
  }
}

.pico-icons-wrapper {
  .pico-icon {
    height: 32px;
    width: 16px;
    background: #E4E4E4;
    border-radius: 4px;

    &.green {
      background: #43E9B3;
    }

    &.orange {
      background: #FFD43D;
    }

    &.red {
      background: #FF655B;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .gutter-box {
    padding: 40px 32px;

    .gutter-box-btn {
      margin-top: 32px;
      padding: 8px 28px;
    }
  }

  .pico-icons-wrapper {
    .pico-icon {
      height: 26px;
      width: 13px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}
</style>
