<template>
  <div>
    <a-row :gutter="32" class="mb-24px">
      <a-col :span="8">
        <form-input
            :value="filters.search"
            :placeholder="$t('label.search')"
            allow-clear
            with-icon
            @input="filters.search = $event"
        >
          <template #prefix-icon>
            <search-icon stroke="#467BFF"/>
          </template>
        </form-input>
      </a-col>
      <a-col class="gutter-row" :span="8">
        <form-select
            :value="filters.organization_id"
            :options="organizationsList"
            :placeholder="$t('label.organization')"
            allow-clear
            with-icon
            @change="filters.organization_id = $event"
        >
          <template #prefix-icon>
            <list-icon/>
          </template>
        </form-select>
      </a-col>
      <a-col class="gutter-row" :span="8">
        <a-button
            class="app-button no-border filters-toggle-btn d-flex aic text-left w-100"
            @click="moreFiltersState = !moreFiltersState"
        >
          <funnel-icon stroke="#467BFF"/>
          <span class="text-grey">{{ moreFiltersState ? $t('btn.hide_filters') : $t('btn.show_filters') }}</span>
        </a-button>
      </a-col>
    </a-row>
    <div v-show="moreFiltersState">
      <a-row :gutter="32" class="mb-24px">
        <a-col class="gutter-row" :span="8">
          <form-input
              :value="filters.user_id"
              :placeholder="$t('label.user_id')"
              with-icon
              allow-clear
              @input="filters.user_id = $event"
          >
            <template #prefix-icon>
              <list-icon stroke="#467BFF"/>
            </template>
          </form-input>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <form-input
              :value="filters.base_id"
              :placeholder="$t('label.base_id')"
              with-icon
              allow-clear
              @input="filters.base_id = $event"
          >
            <template #prefix-icon>
              <list-icon stroke="#467BFF"/>
            </template>
          </form-input>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <form-input
              :value="filters.pico_id"
              :placeholder="$t('label.pico_id')"
              with-icon
              allow-clear
              @input="filters.pico_id = $event"
          >
            <template #prefix-icon>
              <list-icon stroke="#467BFF"/>
            </template>
          </form-input>
        </a-col>
      </a-row>
      <a-row :gutter="32" class="mb-24px">
        <a-col class="gutter-row" :span="8">
          <form-date-range-picker
              :value="filters.date"
              :placeholder="[$t('placeholder.from'), $t('placeholder.to')]"
              allow-clear
              with-icon
              @change="filters.date = $event"
          >
            <template #prefix-icon>
              <calendar-icon height="24" width="24" stroke="#467BFF" view-box="0 0 32 24"/>
            </template>
          </form-date-range-picker>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <form-input-select
              :input-placeholder="$t('placeholder.time_of_use_from')"
              :input-value="filters.use_time_from_value"
              :select-value="filters.use_time_from_unit"
              :options="holdingTimeOptions"
              active-first-option
              input-width="80"
              select-width="20"
              @keydown="validateNumber"
              @input="filters.use_time_from_value = $event"
              @change="filters.use_time_from_unit = $event"
          />
        </a-col>
        <a-col class="gutter-row" :span="8">
          <form-input-select
              :input-placeholder="$t('placeholder.time_of_use_to')"
              :input-value="filters.use_time_to_value"
              :select-value="filters.use_time_to_unit"
              :options="holdingTimeOptions"
              active-first-option
              input-width="80"
              select-width="20"
              @keydown="validateNumber"
              @input="filters.use_time_to_value = $event"
              @change="filters.use_time_to_unit = $event"
          />
        </a-col>
      </a-row>
    </div>


    <a-row>
      <app-table
          :columns="tableColumns"
          :data="history"
          :total="historyMeta.total"
          :current="historyMeta.current_page"
          scroll
          @getData="getTableData"
      >
        <template #base_to_title="{text}">{{ text || '-' }}</template>
        <template #user_title="{text}">{{ text || '-' }}</template>
        <template #created_at_timestamp="{text}">{{ timestampToDate(text) }}</template>
      </app-table>
    </a-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import {settings} from '@/core/defaultSettings';
import generalMixin from '@/mixins/general';
import validationMixin from '@/mixins/validation';
import AppTable from '@/components/elements/AppTable';
import FormDateRangePicker from '@/components/elements/FormDateRangePicker';
import FormInput from '@/components/elements/FormInput';
import FormInputSelect from '@/components/elements/FormInputSelect'
import FormSelect from '@/components/elements/FormSelect';
import CalendarIcon from '@/assets/icons/calendarIcon';
import FunnelIcon from '@/assets/icons/funnelIcon';
import ListIcon from '@/assets/icons/listIcon';
import SearchIcon from '@/assets/icons/searchIcon';

export default {
  components: {
    AppTable,
    FormDateRangePicker,
    FormInput,
    FormInputSelect,
    FormSelect,
    CalendarIcon,
    FunnelIcon,
    ListIcon,
    SearchIcon
  },
  mixins: [generalMixin, validationMixin],
  data: () => ({
    moreFiltersState: false,
    filters: {
      search: null,
      organization_id: null,
      date: null,
      pico_id: null,
      base_id: null,
      user_id: null,
      use_time_from_value: null,
      use_time_from_unit: 'm',
      use_time_to_value: null,
      use_time_to_unit: 'm',
    }
  }),
  beforeMount() {
    if (this.$route.query?.search) {
      this.filters.search = this.$route.query.search;
    }
    if (this.$route.query?.organization_id) {
      this.filters.organization_id = this.$route.query.organization_id;
    }
    if (this.$route.query?.user_id) {
      this.filters.user_id = this.$route.query.user_id;
    }
    if (this.$route.query?.base_id) {
      this.filters.base_id = this.$route.query.base_id;
    }
    if (this.$route.query?.pico_id) {
      this.filters.pico_id = this.$route.query.pico_id;
    }
  },
  mounted() {
    this.$store.dispatch('organizationsStore/GET_DROPDOWN_LIST');
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organizationsStore/DROPDOWN_LIST',
      history: 'picosStore/HISTORY',
      historyMeta: 'picosStore/HISTORY_META'
    }),
    tableColumns() {
      return [
        {
          title: this.$t('label.pico_id'),
          dataIndex: 'pico_title'
        },
        {
          title: this.$t('label.base_from'),
          dataIndex: 'base_from_title'
        },
        {
          title: this.$t('label.base_to'),
          dataIndex: 'base_to_title',
          scopedSlots: {customRender: 'base_to_title'}
        },
        {
          title: this.$t('label.user_id'),
          dataIndex: 'user_id'
        },
        {
          title: this.$t('label.personal_info'),
          dataIndex: 'user_title',
          scopedSlots: {customRender: 'user_title'}
        },
        {
          title: this.$t('label.date_of_unlock'),
          dataIndex: 'created_at_timestamp',
          scopedSlots: {customRender: 'created_at_timestamp'}
        },
        {
          title: this.$t('label.total_time'),
          dataIndex: 'count_hour',
          scopedSlots: {customRender: 'count_hour'}
        },
      ];
    },
    holdingTimeOptions() {
      return [
        {value: 'm', label: 'm'},
        {value: 'h', label: 'h'},
        {value: 'd', label: 'd'}
      ];
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler: _.debounce(function () {
        this.getTableData();
      }, settings.debounceTime),
      deep: true
    }
  },
  methods: {
    getTableData(pagination) {
      this.$store.dispatch('picosStore/GET_HISTORY', {...this.filters, ...pagination});
    }
  }
}
</script>

<style lang="scss" scoped>
.gutter-example > > > .ant-row > div {
  background: transparent;
  border: 0;
}

.gutter-box {
  padding: 40px 32px;
  background: #fff;

  .gutter-box-number {
    color: #132540;
    font-size: 28px;
    font-weight: 900;
  }

  .gutter-box-label {
    color: #6886A6;
    font-size: 16px;
  }

  .gutter-box-btn {
    height: auto;
    margin-top: 32px;
    padding: 8px 28px;
    background: #467BFF;
  }

  .gutter-box-icon {
    height: 52px;
    width: 52px;

    &.green {
      background: #BDFFEA;
    }

    &.yellow {
      background: #FFEDAC;
    }

    &.red {
      background: #FFCBC8;
    }
  }
}

.filters-toggle-btn {
  padding: 12px 20px;

  span {
    margin-left: 5px;
  }
}

.select-btn {
  height: 48px;
  width: 100%;
  display: flex;
  position: relative;
  padding: 12px 48px;
  align-items: center;
  background: #fff;
  border: 0;
  color: #6886A6;
  text-align: left;
  box-shadow: none;

  .icon-wrapper {
    position: absolute;
    left: 16px;
  }

  &:hover, &:focus {
    color: #6886A6;
  }
}

.ant-popover::v-deep {
  .ant-popover-inner-content {
    padding: 24px 16px;
    background: #F7F8FA;
  }
}
</style>