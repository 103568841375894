<template>
  <svg :height="height" :width="width" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 25C12.2091 25 14 23.2091 14 21C14 18.7909 12.2091 17 10 17C7.79086 17 6 18.7909 6 21C6 23.2091 7.79086 25 10 25Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 27.9997C4.69871 27.0684 5.60464 26.3124 6.64607 25.7918C7.68751 25.2711 8.83585 25 10.0002 25C11.1645 25 12.3129 25.271 13.3543 25.7916C14.3958 26.3122 15.3017 27.0681 16.0005 27.9995" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 14.9997C4.69871 14.0684 5.60464 13.3124 6.64607 12.7918C7.68751 12.2711 8.83585 12 10.0002 12C11.1645 12 12.3129 12.271 13.3543 12.7916C14.3958 13.3122 15.3017 14.0681 16.0005 14.9995" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 25C24.2091 25 26 23.2091 26 21C26 18.7909 24.2091 17 22 17C19.7909 17 18 18.7909 18 21C18 23.2091 19.7909 25 22 25Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 27.9997C16.6987 27.0684 17.6046 26.3124 18.6461 25.7918C19.6875 25.2711 20.8358 25 22.0002 25C23.1645 25 24.3129 25.271 25.3543 25.7916C26.3958 26.3122 27.3017 27.0681 28.0005 27.9995" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22 12C24.2091 12 26 10.2091 26 8C26 5.79086 24.2091 4 22 4C19.7909 4 18 5.79086 18 8C18 10.2091 19.7909 12 22 12Z" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 14.9997C16.6987 14.0684 17.6046 13.3124 18.6461 12.7918C19.6875 12.2711 20.8358 12 22.0002 12C23.1645 12 24.3129 12.271 25.3543 12.7916C26.3958 13.3122 27.3017 14.0681 28.0005 14.9995" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    height: {type: String, default: '24'},
    width: {type: String, default: '24'},
    fill: {type: String, default: 'none'},
    stroke: {type: String, default: '#6886A6'},
    viewBox: {type: String, default: '0 0 24 24'}
  }
}
</script>