import {settings} from '@/core/defaultSettings';
import {getLastNotifications, getTableData, getTypes, markAsRead} from '@/api/notifications';

export default {
  namespaced: true,
  state: {
    tableData: [],
    tableMeta: {total: 0},
    lastNotifications: [],
    markedAsRead: null,
    types: []
  },
  getters: {
    TABLE_DATA(state) {
      return state.tableData;
    },
    TABLE_META(state) {
      return state.tableMeta;
    },
    LAST_NOTIFICATIONS(state) {
      return state.lastNotifications;
    },
    MARKED_AS_READ(state) {
      return state.markedAsRead;
    },
    TYPES(state) {
      return state.types;
    }
  },
  mutations: {
    SET_TABLE_DATA(state, data) {
      state.tableData = data;
    },
    SET_TABLE_META(state, data) {
      state.tableMeta = data;
    },
    SET_LAST_NOTIFICATIONS(state, data) {
      state.lastNotifications = data;
    },
    SET_MARKED_AD_READ(state, data) {
      state.markedAsRead = data;
    },
    ADD_NOTIFICATION(state, data) {
      state.lastNotifications.unshift(data);
      if (state.lastNotifications.length > settings.notificationsPopoverLength) {
        state.lastNotifications.pop();
      }
    },
    SET_TYPES(state, data) {
      state.types = data.map(el => {
        return {...el, value: el.id, label: el.title};
      });
    }
  },
  actions: {
    GET_TABLE_DATA(context, filters) {
      return new Promise((resolve, reject) => {
        getTableData(filters).then(response => {
          context.commit('SET_TABLE_DATA', response.data);
          context.commit('SET_TABLE_META', response.meta);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_LAST_NOTIFICATIONS(context, data) {
      return new Promise((resolve, reject) => {
        getLastNotifications(data).then(response => {
          context.commit('SET_LAST_NOTIFICATIONS', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    GET_TYPES(context) {
      return new Promise((resolve, reject) => {
        getTypes().then(response => {
          context.commit('SET_TYPES', response.data);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    MARK_AS_READ(context, notificationId) {
      return new Promise((resolve, reject) => {
        markAsRead(notificationId).then(response => {
          context.commit('SET_MARKED_AD_READ', notificationId);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }
  },
}