<template>
  <div class="app-checkbox">
    <a-checkbox :value="value" @change="$emit('change', $event.target.checked)">{{ label }}</a-checkbox>
  </div>
</template>
<script>
export default {
  props: {
    label: {type: String, default: ''},
    value: {type: Boolean, default: false}
  }
};
</script>

<style lang="scss" scoped>
.app-checkbox::v-deep {
  .ant-checkbox {
    + span {
      color: #6886A6;
      font-size: 15px;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #FFF;
        border-color: #6886A6;
      }
    }

    .ant-checkbox-inner {
      height: 24px;
      width: 24px;

      &::after {
        top: 45%;
        left: 25%;
        width: 7px;
        height: 12px;
        border-color: #467BFF;
      }
    }
  }
}
</style>