<template>
  <div class="form-element-wrapper app-date-picker">
    <div v-if="label" class="input-label text-grey">
      {{ label }}
      <span v-if="required" class="required text-danger">*</span>
    </div>
    <div v-if="withIcon" class="form-element-icon">
      <slot name="prefix-icon"/>
    </div>
    <a-range-picker
        :class="{'error': error}"
        :value="value"
        :placeholder="placeholder"
        :disabled-date="disabledDate"
        :ranges="ranges"
        format="DD.MM.YYYY"
        :allow-clear="allowClear"
        :separator="separator"
        @change="$emit('change', $event)"/>
  </div>
</template>

<script>
export default {
  props: {
    label: {type: String, default: ''},
    placeholder: {type: Array, default: () => ['', '']},
    value: {type: Array, default: () => []},
    disabledDate: {type: Function, default: () => {}},
    ranges: {type: Object, default: () => {}},
    separator: {type: String, default: '~'},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    error: {type: Boolean, default: false},
    allowClear: {type: Boolean, default: false},
    withIcon: {type: Boolean, default: false}
  }
}
</script>

<style lang="scss" scoped>
.app-date-picker::v-deep {
  .input-label {
    margin-bottom: 8px;
  }

  &:hover {
    .ant-calendar-picker-input {
      border: 0;

      &:hover {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .ant-calendar-picker {
    width: 100%;
    border: none !important;
    box-shadow: none !important;

    --antd-wave-shadow-color: none;

    &.hover {
      border: none !important;
      box-shadow: none !important;
    }

    .ant-input {
      height: 48px;
      border: 1px solid transparent;
      font-size: 15px;
      box-shadow: none;

      &:focus {
        box-shadow: none;
        outline: none;
      }

      .ant-calendar-range-picker-input {
        padding-left: 19px;
        color: #6886A6;
        text-align: left;

        &:first-child {
          padding-left: 38px;
        }

        &::placeholder {
          color: #6886A6;
        }
      }

      .ant-calendar-range-picker-separator {
        vertical-align: initial;
      }
    }
  }
}

.error::v-deep {
  .ant-input {
    border: 1px solid #FF655B;

    &:not(:focus) {
      background: #FFF6F5;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-height: 820px) {
  .app-date-picker::v-deep {
    .ant-calendar-picker {
      width: 100%;

      .ant-input {
        font-size: 14px;

        .ant-calendar-range-picker-input {
          padding-left: 38px;
        }
      }
    }
  }
}
</style>